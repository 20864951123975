export enum DeviceType {
  UNKNOWN = 'UNKNOWN',
  INVERTER = 'INVERTER',
  BEACON = 'BEACON',
  BATTERY = 'BATTERY',
  PVLINK = 'PVLINK',
  ICM = 'ICM', // legacy beacon-like device
  RGM = 'RGM',
  LOAD_MANAGER = 'LOAD CONTROLLER',
  GENERATOR = 'GENERATOR',
  MICROINVERTER_GATEWAY = 'MICROINVERTER GATEWAY', // gateway that speaks to micros
  MICROINVERTER = 'MICROINVERTER', // for the individual micros; mainly used in array layout
  // GATEWAY = 'GATEWAY',
  GATEWAY_PENGUIN = 'GATEWAY_PENGUIN', // Gateway for ES2
  INVERTER_LEANCORE = 'INVERTER_LEANCORE',
  DISCONNECT_SWITCH_MANTA = 'DISCONNECT_SWITCH_MANTA',
  BATTERY_R2_BATTERY = 'BATTERY_R2_BATTERY',
}
