import { Amp, AmpHour, Kilowatt, Percentage, Watt, WattHour } from '../types/Unit';

export const settingsUnitMap: { [key: string]: string } = {
  AMPS: Amp.symbol,
  AMP_HOURS: AmpHour.symbol,
  PERCENT: Percentage.symbol,
  WATTS: Watt.symbol,
  WATT_HOURS: WattHour.symbol,
  KILOWATTS: Kilowatt.symbol,
};
