import { CircularProgress, Fab, Tooltip } from '@mui/material';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import type { ReactElement } from 'react';
import { useRef } from 'react';

import { DATE_TIME_FILENAME_FORMAT } from '../../constants/dates';
import { useDownloadUrl } from '../../store/api';
import { customAlert } from '../../utils/alerts';
import { Icon } from '../icon';
import type { IconType } from '../icon/icons';
import { useStyles } from './styles';

const CLEAR_DOWNLOAD_STATE_TIMEOUT = 3000;

interface Props {
  url: string;
  filenamePrefix?: string;
  buttonTooltipText?: string;
}

// --

export const DownloadUrlButton = (props: Props): ReactElement => {
  const { url, filenamePrefix = 'export', buttonTooltipText = 'Export' } = props;
  const classes = useStyles();

  const [
    downloadUrl,
    {
      isLoading,
      isError: isErrorDownloading,
      isSuccess: isSuccessDownloading,
      isUninitialized: isNotStarted,
      reset: resetDownloading,
    },
  ] = useDownloadUrl();
  const resetDownloadingRef = useRef(resetDownloading);
  resetDownloadingRef.current = resetDownloading;

  const createFilename = () => {
    return `${filenamePrefix}_${DateTime.now().toFormat(DATE_TIME_FILENAME_FORMAT)}.csv`;
  };

  const handleDownload = async () => {
    // block downloads while another download is processing
    if (isLoading) {
      return;
    }

    try {
      await downloadUrl({ url, fileName: createFilename() }).unwrap();
    } catch (error: any) {
      customAlert(
        {
          title: `There was a problem with your export. Please contact Generac Support for help.`,
        },
        { type: 'error' },
      );
    } finally {
      setTimeout(() => {
        resetDownloadingRef.current();
      }, CLEAR_DOWNLOAD_STATE_TIMEOUT);
    }
  };

  const currentIcon = ((): IconType => {
    if (isSuccessDownloading) {
      return 'complete';
    }
    if (isErrorDownloading) {
      return 'failed';
    }
    return 'export csv';
  })();

  const buttonClassname = clsx({
    [classes.buttonError]: isErrorDownloading,
    [classes.buttonSuccess]: isSuccessDownloading,
    [classes.buttonPrimary]: isNotStarted || isLoading,
  });

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Tooltip placement="left" title={buttonTooltipText}>
          <Fab
            color="primary"
            className={buttonClassname}
            onClick={handleDownload}
            sx={{ zIndex: 1 }}
          >
            {isLoading ? (
              <>
                <CircularProgress size={44} className={classes.fabProgress} />
                <Icon icon="export csv" size={20} className={classes.iconProgress} />
              </>
            ) : (
              <Icon icon={currentIcon} />
            )}
          </Fab>
        </Tooltip>
      </div>
    </div>
  );
};
