import type { AxiosError } from 'axios';
import { isEmpty } from 'lodash';
import React from 'react';
import type { ActionsObservable, Epic } from 'redux-observable';
import { combineEpics } from 'redux-observable';
import type { Observable } from 'rxjs';
import { EMPTY, forkJoin, from, of } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import type { Action } from 'typesafe-actions';
import { isActionOf } from 'typesafe-actions';

import { trackEvent } from '../../analyticsClient';
import { addSystemsToSite, getSiteSystems, getSystemDetails, pairLoadManager } from '../../api';
import { SystemConnectionStatusList } from '../../components';
import type { AddSystemsToSiteSuccess } from '../../types/AddSystemsToSite';
import { AnalyticsEvent, AnalyticsProperty } from '../../types/Analytics';
import type { LoadManagerPairRequest } from '../../types/LoadManager';
import type { GetSiteSystemsSuccessResponse } from '../../types/System';
import type { SystemDetailsSuccessResponse } from '../../types/SystemDetails';
import { customAlert } from '../../utils/alerts';
import type { EpicDependencies } from '..';
import { userIdSelector } from '../auth/selectors';
import { companyIdSelector } from '../company/selectors';
import {
  addSystems,
  clearAddSystemsState,
  loadSystems,
  loadSystemsDetails,
  pairLoadManagers,
  setPairingRecord,
  setSystems,
} from './actions';
import {
  addedSystemsSelector,
  neverConnectedSystemsCountSelector,
  onePairRecordSelector,
  pairingErrorSelector,
  pairingRecordSelector,
  systemsForPairingSelector,
} from './selectors';

const addSystemsEpic: Epic = (action$, state$): Observable<Action> => {
  return action$.pipe(
    filter(isActionOf(addSystems.request)),
    mergeMap((action): Observable<Action> => {
      const { siteId, systems } = action.payload;
      const companyId = companyIdSelector(state$.value);
      const userId = userIdSelector(state$.value);
      return from(addSystemsToSite({ siteId, systems, companyId, userId })).pipe(
        map((data) => {
          // [analytics] System Register
          trackEvent(AnalyticsEvent.SystemRegister, {
            [AnalyticsProperty.SiteId]: siteId,
            [AnalyticsProperty.Count]: systems.length,
          });
          return addSystems.success({
            siteId: action.payload.siteId,
            data: data as AddSystemsToSiteSuccess,
          });
        }),
        catchError((error: AxiosError) => {
          // [analytics] System Reg Validation Error
          trackEvent(AnalyticsEvent.ApiError, {
            [AnalyticsProperty.ApiErrorCode]: error?.response?.status,
            [AnalyticsProperty.ApiErrorMessage]:
              error?.response?.data?.message || error?.response?.data,
            [AnalyticsProperty.ApiCallName]: 'RegisterSystemRequest',
          });
          return of(
            addSystems.failure({ ...error.response?.data, errorCode: error.response?.status }),
          );
        }),
      );
    }),
  );
};

const startGetDetailsFlowEpic: Epic = (
  action$: ActionsObservable<Action>,
  state$,
): Observable<Action> => {
  return action$.pipe(
    filter(isActionOf(addSystems.success)),
    mergeMap((action): Observable<Action> => {
      const {
        payload: { siteId },
      } = action;
      const systems = systemsForPairingSelector(state$.value);
      const systemIds = systems?.map((s) => s.inverter.systemId).filter(Boolean);
      if (systemIds) {
        return of(loadSystemsDetails.request({ siteId, systemIds }));
      }
      return EMPTY;
    }),
  );
};

const getSystemsDetailsEpic: Epic = (action$: ActionsObservable<Action>): Observable<Action> => {
  return action$.pipe(
    filter(isActionOf(loadSystemsDetails.request)),
    mergeMap(({ payload }): Observable<Action> => {
      const { siteId, systemIds } = payload;
      const requests = systemIds?.map((systemId) => getSystemDetails({ systemId }));
      if (requests?.length) {
        return forkJoin(requests).pipe(
          map((response) => {
            const systems = response as SystemDetailsSuccessResponse[];
            return loadSystemsDetails.success({ siteId, systems });
          }),
          catchError((e) => of(loadSystemsDetails.failure(e))),
        );
      }
      return EMPTY;
    }),
  );
};

const startPairingFlowEpic: Epic = (
  action$: ActionsObservable<Action>,
  state$,
): Observable<Action> => {
  return action$.pipe(
    filter(isActionOf(loadSystemsDetails.success)),
    mergeMap(({ payload: { systems: inverters } }): Observable<Action> => {
      const systems = systemsForPairingSelector(state$.value);
      if (systems) {
        const systemsToPair = systems
          .map(({ loadManager, inverter }) => {
            const { systemId } = loadManager;
            const inverterDetails = inverters.find((i) => i.systemId === inverter.systemId);
            const { rcpId, beaconRcpn } = { ...inverterDetails };
            if (rcpId && beaconRcpn) {
              return {
                inverterRcpn: rcpId,
                beaconRcpn,
                systemId,
              };
            }
            return null;
          })
          .filter((item): item is LoadManagerPairRequest => !!item);

        return of(pairLoadManagers.request(systemsToPair));
      }
      return EMPTY;
    }),
  );
};

const systemsPairingEpic: Epic = (action$: ActionsObservable<Action>): Observable<Action> => {
  return action$.pipe(
    filter(isActionOf(pairLoadManagers.request)),
    mergeMap(({ payload }): Observable<Action> => {
      const requests = payload.map((p) => pairLoadManager(p));
      if (requests?.length) {
        return forkJoin(requests).pipe(
          map((response) => pairLoadManagers.success(response)),
          catchError((e) => of(pairLoadManagers.failure(e))),
        );
      }
      return of(pairLoadManagers.failure(new Error('There is no pairing requests')));
    }),
  );
};

const notificationEpic: Epic = (
  action$: ActionsObservable<Action>,
  state$,
  dependencies: EpicDependencies,
): Observable<Action> => {
  return action$.pipe(
    filter(
      isActionOf([
        addSystems.success,
        pairLoadManagers.success,
        pairLoadManagers.failure,
        loadSystemsDetails.failure,
      ]),
    ),
    filter((action) => {
      if (isActionOf(addSystems.success)(action)) {
        const pairingRecord = pairingRecordSelector(state$.value);
        const pairingNeeded = pairingRecord && !isEmpty(pairingRecord);
        if (pairingNeeded) {
          return false;
        }
      }
      return true;
    }),
    map(() => {
      const addedSystems = addedSystemsSelector(state$.value);
      const neverConnectedSystemsCount = neverConnectedSystemsCountSelector(state$.value);
      const hasNeverConnectedSystems = neverConnectedSystemsCount > 0;
      const hasPairingError = !!pairingErrorSelector(state$.value);
      const hasError = hasNeverConnectedSystems || hasPairingError;
      // Close wizard
      dependencies.history.replace({ search: '' });
      // Notification
      customAlert(
        {
          title: (
            <>
              You’ve added equipment to the site.
              {addedSystems && hasError ? (
                <>
                  <br />
                  However, there are issues.
                  <br />
                  <SystemConnectionStatusList
                    systems={addedSystems}
                    hasPairingError={hasPairingError}
                  />
                </>
              ) : null}
            </>
          ),
          closeButton: true,
          hideIcon: hasError,
        },
        { type: 'success', autoClose: false },
      );
      return clearAddSystemsState();
    }),
  );
};

const loadSystemsEpic: Epic = (action$): Observable<Action> => {
  return action$.pipe(
    filter(isActionOf(loadSystems.request)),
    mergeMap((action): Observable<Action> => {
      const { siteId } = action.payload;
      return from(getSiteSystems(siteId)).pipe(
        map((data) => loadSystems.success(data as GetSiteSystemsSuccessResponse)),
        catchError((error: AxiosError) => of(loadSystems.failure(error))),
      );
    }),
  );
};

/**
 * Condition: systems change
 * Result: if there is only one pair inverter - load manager then the value of the pair is set
 * else pairing record is cleared
 **/
const clearPairingRecordEpic: Epic = (
  action$: ActionsObservable<Action>,
  state$,
): Observable<Action> => {
  return action$.pipe(
    filter(isActionOf(setSystems)),
    map(() => {
      const onePairRecord = onePairRecordSelector(state$.value);
      return setPairingRecord(onePairRecord || {});
    }),
  );
};

export default combineEpics(
  addSystemsEpic,
  notificationEpic,
  clearPairingRecordEpic,
  startGetDetailsFlowEpic,
  getSystemsDetailsEpic,
  startPairingFlowEpic,
  systemsPairingEpic,
  loadSystemsEpic,
);
