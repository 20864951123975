import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import type { SxProps, TextFieldProps } from '@mui/material';
import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import type { KeyboardEvent } from 'react';

import { COLORS } from '../../styles';

export interface SettingTextInputProps {
  onSubmit: () => void;
  isLoading?: boolean;
  errorText?: string;
  successText?: string;
  disableSubmitButton?: boolean;
  valueUnit?: string;
  readOnly?: boolean;
  label?: string;
}

const textFieldSx: SxProps = {
  width: '100%',
  maxWidth: {
    xs: 'none',
    md: 300,
  },
  minWidth: {
    xs: 'none',
    md: 300,
  },
  ':disabled': {
    background: `${COLORS.LIGHT_GREY} !important`,
  },
  '& .Mui-focused fieldset': {
    border: `2px solid ${COLORS.BLACK} !important`,
  },
  '& .Mui-error fieldset': {
    borderColor: COLORS.ERROR,
  },
  '& .Mui-focused.Mui-error fieldset': {
    borderColor: `${COLORS.ERROR} !important`,
  },
  '& .MuiInputBase-colorSuccess fieldset': {
    borderColor: COLORS.GREEN,
  },
  '& .Mui-focused.MuiInputBase-colorSuccess fieldset': {
    borderColor: `${COLORS.GREEN} !important`,
  },
};

const textFieldInputSx: SxProps = {
  pl: 2,
  pr: 1,
  py: 1.5,
  '& input[type=number]': {
    '-moz-appearance': 'textfield',
  },
  '& input[type=number]::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
  '& input[type=number]::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
};

const textFieldSuffixSx: SxProps = {
  fontSize: 20,
  color: COLORS.MEDIUM_EMPHASIS,
  fontWeight: 'bold',
  px: 1,
};

const textFieldSubmitIconButton: SxProps = {
  width: 32,
  height: 32,
  background: COLORS.ORANGE,
  ':hover': {
    background: COLORS.ORANGE,
  },
  ':disabled': {
    border: `1px solid ${COLORS.SILVER}`,
    svg: {
      color: COLORS.SILVER,
    },
  },
};

const textFieldHelperTextSx: SxProps = {
  ml: 0,
  mt: 0.5,
};

const textFieldInputLabelSx: SxProps = {
  textTransform: 'capitalize !important',
  transform: 'translate(0, -16px)',
  fontWeight: 'bold',
};

const textFieldStatusBox: SxProps = {
  height: 47,
  width: 47,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const textFieldVisibleInputSx: SxProps = {
  mt: 2,
  '& .MuiInputBase-root fieldset span': {
    display: 'none',
  },
};

export default ({
  label,
  value,
  onChange,
  isLoading,
  errorText,
  successText,
  onSubmit,
  valueUnit,
  disableSubmitButton,
  readOnly,
  helperText,
  name,
  ...other
}: SettingTextInputProps & TextFieldProps) => {
  const hasError = !!errorText;
  const isSuccessful = !!successText;
  const isSubmitButtonShown = !hasError && !isSuccessful && !isLoading && !readOnly;
  const isSubmitButtonActive = isSubmitButtonShown && !disableSubmitButton;

  const handleKeyPress = (event: KeyboardEvent<HTMLElement>) => {
    if (event.code === 'Enter' && isSubmitButtonActive) {
      onSubmit();
    }
  };

  return (
    <TextField
      {...other}
      label={label}
      color={successText ? 'success' : 'primary'}
      value={value}
      variant={readOnly ? 'standard' : 'outlined'}
      onChange={onChange}
      helperText={errorText || successText || helperText}
      onKeyPress={handleKeyPress}
      InputLabelProps={{
        shrink: true,
        sx: textFieldInputLabelSx,
      }}
      sx={{
        ...textFieldSx,
        ...(!!label && label.length > 0 ? textFieldVisibleInputSx : {}),
      }}
      error={hasError}
      InputProps={{
        readOnly: readOnly || isLoading,
        sx: textFieldInputSx,
        endAdornment: (
          <InputAdornment position="start" sx={{ mr: 0 }}>
            <Typography sx={textFieldSuffixSx} data-test-class={`settings-input-unit-text-${name}`}>
              {valueUnit}
            </Typography>
            {!readOnly && (
              <Box sx={textFieldStatusBox}>
                {hasError && (
                  <ErrorOutlineOutlinedIcon
                    sx={{ fontSize: 20, color: COLORS.ERROR }}
                    data-test-class={`settings-input-error-icon-${name}`}
                  />
                )}
                {isSuccessful && (
                  <CheckCircleOutlineOutlinedIcon
                    sx={{ fontSize: 20, color: COLORS.GREEN }}
                    data-test-class={`settings-input-success-icon-${name}`}
                  />
                )}
                {isLoading && <CircularProgress size={20} />}
                {isSubmitButtonShown && (
                  <IconButton
                    sx={textFieldSubmitIconButton}
                    area-label={`submit-button-${disableSubmitButton ? 'disabled' : 'enabled'}`}
                    disabled={disableSubmitButton}
                    onClick={onSubmit}
                    data-test-class={`settings-input-submit-button-${name}`}
                  >
                    <ArrowUpwardIcon sx={{ fontSize: 16, color: COLORS.WHITE }} />
                  </IconButton>
                )}
              </Box>
            )}
          </InputAdornment>
        ),
      }}
      inputProps={{
        ...other.inputProps,
        sx: { p: '0px !important' },
        'data-test-class': `settings-input-${name}`,
      }}
      FormHelperTextProps={{
        sx: { ...textFieldHelperTextSx, ...(successText ? { color: COLORS.GREEN } : {}) },
        // @ts-ignore
        'data-test-class': `settings-input-helper-text-${name}`,
      }}
    />
  );
};
