import { isEmpty } from 'lodash';

import type { AccountDetailsBody } from '../types/Account';
import type { AddSystemsToSiteRequest } from '../types/AddSystemsToSite';
import type {
  AlertDetailsRequest,
  AlertInstanceHistoryRequest,
  CompanyAlertsRequest,
  SiteAlertsRequest,
} from '../types/Alert';
import type { CompaniesMergeRequest } from '../types/CompaniesMerge';
import type { AssociateCompaniesRequest } from '../types/CompanyAssociation';
import type { RemoveCompanyUserRequest, UpdateCompanyUserRoleRequest } from '../types/CompanyUser';
import type { DeviceEventLogRequest } from '../types/DeviceEvent';
import type { LoadManagerPairRequest } from '../types/LoadManager';
import type { SignUpRequest } from '../types/SignUp';
import type {
  SiteListExportParams,
  SiteQueryParams,
  SitesRequest,
  SystemDetails,
} from '../types/Site';
import type {
  AddSiteInterface,
  SiteAddressRequest,
  ValidationSiteAddressInterface,
} from '../types/SiteDetails';
import type { SiteLayoutSaveRequest, SiteLayoutTelemetryRequest } from '../types/SiteLayout';
import type { SystemDetailsRequest } from '../types/SystemDetails';
import type { UserInviteRequest } from '../types/UserInvite';
import type { UtilitiesRequest } from '../types/Utility';
import type { FakeApiProvider } from './FakeApiProvider';
import RealApiProvider from './RealApiProvider';
import type { ApiProvider, InterceptorCallbacks, TokenFunctions } from './types';

let fakeProvider = {} as FakeApiProvider;
if (process.env.NODE_ENV === 'development') {
  const module = require('./FakeApiProvider');
  fakeProvider = new module.FakeApiProvider();
} // only import in development to prevent bulky faker.js pkg from compiling into prod bundle

export type ApiProviderMethods = keyof ApiProvider;

const fakeMethods = new Set<ApiProviderMethods>();

const provider = new RealApiProvider();

const getProvider = (method: ApiProviderMethods) =>
  isEmpty(fakeProvider) ? provider : fakeMethods.has(method) ? fakeProvider : provider;

export const fakeMethod = (method: ApiProviderMethods) => fakeMethods.add(method);

export const refreshTokens = (token: string) => getProvider('refreshTokens').refreshTokens(token);

export const updateAccessToken = () => getProvider('updateAccessToken').updateAccessToken();

export const init = (
  interceptorCallbacks: InterceptorCallbacks,
  tokenFunctions?: TokenFunctions,
  localStorage?: any,
) => getProvider('init').init(interceptorCallbacks, tokenFunctions, localStorage);

export const getFleetSites = (sitesRequest: SitesRequest & SiteQueryParams) =>
  getProvider('getFleetSites').getFleetSites(sitesRequest);

export const getFleetMapSites = (fleetId: string) =>
  getProvider('getFleetMapSites').getFleetMapSites(fleetId);

export const setAddSite = (params: AddSiteInterface) =>
  getProvider('setAddSite').setAddSite(params);

export const setValidationSiteAddress = (address: ValidationSiteAddressInterface) =>
  getProvider('setValidationSiteAddress').setValidationSiteAddress(address);

export const validateSystemDetails = (systemsToValidate: SystemDetails[]) =>
  getProvider('validateSystemDetails').validateSystemDetails(systemsToValidate);

export const getSiteAlerts = (params: SiteAlertsRequest) =>
  getProvider('getSiteAlerts').getSiteAlerts(params);

export const getCompanyFleets = (companyId: string) =>
  getProvider('getCompanyFleets').getCompanyFleets(companyId);

export const getUserCompanies = (userId: string) =>
  getProvider('getUserCompanies').getUserCompanies(userId);

export const getCompany = (id: string) => getProvider('getCompany').getCompany(id);

export const getCompanies = () => getProvider('getCompanies').getCompanies();

export const getAccountData = (id: string) => getProvider('getAccountData').getAccountData(id);

export const getAccountCompanies = (id: string) =>
  getProvider('getAccountCompanies').getAccountCompanies(id);

export const updateAccountNameData = (id: string, firstName: string, lastName: string) =>
  getProvider('updateAccountNameData').updateAccountNameData(id, firstName, lastName);

export const createSiteListExport = (companyId: string, params?: SiteListExportParams) =>
  getProvider('createSiteListExport').createSiteListExport(companyId, params || {});

export const updateAccountPasswordData = (
  id: string,
  currentPassword: string,
  newPassword: string,
) =>
  getProvider('updateAccountPasswordData').updateAccountPasswordData(
    id,
    currentPassword,
    newPassword,
  );

export const updateCompanyData = (id: string, body: AccountDetailsBody) =>
  getProvider('updateCompanyData').updateCompanyData(id, body);

export const signUp = (params: SignUpRequest) => getProvider('signUp').signUp(params);

export const getCompanyUsers = (companyId: string) =>
  getProvider('getCompanyUsers').getCompanyUsers(companyId);

export const mergeCompanies = (params: CompaniesMergeRequest) =>
  getProvider('mergeCompanies').mergeCompanies(params);

export const inviteUsers = (userId: string, params: UserInviteRequest) =>
  getProvider('inviteUsers').inviteUsers(userId, params);

export const getPendingCompanyUsers = (companyId: string) =>
  getProvider('getPendingCompanyUsers').getPendingCompanyUsers(companyId);

export const updateCompanyUserRole = (params: UpdateCompanyUserRoleRequest) =>
  getProvider('updateCompanyUserRole').updateCompanyUserRole(params);

export const addSystemsToSite = (params: AddSystemsToSiteRequest) =>
  getProvider('addSystemsToSite').addSystemsToSite(params);

export const removeCompanyUser = (params: RemoveCompanyUserRequest) =>
  getProvider('removeCompanyUser').removeCompanyUser(params);

export const getCompanyAssociations = (companyId: string) =>
  getProvider('getCompanyAssociations').getCompanyAssociations(companyId);

export const associateCompanies = (params: AssociateCompaniesRequest) =>
  getProvider('associateCompanies').associateCompanies(params);

export const activateCompanyAssociation = (associationId: string) =>
  getProvider('activateCompanyAssociation').activateCompanyAssociation(associationId);

export const removeCompanyAssociation = (associationId: string) =>
  getProvider('removeCompanyAssociation').removeCompanyAssociation(associationId);

export const getSiteAssociations = (siteId: string) =>
  getProvider('getSiteAssociations').getSiteAssociations(siteId);

export const getCompanyAlerts = (params: CompanyAlertsRequest) =>
  getProvider('getCompanyAlerts').getCompanyAlerts(params);

export const getAlertDetails = (params: AlertDetailsRequest) =>
  getProvider('getAlertDetails').getAlertDetails(params);

export const getAlertTypeInfoFromUrl = (url: string) =>
  getProvider('getAlertTypeInfoFromUrl').getAlertTypeInfoFromUrl(url);

export const getSiteAddress = (params: SiteAddressRequest) =>
  getProvider('getSiteAddress').getSiteAddress(params);

export const getAlertHistory = (params: AlertInstanceHistoryRequest) =>
  getProvider('getAlertHistory').getAlertHistory(params);

export const getDeviceEventLog = (params: DeviceEventLogRequest) =>
  getProvider('getDeviceEventLog').getDeviceEventLog(params);

export const getUtilities = (params: UtilitiesRequest) =>
  getProvider('getUtilities').getUtilities(params);

export const getSystemDetails = (params: SystemDetailsRequest) =>
  getProvider('getSystemDetails').getSystemDetails(params);

export const pairLoadManager = (params: LoadManagerPairRequest) =>
  getProvider('pairLoadManager').pairLoadManager(params);

export const getSiteSystems = (siteId: string) =>
  getProvider('getSiteSystems').getSiteSystems(siteId);

export const getSiteLayout = (siteId: string) => getProvider('getSiteLayout').getSiteLayout(siteId);

export const putSiteLayout = (siteId: string, body: SiteLayoutSaveRequest) =>
  getProvider('putSiteLayout').putSiteLayout(siteId, body);

export const getSiteLayoutTelemetry = (body: SiteLayoutTelemetryRequest) =>
  getProvider('getSiteLayoutTelemetry').getSiteLayoutTelemetry(body);
