import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { isSupportModeSelector } from '../../store/auth/selectors';
import { updateSearchValue } from '../../store/ui';
import { getColor } from '../../styles';
import { Icon } from '../icon';
import { SearchInput } from '../searchInput';

/**
 * supportMode UI has the SupportAppBar at the top of the page which
 * will required a fixed position element to be pushed down by 48px
 */
const CollapsedContainer = styled(Box)<{ $supportMode?: boolean }>`
  position: fixed;
  height: 48px;
  right: 0;
  top: ${({ $supportMode }) => ($supportMode ? '78px' : '30px')};
  bottom: 0;
  z-index: 2;
  background: ${getColor('WHITE')};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.08);
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
  cursor: pointer;
`;

const Container = styled(Box)<{ $supportMode?: boolean }>`
  position: fixed;
  right: 0;
  top: ${({ $supportMode }) => ($supportMode ? '48px' : '0px')};
  bottom: 0;
  z-index: 2;
  background: ${getColor('WHITE')};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
  border-bottom-left-radius: 15px;
  height: min-content;
  align-items: center;
  display: flex;
  width: 510px;
  justify-content: space-between;
`;

const CloseButton = styled('div')`
  cursor: pointer;
  height: 24px;
`;

export const SearchPopup = ({ initialCollapsed = true }: { initialCollapsed?: boolean }) => {
  const dispatch = useDispatch();
  const isSupportMode = useSelector(isSupportModeSelector);
  const [collapsed, setCollapsed] = useState(initialCollapsed);
  const location = useLocation();

  useEffect(() => {
    setCollapsed(true);
  }, [location]);

  const onCloseHandler = () => {
    setCollapsed(true);
    dispatch(updateSearchValue(''));
  };

  if (collapsed) {
    return (
      <CollapsedContainer
        p={1.5}
        pr={5}
        $supportMode={isSupportMode}
        onClick={() => setCollapsed(false)}
      >
        <Icon icon="search" />
      </CollapsedContainer>
    );
  }
  return (
    <Container p={4} $supportMode={isSupportMode}>
      <SearchInput />
      <CloseButton onClick={onCloseHandler}>
        <Icon icon="search close" />
      </CloseButton>
    </Container>
  );
};
