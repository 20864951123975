import type { SelectChangeEvent } from '@mui/material';
import type { ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { PayloadActionCreator } from 'typesafe-actions';

import type { RootState } from '../store/types';

type SelectedValue = {
  page: number;
  perPage: number;
  total: number;
  [key: string]: any;
};

type Action = PayloadActionCreator<any, any>;
type Payload<A extends Action> = Omit<Parameters<A>[0], 'page' | 'perPage'>;
type PayloadGetter<A extends Action> = () => Payload<A> | false | undefined;

type Props<A extends Action> = {
  selector: (state: RootState) => SelectedValue;
  action?: A;
  payload?: Payload<A> | PayloadGetter<A>;
};

export const usePagination = <A extends Action>({ selector, action, payload }: Props<A>) => {
  const dispatch = useDispatch();
  const { page, perPage, total } = useSelector(selector);

  const handlePageChange = (event: ChangeEvent<unknown>, page: number) => {
    const params = typeof payload === 'function' ? payload() : payload;
    if (params) {
      dispatch(action?.({ page, perPage, ...params }));
    }
  };

  const handleRowsPerPageChange = (event: SelectChangeEvent<unknown>, perPage: number) => {
    const params = typeof payload === 'function' ? payload() : payload;
    if (params) {
      dispatch(action?.({ page: 1, perPage, ...params }));
    }
  };

  return {
    page,
    perPage,
    total,
    pageCount: Math.ceil(total / perPage),
    handlePageChange,
    handleRowsPerPageChange,
  };
};
