export enum Capability {
  BEACON = 'beacon',
  INVERTER = 'inverter',
  PVL = 'pvl',
  BATTERY = 'battery',
  GENERATOR = 'generator',
  RGM = 'rgm',
  LOAD_MANAGER = 'load_controller',
  GATEWAY = 'gateway',
  MICROINVERTER = 'microinverter',
}
