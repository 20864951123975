import React from 'react';
import type { Epic } from 'redux-observable';
import { combineEpics } from 'redux-observable';
import type { Observable } from 'rxjs';
import { from } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import type { Action } from 'typesafe-actions';
import { isActionOf } from 'typesafe-actions';

import { getCompanies, mergeCompanies } from '../../api';
import { MergeNotification } from '../../components';
import { AlertContainer } from '../../types/ScreenAlerts';
import { customAlert } from '../../utils/alerts';
import { loadCompanies, mergeCompaniesAction } from './actions';
import { companiesMergeStatsSelector } from './selectors';

const loadCompaniesEpic: Epic = (action$) =>
  action$.pipe(
    filter(isActionOf(loadCompanies.request)),
    mergeMap(() => {
      return from(getCompanies()).pipe(
        map(loadCompanies.success),
        catchError(async (e) => loadCompanies.failure(e)),
      );
    }),
  );

const mergeCompaniesEpic: Epic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(mergeCompaniesAction.request)),
    mergeMap((action): Observable<Action> => {
      return from(mergeCompanies(action.payload)).pipe(
        map(mergeCompaniesAction.success),
        catchError(async (error) => mergeCompaniesAction.failure(error)),
        mergeMap((res) => {
          const { targetCompanyId } = action.payload;
          const { companiesCount, usersCount, companyName } = companiesMergeStatsSelector(
            state$.value,
            {
              targetCompanyId,
            },
          );
          const isError = isActionOf(mergeCompaniesAction.failure)(res);
          const type = isError ? 'error' : 'success';
          customAlert(
            {
              title: (
                <MergeNotification
                  targetCompanyId={targetCompanyId}
                  companiesCount={companiesCount}
                  usersCount={usersCount}
                  companyName={companyName}
                  isError={isError}
                />
              ),
              closeButton: true,
            },
            {
              type,
              autoClose: false,
              containerId: isError ? AlertContainer.Modal : AlertContainer.Root,
            },
          );
          return [res, loadCompanies.request()];
        }),
      );
    }),
  );

export default combineEpics(loadCompaniesEpic, mergeCompaniesEpic);
