import { Box, ButtonBase, Typography } from '@mui/material';
import bbox from '@turf/bbox';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { LayerProps, MapRef, ViewportProps } from 'react-map-gl';
import ReactMapGL, {
  Layer,
  NavigationControl,
  Popup,
  Source,
  WebMercatorViewport,
} from 'react-map-gl';
import { generatePath, useHistory } from 'react-router-dom';

import { DATE_SHORT_FORMAT } from '../../constants/dates';
import GeneratorStatusDefinitions from '../../constants/generatorStatus';
import { LoadManagerSiteStatusDefinitions } from '../../constants/loadManager';
import { useCompanyContext } from '../../hooks/useCompanyContext';
import { useRoutes } from '../../hooks/useRoutes';
import { useGetSite } from '../../store/api/endpoints/site';
import { COLORS } from '../../styles';
import type { MapSite } from '../../types/Site';
import { LoadManagerStatus } from '../../types/SiteDetails';
import type { GeneratorStatus } from '../../types/SiteDevices';
import { SystemType } from '../../types/System';
import { numberToFixed } from '../../utils';
import { Icon } from '../icon';
import { Loader } from '../loader';

const SOURCE_ID = 'source1';

interface Props {
  width: number | string;
  height: number;
  data: MapSite[];
}

export const clusterLayerUnder: LayerProps = {
  id: 'clusterLayerUnder',
  type: 'circle',
  source: 'earthquakes',
  filter: ['has', 'point_count'],
  paint: {
    'circle-color': 'rgba(44, 105, 130 ,0.2)',
    'circle-radius': 21,
  },
};

export const clusterLayer: LayerProps = {
  id: 'clusters',
  type: 'circle',
  source: 'earthquakes',
  filter: ['has', 'point_count'],
  paint: {
    'circle-color': '#2C6982',
    'circle-radius': 13,
  },
};

export const clusterCountLayer: LayerProps = {
  id: 'cluster-count',
  type: 'symbol',
  source: 'earthquakes',
  filter: ['has', 'point_count'],
  paint: {
    'text-color': '#FFF',
  },
  layout: {
    'text-field': '{point_count_abbreviated}',
    'text-font': ['Nunito Sans'],
    'text-size': 11,
  },
};

export const unclusteredPointLayer: LayerProps = {
  id: 'unclustered-point',
  type: 'circle',
  source: 'earthquakes',
  filter: ['!', ['has', 'point_count']],
  paint: {
    'circle-color': '#2C6982',
    'circle-radius': 8,
  },
};

export const navigationStyle = { bottom: 24, right: 24 };

interface PopupState {
  show: boolean;
  latitude: number;
  longitude: number;
  name: string;
  siteId: string;
  installedDate: string;
  installedPV: number;
  installedStorage: number;
  loadManagerStatus?: LoadManagerStatus;
  generatorStatus?: GeneratorStatus;
  siteSystemTypes?: SystemType[];
}

const initialPopupState: PopupState = {
  show: false,
  name: '',
  siteId: '',
  installedDate: '',
  latitude: 0,
  longitude: 0,
  installedPV: 0,
  installedStorage: 0,
  siteSystemTypes: [],
};

export const Map = ({ width, height, data }: Props) => {
  const Routes = useRoutes();
  const history = useHistory();

  const { companyId } = useCompanyContext();

  const [viewport, setViewport] = useState<ViewportProps>({ transitionDuration: 500 });
  const [popup, setPopup] = useState(initialPopupState);
  const [selectedSiteId, setSelectedSiteId] = useState<string>('');

  const { data: selectedSite, isFetching: isFetchingSite } = useGetSite(
    { siteId: selectedSiteId },
    { skip: !selectedSiteId },
  );

  useEffect(() => {
    if (selectedSite) {
      setPopup((currentPopup) => ({
        ...currentPopup,
        installedStorage: selectedSite.nameplateBatteryCapacity_kWh,
        loadManagerStatus: selectedSite.loadControllerStatus,
        generatorStatus: selectedSite.dcGeneratorStatus,
        siteSystemTypes: selectedSite.siteSystemTypes,
      }));
    }
  }, [selectedSite]);

  const convertedData = useMemo(
    () => ({
      type: 'FeatureCollection',
      crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' } },
      features: data.map((site) => {
        const { siteId, siteName, installedDate, longitude, latitude, installedPV } = site;

        return {
          type: 'Feature',
          properties: {
            id: siteId,
            name: siteName,
            installedPV,
            installedDate,
            siteId,
          },
          geometry: {
            type: 'Point',
            coordinates: [longitude, latitude, 0.0],
          },
        };
      }),
    }),
    [data],
  );

  useEffect(() => {
    const boundingBox = bbox(convertedData);
    if (!boundingBox.every(isFinite)) {
      return;
    }

    const [minLng, minLat, maxLng, maxLat] = boundingBox;
    const newPort = new WebMercatorViewport({ width: 640, height: 480 });
    const { longitude, latitude, zoom } = newPort.fitBounds(
      [
        [minLng, minLat],
        [maxLng, maxLat],
      ],
      {
        padding: 40,
      },
    );
    setViewport({ ...viewport, longitude, latitude, zoom: Math.round(zoom) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [convertedData]);

  const mapRef = useRef<MapRef>(null);

  const onViewportChange = useCallback((viewport: ViewportProps) => {
    setViewport(viewport);
  }, []);

  const onClick = useCallback(
    (event) => {
      if (!mapRef?.current) {
        return;
      }
      const feature = event.features[0];
      if (feature?.layer?.id === unclusteredPointLayer.id) {
        setSelectedSiteId(feature.properties.siteId);

        setPopup({
          show: true,
          siteId: feature.properties.siteId,
          longitude: feature.geometry.coordinates[0],
          latitude: feature.geometry.coordinates[1],
          name: feature.properties.name,
          installedDate: DateTime.fromISO(feature.properties.installedDate).toFormat(
            DATE_SHORT_FORMAT,
          ),
          installedPV: feature.properties.installedPV,
          installedStorage: feature.properties.installedStorage,
          siteSystemTypes: [],
        });
        return;
      }
      if (!feature?.properties?.cluster_id) {
        setSelectedSiteId('');
        setPopup(initialPopupState); // closes an open popup if map clicked

        return;
      }
      const clusterId = feature.properties.cluster_id;

      const mapboxSource = mapRef?.current?.getMap().getSource(SOURCE_ID);

      mapboxSource.getClusterExpansionZoom(clusterId, (err: any, zoom: number) => {
        if (err) {
          return;
        }
        setViewport({
          ...viewport,
          zoom,
          longitude: feature.geometry.coordinates[0],
          latitude: feature.geometry.coordinates[1],
        });
      });
    },
    [viewport],
  );

  const onHeaderClick = () => {
    history.push({
      pathname: generatePath(Routes.SiteDashboard, { companyId, siteId: popup.siteId }),
      state: { siteName: popup.name },
    });
  };

  const loadManagerStatusText = popup.loadManagerStatus
    ? LoadManagerSiteStatusDefinitions[popup.loadManagerStatus]
      ? LoadManagerSiteStatusDefinitions[popup.loadManagerStatus].name
      : 'Unknown'
    : '';

  const generatorStatusText = popup.generatorStatus
    ? GeneratorStatusDefinitions[popup.generatorStatus]
      ? GeneratorStatusDefinitions[popup.generatorStatus].name
      : 'Unknown'
    : '';

  return (
    <>
      <ReactMapGL
        mapStyle={process.env.REACT_APP_MAPBOX_STYLE}
        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        onViewportChange={onViewportChange}
        interactiveLayerIds={[clusterLayer.id, unclusteredPointLayer.id] as string[]}
        onClick={onClick}
        {...viewport}
        width={width}
        height={height}
        reuseMaps
        ref={mapRef}
        attributionControl={false}
      >
        <NavigationControl style={navigationStyle} showCompass={false} />
        {popup.show && (
          <Popup
            latitude={popup.latitude}
            longitude={popup.longitude}
            closeButton={false}
            closeOnClick={false}
            anchor="left"
          >
            <ButtonBase
              onClick={onHeaderClick}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography variant="caption" sx={{ fontWeight: 'bold', lineHeight: '16px', mr: 1 }}>
                {popup.name}
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                <Box display="inline-flex">
                  {popup.siteSystemTypes?.map((systemType, i) => {
                    switch (systemType) {
                      case SystemType.ESS:
                        return <Icon icon="inverter" size={20} key={i} />;
                      case SystemType.MICROINVERTER:
                        return <Icon icon="microinverter" size={20} key={i} />;
                      default:
                        return null;
                    }
                  })}
                </Box>
                <Icon icon="chevron down" rotate={-90} size={14} />
              </Box>
            </ButtonBase>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                minWidth: 220,
                borderTop: `1px solid ${COLORS.LIGHT_GREY}`,
                pt: 1,
                pr: 2,
                mt: 1,
              }}
            >
              {!isFetchingSite ? (
                <>
                  <Box display="inline-flex" alignItems="center" py={0.5}>
                    <Icon className="icon" icon="solar" size={18} />
                    <Box ml={1}>
                      <Typography variant="caption" sx={{ fontWeight: 'bold', lineHeight: '100%' }}>
                        {numberToFixed(popup.installedPV, 2, '--')} kW Peak solar power
                      </Typography>
                    </Box>
                  </Box>
                  {popup.generatorStatus && (
                    <Box display="inline-flex" alignItems="center" py={0.5}>
                      <Icon className="icon" icon="generator" size={18} />
                      <Box ml={1}>
                        <Typography
                          variant="caption"
                          sx={{ fontWeight: 'bold', lineHeight: '100%' }}
                        >
                          {generatorStatusText}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  <Box display="inline-flex" alignItems="center" py={0.5}>
                    <Icon className="icon" icon="battery" size={18} />
                    <Box ml={1}>
                      <Typography variant="caption" sx={{ fontWeight: 'bold', lineHeight: '100%' }}>
                        {numberToFixed(popup.installedStorage, 2, '--')} kWh Storage capacity
                      </Typography>
                    </Box>
                  </Box>
                  {popup.loadManagerStatus && (
                    <Box display="inline-flex" alignItems="center" py={0.5}>
                      <Icon
                        className="icon"
                        icon={
                          popup.loadManagerStatus === LoadManagerStatus.ONLINE
                            ? 'load manager'
                            : 'load manager disconnected'
                        }
                        size={18}
                      />
                      <Box ml={1}>
                        <Typography
                          variant="caption"
                          sx={{ fontWeight: 'bold', lineHeight: '100%' }}
                        >
                          Load Manager Status: {loadManagerStatusText}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </>
              ) : (
                <Box display="flex" justifyContent="center" my={2}>
                  <Loader size={8} color={COLORS.LIGHT_ORANGE} />
                </Box>
              )}
            </Box>
            <Box
              sx={{
                lineHeight: '100%',
                borderTop: `1px solid ${COLORS.LIGHT_GREY}`,
                pt: 1.5,
                mt: 1,
              }}
            >
              <Typography variant="caption" color="textSecondary" lineHeight="100%">
                Install date: {popup.installedDate}
              </Typography>
            </Box>
          </Popup>
        )}
        <Source
          id={SOURCE_ID}
          type="geojson"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          data={convertedData}
          cluster={false}
          clusterMaxZoom={14}
          clusterRadius={50}
        >
          <Layer {...clusterLayerUnder} />
          <Layer {...clusterLayer} />
          <Layer {...clusterCountLayer} />
          <Layer {...unclusteredPointLayer} />
        </Source>
      </ReactMapGL>
    </>
  );
};
