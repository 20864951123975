import { Box, Typography } from '@mui/material';
import React from 'react';

import type { AddedSystem } from '../../types/AddSystemsToSite';
import { AddedSystemStatusName } from '../../types/AddSystemsToSite';
import { DeviceType } from '../../types/DeviceType';
import { getSystemInfoBySerial } from '../../utils';
import { Icon } from '../icon';

type Props = {
  systems: AddedSystem[];
  hasPairingError?: boolean;
};

const getSystemStatusText = (system: AddedSystem, hasPairingError?: boolean) => {
  const isConnected = system.systemConnectionStatus === AddedSystemStatusName.CONNECTED;
  const { type } = getSystemInfoBySerial(system.serialNumber);
  const isLoadManager = type === DeviceType.LOAD_MANAGER;
  if (isLoadManager && hasPairingError) {
    return 'The Load Manager failed to pair with the inverter. Please try again on the devices screen.';
  }
  if (isConnected) {
    return 'Connected to the internet.';
  }
  if (!isLoadManager) {
    return `This ${type.toLowerCase()} cannot be monitored until it is connected to the internet.`;
  }
};

const getSystemIcon = (system: AddedSystem, hasPairingError?: boolean) => {
  const isConnected = system.systemConnectionStatus === AddedSystemStatusName.CONNECTED;
  const { type } = getSystemInfoBySerial(system.serialNumber);
  const isLoadManager = type === DeviceType.LOAD_MANAGER;
  const hasError = !isConnected || (isLoadManager && hasPairingError);
  return hasError ? 'error alert orange' : 'alert success';
};

export const SystemConnectionStatusList = ({ systems, hasPairingError }: Props) => (
  <>
    {systems.map((system) => {
      const { iconName } = getSystemInfoBySerial(system.serialNumber);
      const statusText = getSystemStatusText(system, hasPairingError);
      const icon = getSystemIcon(system, hasPairingError);

      return (
        <Box mt={2} key={system.systemId}>
          <Box display="flex" flexDirection="row">
            {iconName ? <Icon icon={iconName} size={22} /> : null}
            <Box ml={1}>
              <Typography variant="subtitle2">{system.serialNumber}</Typography>
            </Box>
          </Box>
          {statusText ? (
            <Box mt={1} display="flex">
              <Icon icon={icon} size={22} />
              <Box ml={1}>
                <Typography variant="body1">{statusText}</Typography>
              </Box>
            </Box>
          ) : null}
        </Box>
      );
    })}
  </>
);
