import { Box, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { generatePath, NavLink } from 'react-router-dom';

import { usePageName } from '../../../hooks/usePageName';
import { useRoutes } from '../../../hooks/useRoutes';
import { useGetSite, useGetSiteSystems } from '../../../store/api/endpoints/site';
import { AlertsTab } from '../../../types/Alert';
import { AnalyticsPageName } from '../../../types/Analytics';
import { StyledFleetSections, StyledSection } from './index.styles';

const typographySx = {
  fontSize: '12px',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  lineHeight: '12px',
};

type Props = {
  companyId: string;
  siteId: string;
};

export const SiteContextMenu = ({ companyId, siteId }: Props) => {
  const routes = useRoutes();

  const { data: siteData } = useGetSite({ siteId });
  const { data: siteSystems } = useGetSiteSystems({ siteId });

  const {
    pages: {
      [AnalyticsPageName.SiteAccess]: siteAccessMatch,
      [AnalyticsPageName.SiteAlerts]: siteAlertsMatch,
      [AnalyticsPageName.SiteDevices]: siteDevicesMatch,
      [AnalyticsPageName.SiteDetails]: siteDetailsMatch,
      [AnalyticsPageName.SiteConfiguration]: siteConfigurationMatch,
    },
  } = usePageName();

  return (
    <Box ml={3}>
      <StyledFleetSections>
        <StyledSection>
          <NavLink
            exact
            strict
            to={generatePath(routes.SiteDashboard, { companyId, siteId })}
            data-test-id="site-dashboard-nav-link"
          >
            <Typography
              component="span"
              sx={{
                fontSize: 12,
                fontWeight: 'bold',
                textTransform: 'uppercase',
                lineHeight: '16px',
                overflowWrap: 'anywhere',
              }}
            >
              {siteData?.siteName || 'Loading...'}
            </Typography>
          </NavLink>
        </StyledSection>
        {!isEmpty(siteSystems) && (
          <StyledSection $active={siteDevicesMatch}>
            <NavLink
              replace
              to={generatePath(routes.SiteDevices, { companyId, siteId })}
              data-test-id="site-devices-nav-link"
            >
              <Typography component="span" color="inherit" sx={typographySx}>
                Devices
              </Typography>
            </NavLink>
          </StyledSection>
        )}
        <StyledSection $active={siteDetailsMatch}>
          <NavLink
            replace
            to={generatePath(routes.SiteDetails, { companyId, siteId })}
            data-test-id="site-details-nav-link"
          >
            <Typography component="span" color="inherit" sx={typographySx}>
              Details
            </Typography>
          </NavLink>
        </StyledSection>
        <StyledSection $active={siteAccessMatch}>
          <NavLink replace to={generatePath(routes.SiteAccess, { companyId, siteId })}>
            <Typography component="span" color="inherit" sx={typographySx}>
              Access
            </Typography>
          </NavLink>
        </StyledSection>
        {!isEmpty(siteSystems) && (
          <StyledSection $active={siteAlertsMatch}>
            <NavLink
              replace
              to={generatePath(routes.SiteAlerts, {
                companyId,
                siteId,
                tab: AlertsTab.ACTIVE,
              })}
              data-test-id="site-alerts-nav-link"
            >
              <Typography component="span" color="inherit" sx={typographySx}>
                Alerts
              </Typography>
            </NavLink>
          </StyledSection>
        )}
        {!isEmpty(siteSystems) && (
          <StyledSection $active={siteConfigurationMatch}>
            <NavLink replace to={generatePath(routes.SiteDevicesConfig, { companyId, siteId })}>
              <Typography component="span" color="inherit" sx={typographySx}>
                Configuration
              </Typography>
            </NavLink>
          </StyledSection>
        )}
      </StyledFleetSections>
    </Box>
  );
};
