import type { AddDetailsStepData } from '../store/addSite';
import type { AddressEditFields, UpdateAddressRequestBodyAddress } from '../types/Address';
import type {
  Address,
  SuggestionListInterface,
  ValidationSiteAddressInterface,
} from '../types/SiteDetails';

// @example [streetAddress, city, state, zip, countryCode]
export const inlineAddress = (addressArray: (string | undefined)[]) =>
  addressArray.filter((a) => !!a).join(', '); // filter removes falsy elements if they exist

export const makePuertoRicoReady = (values: ValidationSiteAddressInterface) => {
  if (values.countryCode === 'PR') {
    return { ...values, countryCode: 'US', state: 'PR' };
  }
  return values;
};

export const getSiteAddressForValidation = (values: AddDetailsStepData) => {
  return makePuertoRicoReady({
    address1: values.siteAddress1,
    city: values.siteCity,
    countryCode: values.siteCountry,
    postalCode: values.siteZip,
    state: values.siteState,
  });
};

export const getHomeownerAddressForValidation = (values: AddDetailsStepData) => {
  return makePuertoRicoReady({
    address1: values.homeownerAddress1,
    city: values.homeownerCity,
    countryCode: values.homeownerCountry,
    postalCode: values.homeownerZip,
    state: values.homeownerState,
  });
};

export const getSuggestionSiteAddress = (values: AddDetailsStepData): SuggestionListInterface => {
  return {
    businessName: values.homeownerBusinessName || ' ',
    address1: values.siteAddress1,
    city: values.siteCity,
    countryCode: values.siteCountry,
    postalCode: values.siteZip,
    state: values.siteState,
    longitude: `${values.longitude}`,
    latitude: `${values.latitude}`,
  };
};

export const getSuggestionHomeownerAddress = (
  values: AddDetailsStepData,
): SuggestionListInterface => {
  return {
    businessName: values.homeownerBusinessName || ' ',
    address1: values.homeownerAddress1,
    city: values.homeownerCity,
    countryCode: values.homeownerCountry,
    postalCode: values.homeownerZip,
    state: values.homeownerState,
    longitude: `${values.longitude}`,
    latitude: `${values.latitude}`,
  };
};

export const getAddSitePayloadAddress = ({
  address1,
  city,
  countryCode,
  state,
  postalCode,
  latitude,
  longitude,
}: SuggestionListInterface) => ({
  streetAddress: address1,
  city,
  countryCode,
  state,
  zip: postalCode,
  latitude,
  longitude,
});

export const mergeSuggestedAddressWithAddressEditFields = (
  addressEditFields: AddressEditFields,
  suggestedAddress: SuggestionListInterface,
): AddressEditFields => {
  return {
    ...addressEditFields,
    address1: suggestedAddress.address1,
    city: suggestedAddress.city,
    state: suggestedAddress.state,
    countryCode: suggestedAddress.countryCode,
    zip: suggestedAddress.postalCode,
    latitude: Number(suggestedAddress.latitude),
    longitude: Number(suggestedAddress.longitude),
  };
};

export const convertFormAddressToUpdateAddress = (
  address: Address | AddressEditFields,
): UpdateAddressRequestBodyAddress => {
  return {
    ...address,
    latitude: address.latitude?.toString() ?? '',
    longitude: address.longitude?.toString() ?? '',
  };
};

export const mapHomeownerAddressToUpdateAddressRequestBodyAddress = (
  address: Address | AddressEditFields,
): UpdateAddressRequestBodyAddress => {
  return {
    ...address,
    latitude: null,
    longitude: null,
  };
};

export const mapAddressToAddressEditFields = (
  address: Address | undefined,
  areAddressesEqual: boolean,
): AddressEditFields => {
  return {
    address1: address?.address1 ?? '',
    city: address?.city ?? '',
    state: address?.state ?? '',
    zip: address?.zip ?? '',
    countryCode: address?.countryCode ?? '',
    latitude: undefined,
    longitude: undefined,
    overrideCoordinates: false,
    useSiteAddress: areAddressesEqual,
  };
};

export const convertFormAddressToValidationAddress = (
  address: AddressEditFields,
): ValidationSiteAddressInterface => {
  const { zip, latitude, longitude, ...rest } = address;

  return { ...rest, postalCode: zip };
};

export const convertSuggestionAddressToUpdateAddress = (
  address: SuggestionListInterface,
): UpdateAddressRequestBodyAddress => {
  const { addressType, businessName, postalCode, ...rest } = address;

  return { ...rest, zip: postalCode };
};
