import type { AxiosError } from 'axios';
import { produce } from 'immer';
import type { ActionType } from 'typesafe-actions';
import { createReducer, getType } from 'typesafe-actions';

import type {
  AddSystemsToSiteFailure,
  AddSystemsToSiteSuccess,
} from '../../types/AddSystemsToSite';
import type { SystemDetails } from '../../types/Site';
import type { GetSiteSystemsErrorResponse, SiteSystem } from '../../types/System';
import * as actions from './actions';
import { AddSystemStep } from './stepsData';

export interface AddSystemsState {
  loading: boolean;
  errors: AddSystemsToSiteFailure | null;
  data: AddSystemsToSiteSuccess | null;
  systemsAdded: boolean;
  step: AddSystemStep;
  pairingRecord: Record<string, string>;
  pairingError: AxiosError | Error | null;
  isPairing: boolean;
  systems: SystemDetails[];
  siteSystems: SiteSystem[];
  siteSystemsLoading: boolean;
  siteSystemsError: GetSiteSystemsErrorResponse | null;
}

const INITIAL_STATE: AddSystemsState = {
  loading: false,
  errors: null,
  data: null,
  systemsAdded: false,
  step: AddSystemStep.PREPARE,
  pairingRecord: {},
  pairingError: null,
  isPairing: false,
  systems: [],
  siteSystems: [],
  siteSystemsLoading: false,
  siteSystemsError: null,
};

export type AddSystemsActions = ActionType<typeof actions>;

export default createReducer<AddSystemsState, AddSystemsActions>(INITIAL_STATE, {
  [getType(actions.addSystems.request)]: produce((state: AddSystemsState) => {
    state.errors = null;
    state.data = null;
    state.loading = true;
    state.systemsAdded = false;
  }),
  [getType(actions.addSystems.success)]: produce(
    (state: AddSystemsState, action: ReturnType<typeof actions.addSystems.success>) => {
      state.loading = false;
      state.data = action.payload.data;
      state.systemsAdded = true;
    },
  ),
  [getType(actions.addSystems.failure)]: produce(
    (state: AddSystemsState, action: ReturnType<typeof actions.addSystems.failure>) => {
      state.loading = false;
      state.errors = action.payload;
      state.systemsAdded = false;
    },
  ),
  [getType(actions.setAddSystemStep)]: produce(
    (state: AddSystemsState, action: ReturnType<typeof actions.setAddSystemStep>) => {
      state.step = action.payload;
    },
  ),
  [getType(actions.setSystems)]: produce(
    (state: AddSystemsState, action: ReturnType<typeof actions.setSystems>) => {
      state.systems = action.payload;
    },
  ),
  [getType(actions.setPairingRecord)]: produce(
    (state, { payload }: ReturnType<typeof actions.setPairingRecord>) => {
      state.pairingRecord = payload;
    },
  ),
  [getType(actions.loadSystemsDetails.request)]: produce((state) => {
    state.pairingError = null;
    state.isPairing = true;
  }),
  [getType(actions.loadSystemsDetails.failure)]: produce(
    (state, { payload }: ReturnType<typeof actions.loadSystemsDetails.failure>) => {
      state.pairingError = payload;
      state.isPairing = false;
    },
  ),
  [getType(actions.pairLoadManagers.success)]: produce((state) => {
    state.isPairing = false;
  }),
  [getType(actions.pairLoadManagers.failure)]: produce(
    (state, { payload }: ReturnType<typeof actions.pairLoadManagers.failure>) => {
      state.pairingError = payload;
      state.isPairing = false;
    },
  ),
  [getType(actions.loadSystems.request)]: produce((state: AddSystemsState) => {
    state.siteSystemsLoading = true;
    state.siteSystemsError = null;
  }),
  [getType(actions.loadSystems.success)]: produce(
    (state: AddSystemsState, action: ReturnType<typeof actions.loadSystems.success>) => {
      state.siteSystemsLoading = false;
      state.siteSystems = action.payload;
    },
  ),
  [getType(actions.loadSystems.failure)]: produce(
    (state: AddSystemsState, action: ReturnType<typeof actions.loadSystems.failure>) => {
      state.siteSystemsLoading = false;
      state.siteSystemsError = action.payload;
    },
  ),
  [getType(actions.clearLoadingErrorState)]: ({
    step,
    pairingRecord,
    systems,
  }: AddSystemsState) => ({
    ...INITIAL_STATE,
    step,
    pairingRecord,
    systems,
  }),
  [getType(actions.clearAddSystemsState)]: () => ({ ...INITIAL_STATE }),
});
