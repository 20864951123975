// @ts-nocheck
import type { User } from '../../types/api/getAllUsers';
import type { LoadManagerConfig } from '../../types/api/getLoadManagerConfigs';
import type { ParentCompanyAssociation } from '../../types/CompanyAssociation';
import { CompanyAssociationStatus } from '../../types/CompanyAssociation';
import { SystemStatusName } from '../../types/SiteDetails';
import type { System } from '../../types/SiteDevices';
import { SystemType } from '../../types/System';
import { getIsPairedLoadManager } from '../../utils/getIsPairedLoadManager';
import type { RootState } from '../types';
import { api } from '.';

/*
 * Typically selecting from store.api won't be necessary since RTK query hooks return data directly, but
 * selectors might be helpful for using an RTK query hook in a parent component and getting its output
 * in a child component - or, helpful in cutting out RxJS epics without excess collateral damage
 */

export const allUsersSelector = (state: RootState): User[] => {
  return api.endpoints.getAllUsers.select({})(state)?.data;
};

export const isES2SiteSelector = (state: RootState, { siteId }: { siteId: string }): boolean => {
  const { data } = api.endpoints.getSiteSystems.select({ siteId })(state);
  return data?.some((system) => system.systemType === SystemType.ES2);
};

export const isEverySystemNeverConnectedSelector = (
  state: RootState,
  { siteId }: { siteId: string },
): boolean => {
  const { data } = api.endpoints.getSiteStatus.select({ siteId })(state);
  return data?.systems.every((system) => system.statusName === SystemStatusName.NEVER_CONNECTED);
}; // returns true for empty sites

export const hasBatterySiteSelector = (
  state: RootState,
  { siteId }: { siteId: string },
): boolean => {
  const { data } = api.endpoints.getSiteStatus.select({ siteId })(state);
  return data?.systems.some((system) => !!system.battery);
};

export const siteShareCompanyAssociationsSelector = (
  state: RootState,
  { companyId, siteId }: { companyId: string; siteId: string },
): ParentCompanyAssociation[] => {
  const { data: companyAssociationsData } = api.endpoints.getCompanyAssociations.select({
    companyId,
  })(state);

  const { data: siteAccessData } = api.endpoints.getSiteAccess.select({
    siteId,
  })(state);

  const hasData = companyAssociationsData && siteAccessData;

  if (!hasData) {
    return [];
  }

  const companyAssociations = companyAssociationsData.sentInvitations;
  const siteAssociations = siteAccessData.siteAccessDetailsList;

  return companyAssociations.filter((companyAssociation) => {
    const isActive = companyAssociation.status === CompanyAssociationStatus.ACTIVE;
    const hasInviteeCompanySiteAssociations = siteAssociations.find(
      (siteAssociation) =>
        siteAssociation.companyId === companyAssociation.inviteeCompany.companyId,
    );
    return isActive && !hasInviteeCompanySiteAssociations;
  });
};

export const unpairedLoadManagerConfigsSelector = (
  state: RootState,
  { siteId }: { siteId: string },
): LoadManagerConfig[] => {
  const { data: siteDevices } = api.endpoints.getSiteDevices.select({
    siteId,
  })(state);

  const { data: loadManagerConfigs } = api.endpoints.getLoadManagerConfigs.select({
    siteId,
  })(state);

  const hasData = siteDevices && loadManagerConfigs;

  if (!hasData) {
    return [];
  }

  return loadManagerConfigs.filter(
    (config) => !siteDevices.systems.some((system) => getIsPairedLoadManager(system, config)),
  );
};

export const systemsWithoutLoadManagersSelector = (
  state: RootState,
  { siteId }: { siteId: string },
): System[] => {
  const { data: siteDevices } = api.endpoints.getSiteDevices.select({
    siteId,
  })(state);

  const { data: loadManagerConfigs } = api.endpoints.getLoadManagerConfigs.select({
    siteId,
  })(state);

  const hasData = siteDevices && loadManagerConfigs;

  if (!hasData) {
    return [];
  }

  return siteDevices.systems.filter(
    (system) => !loadManagerConfigs.some((config) => getIsPairedLoadManager(system, config)),
  );
};
