import type { SystemOrSiteError } from '../types/systemOrSiteError';
import { KNOWN_SITE_ERRORS, KNOWN_SYSTEM_ERRORS } from './knownErrors';

class HandleErrorMessage {
  splitSystemAndSiteErrors = (
    errorList: SystemOrSiteError[],
  ): { systemErrors: SystemOrSiteError[]; siteErrors: SystemOrSiteError[] } => {
    const splitErrorList: { systemErrors: SystemOrSiteError[]; siteErrors: SystemOrSiteError[] } = {
      systemErrors: [],
      siteErrors: [],
    };

    for (const error of errorList) {
      const errorCodeStr = error.errorCode.toString();

      if (this.isSiteError(errorCodeStr)) {
        splitErrorList.siteErrors.push(error);
      } else if (this.isSystemError(errorCodeStr)) {
        splitErrorList.systemErrors.push(error);
      }
    }

    return splitErrorList;
  };

  isSiteError = (errorCode: string): Boolean => {
    return Object.keys(KNOWN_SITE_ERRORS).includes(errorCode);
  };

  isSystemError = (errorCode: string): Boolean => {
    return Object.keys(KNOWN_SYSTEM_ERRORS).includes(errorCode);
  };

  getErrorMessage = (errorCode: number): string => {
    let msg = 'No Issues';

    if (!errorCode) {
      msg = 'Unknown error';
    } else if (errorCode !== 200) {
      msg = `Error ${errorCode}`;
    }

    return msg;
  };
}

const handleErrorMessage = new HandleErrorMessage();

Object.freeze(handleErrorMessage);

export default handleErrorMessage;
