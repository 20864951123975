import type { SxProps } from '@mui/material';
import { Box, Typography } from '@mui/material';
import type { ReactElement } from 'react';

import { Center } from '../center';

interface SettingInputWrapperProps {
  label: string;
  description: string;
  rootSx?: SxProps;
  name: string;
  settingInputSlotElement: ReactElement;
}

const rootContainerSx: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: {
    xs: 'stretch',
    md: 'flex-start',
  },
  flexDirection: {
    xs: 'column',
    md: 'row',
  },
};

const textContainerSx: SxProps = {
  mr: {
    md: '24px',
  },
  mb: {
    xs: '16px',
    md: 0,
  },
};

export const SettingInputWrapper = ({
  label,
  description,
  rootSx,
  name,
  settingInputSlotElement,
}: SettingInputWrapperProps) => {
  return (
    <Box sx={{ ...rootContainerSx, ...(rootSx ?? {}) }}>
      <Box sx={textContainerSx}>
        <Center
          sx={{
            height: 48,
            justifyContent: 'flex-start',
          }}
        >
          <Typography variant="subtitle2" data-test-class={`setting-input-label-${name}`}>
            {label}
          </Typography>
        </Center>
        <Typography
          variant="body2"
          data-test-class={`setting-input-description-${name}`}
          sx={{ mb: 1 }}
        >
          {description}
        </Typography>
      </Box>
      {settingInputSlotElement}
    </Box>
  );
};
