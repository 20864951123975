import type { AxiosError } from 'axios';
import type { ActionsObservable, Epic } from 'redux-observable';
import { combineEpics } from 'redux-observable';
import type { Observable } from 'rxjs';
import { EMPTY, forkJoin, from, of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  pairwise,
  withLatestFrom,
} from 'rxjs/operators';
import type { Action } from 'typesafe-actions';
import { isActionOf } from 'typesafe-actions';

import { trackEvent } from '../../analyticsClient';
import {
  getSystemDetails,
  getUtilities,
  pairLoadManager,
  setAddSite,
  setValidationSiteAddress,
} from '../../api';
import { AnalyticsEvent, AnalyticsProperty, AnalyticsToggleState } from '../../types/Analytics';
import type { LoadManagerPairRequest } from '../../types/LoadManager';
import type { AddSiteInterface, AddSiteSuccessResponse } from '../../types/SiteDetails';
import type { SystemDetailsSuccessResponse } from '../../types/SystemDetails';
import type { UtilitiesSuccessResponse } from '../../types/Utility';
import {
  getHomeownerAddressForValidation,
  getSiteAddressForValidation,
  getSuggestionHomeownerAddress,
  getSuggestionSiteAddress,
} from '../../utils';
import { userIdSelector } from '../auth/selectors';
import {
  clearAddressValidationErrors,
  getAddSite,
  getValidationSiteAddress,
  getValidationSiteAddressHomeowner,
  loadSystemsDetails,
  loadUtilities,
  pairLoadManagers,
  setAddDetailsStepData,
  setAddress,
  setHomeownerAddress,
  setPairingRecord,
  setSiteAddress,
  setStep,
  skipAddressValidation,
  updateSystemsInWizard,
} from './actions';
import {
  addDetailsDataSelector,
  addressValidationSuccessSelector,
  addSitePayloadSelector,
  currentStepSelector,
  homeownerAddressChangeRequiredSelector,
  homeownerAddressSuggestionListSelector,
  isAddressOverriddenSelector,
  loadManagersSelector,
  onePairRecordSelector,
  registeredSystemsForPairingSelector,
  siteAddressChangeRequiredSelector,
  siteAddressSuggestionListSelector,
} from './selectors';
import { AddSiteStep } from './stepsData';

const addSiteEpic: Epic = (action$: ActionsObservable<Action>, state$): Observable<Action> => {
  return action$.pipe(
    filter(isActionOf(getAddSite.request)),
    mergeMap((): Observable<Action> => {
      const userId = userIdSelector(state$.value);
      const params: AddSiteInterface = addSitePayloadSelector(state$.value);
      return from(setAddSite({ ...params, userId })).pipe(
        map((a) => {
          const isAddressOverridden = isAddressOverriddenSelector(state$.value);
          const res = a as AddSiteSuccessResponse;
          trackEvent(AnalyticsEvent.SiteRegister, {
            [AnalyticsProperty.SiteId]: res.id,
            [AnalyticsProperty.Count]: res.systems.length,
            [AnalyticsProperty.OverrideCoordinates]: isAddressOverridden,
          });
          return getAddSite.success(res);
        }),
        catchError(async (error: AxiosError) => {
          trackEvent(AnalyticsEvent.ApiError, {
            [AnalyticsProperty.ApiErrorCode]: error.response?.status,
            [AnalyticsProperty.ApiErrorMessage]:
              error.response?.data?.message || error.response?.data,
            [AnalyticsProperty.ApiCallName]: 'RegisterSiteRequest',
          });
          return getAddSite.failure({ ...error.response?.data, errorCode: error.response?.status });
        }),
      );
    }),
  );
};

const startGetDetailsFlowEpic: Epic = (
  action$: ActionsObservable<Action>,
  state$,
): Observable<Action> => {
  return action$.pipe(
    filter(isActionOf(getAddSite.success)),
    mergeMap((): Observable<Action> => {
      const systems = registeredSystemsForPairingSelector(state$.value);
      const systemIds = systems?.map((s) => s.inverter.systemId).filter(Boolean);
      if (systemIds && systemIds.length > 0) {
        return of(loadSystemsDetails.request({ systemIds }));
      }
      return of(setStep(AddSiteStep.SUCCESS));
    }),
  );
};

const getSystemsDetailsEpic: Epic = (action$: ActionsObservable<Action>): Observable<Action> => {
  return action$.pipe(
    filter(isActionOf(loadSystemsDetails.request)),
    mergeMap(({ payload }): Observable<Action> => {
      const { systemIds } = payload;
      const requests = systemIds?.map((systemId) => getSystemDetails({ systemId }));
      if (requests?.length) {
        return forkJoin(requests).pipe(
          map((response) => {
            const results = response as SystemDetailsSuccessResponse[];
            return loadSystemsDetails.success(results);
          }),
          catchError((e) => of(loadSystemsDetails.failure(e))),
        );
      }
      return EMPTY;
    }),
  );
};

const startPairingFlowEpic: Epic = (
  action$: ActionsObservable<Action>,
  state$,
): Observable<Action> => {
  return action$.pipe(
    filter(isActionOf(loadSystemsDetails.success)),
    mergeMap(({ payload: inverters }): Observable<Action> => {
      const systems = registeredSystemsForPairingSelector(state$.value);
      if (systems) {
        const systemsToPair = systems
          .map(({ loadManager, inverter }) => {
            const { systemId } = loadManager;
            const inverterDetails = inverters.find((i) => i.systemId === inverter.systemId);
            const { rcpId, beaconRcpn } = { ...inverterDetails };
            if (rcpId && beaconRcpn) {
              return {
                inverterRcpn: rcpId,
                beaconRcpn,
                systemId,
              };
            }
            return null;
          })
          .filter((item): item is LoadManagerPairRequest => !!item);

        return of(pairLoadManagers.request(systemsToPair));
      }
      return EMPTY;
    }),
  );
};

const systemsPairingEpic: Epic = (action$: ActionsObservable<Action>): Observable<Action> => {
  return action$.pipe(
    filter(isActionOf(pairLoadManagers.request)),
    mergeMap(({ payload }): Observable<Action> => {
      const requests = payload.map((p) => pairLoadManager(p));
      if (requests?.length) {
        return forkJoin(requests).pipe(
          map((response) => pairLoadManagers.success(response)),
          catchError((e) => of(pairLoadManagers.failure(e))),
        );
      }
      return of(pairLoadManagers.failure(new Error('There is no pairing requests')));
    }),
  );
};

const pairingFlowEndEpic: Epic = (action$: ActionsObservable<Action>): Observable<Action> => {
  return action$.pipe(
    filter(
      isActionOf([pairLoadManagers.success, pairLoadManagers.failure, loadSystemsDetails.failure]),
    ),
    map(() => {
      return setStep(AddSiteStep.SUCCESS);
    }),
  );
};

const validationSiteAddressEpic: Epic = (
  action$: ActionsObservable<Action>,
): Observable<Action> => {
  return action$.pipe(
    filter(isActionOf(getValidationSiteAddress.request)),
    mergeMap((action): Observable<Action> => {
      return from(setValidationSiteAddress(action.payload)).pipe(
        map((data) => {
          if (!data.suggestionList?.length) {
            return getValidationSiteAddress.failure({
              errorCode: data.errorCode,
              errorMsg: data.errorDescription!,
            });
          }
          return getValidationSiteAddress.success(data);
        }),
        catchError(async (error) => getValidationSiteAddress.failure(error.response.data)),
      );
    }),
  );
};

const validationSiteAddressHomeownerEpic: Epic = (
  action$: ActionsObservable<Action>,
): Observable<Action> => {
  return action$.pipe(
    filter(isActionOf(getValidationSiteAddressHomeowner.request)),
    mergeMap((action): Observable<Action> => {
      return from(setValidationSiteAddress(action.payload)).pipe(
        map((data) => {
          if (!data.suggestionList?.length) {
            return getValidationSiteAddressHomeowner.failure({
              errorCode: data.errorCode,
              errorMsg: data.errorDescription!,
            });
          }
          return getValidationSiteAddressHomeowner.success(data);
        }),
        catchError(async (error) => getValidationSiteAddressHomeowner.failure(error.response.data)),
      );
    }),
  );
};

/**
 * Condition: skipAddressValidation action
 * Result: set selectedAddress in SITE_ADDRESS_VERIFICATION and HOMEOWNER_ADDRESS_VERIFICATION states using pure
 * form data and set step to ADD_SYSTEM_DETAILS
 **/
const skipAddressValidationEpic: Epic = (
  action$: ActionsObservable<Action>,
  state$,
): Observable<Action> => {
  return action$.pipe(
    filter(isActionOf(skipAddressValidation)),
    mergeMap((): Observable<Action> => {
      const formData = addDetailsDataSelector(state$.value);
      return of(
        setSiteAddress(getSuggestionSiteAddress(formData)),
        setHomeownerAddress(getSuggestionHomeownerAddress(formData)),
        setStep(AddSiteStep.ADD_SYSTEM_DETAILS),
      );
    }),
  );
};

/**
 * Condition: setAddDetailsStepData action
 * Result: call skipAddressValidation action or call one or two address validation requests actions depending on
 * addressOverride and useSiteAddress flags
 **/
const addDetailsStepEpic: Epic = (action$: ActionsObservable<Action>): Observable<Action> =>
  action$.pipe(
    filter(isActionOf(setAddDetailsStepData)),
    mergeMap((action): Observable<Action> => {
      const { data, validate } = action.payload;
      const { addressOverride, useSiteAddress } = data;
      if (!validate) {
        return EMPTY;
      }
      if (addressOverride) {
        trackEvent(AnalyticsEvent.SiteRegCoordinatesOverride, {
          [AnalyticsProperty.ToggleState]: AnalyticsToggleState.ON,
        });
        return of(skipAddressValidation());
      } else {
        trackEvent(AnalyticsEvent.SiteRegCoordinatesOverride, {
          [AnalyticsProperty.ToggleState]: AnalyticsToggleState.OFF,
        });
      }
      if (useSiteAddress) {
        return of(getValidationSiteAddress.request(getSiteAddressForValidation(data)));
      }
      return of(
        getValidationSiteAddress.request(getSiteAddressForValidation(data)),
        getValidationSiteAddressHomeowner.request(getHomeownerAddressForValidation(data)),
      );
    }),
  );

/**
 * Condition: getValidationSiteAddress.success action
 * Result: if site address suggestion list have only one address set it as selected
 **/
const finishSiteAddressValidationEpic: Epic = (
  action$: ActionsObservable<Action>,
  state$,
): Observable<Action> => {
  return action$.pipe(
    filter(isActionOf(getValidationSiteAddress.success)),
    mergeMap((): Observable<Action> => {
      const isChangeRequired = siteAddressChangeRequiredSelector(state$.value);
      const siteAddressList = siteAddressSuggestionListSelector(state$.value);
      if (!isChangeRequired && siteAddressList?.length) {
        return of(setSiteAddress(siteAddressList[0]));
      }
      return EMPTY;
    }),
  );
};

/**
 * Condition: getValidationSiteAddressHomeowner.success action
 * Result: if homeowner address suggestion list have only one address set it as selected
 **/
const finishHomeownerAddressValidationEpic: Epic = (
  action$: ActionsObservable<Action>,
  state$,
): Observable<Action> => {
  return action$.pipe(
    filter(isActionOf(getValidationSiteAddressHomeowner.success)),
    mergeMap((): Observable<Action> => {
      const isChangeRequired = homeownerAddressChangeRequiredSelector(state$.value);
      const homeownerAddressList = homeownerAddressSuggestionListSelector(state$.value);
      if (!isChangeRequired && homeownerAddressList?.length) {
        return of(setHomeownerAddress(homeownerAddressList[0]));
      }
      return EMPTY;
    }),
  );
};

/**
 * Condition: site address validation and homeowner address validation are not in progress and response data exists
 * Result: set step SITE_ADDRESS_VERIFICATION
 **/
const finishAllAddressValidationEpic: Epic = (
  action$: ActionsObservable<Action>,
  state$,
): Observable<Action> => {
  return state$.pipe(
    map(addressValidationSuccessSelector),
    distinctUntilChanged(),
    filter(Boolean),
    map(() => {
      return setStep(AddSiteStep.SITE_ADDRESS_VERIFICATION);
    }),
  );
};

const isStepAction = (step: AddSiteStep) => (action: Action) =>
  isActionOf(setStep)(action) && action.payload === step;

/**
 * Condition: setStep action with SITE_ADDRESS_VERIFICATION payload
 * Result: if site address suggestion list have only one address or addressOverride is true then skip
 * SITE_ADDRESS_VERIFICATION step and set HOMEOWNER_ADDRESS_VERIFICATION or ADD_DETAILS step depending on wizard direction
 * Notes: direction of wizard is calculated by comparing the steps in the old and new state
 **/
const skipSiteAddressVerificationEpic: Epic = (
  action$: ActionsObservable<Action>,
  state$,
): Observable<Action> => {
  const statePairs$ = state$.pipe(pairwise());
  return action$.pipe(
    filter(isStepAction(AddSiteStep.SITE_ADDRESS_VERIFICATION)),
    withLatestFrom(statePairs$),
    mergeMap(([, [oldState, newState]]): Observable<Action> => {
      const currentStep = currentStepSelector(newState);
      const prevStep = currentStepSelector(oldState);

      const { addressOverride } = addDetailsDataSelector(state$.value);
      const isChangeRequired = siteAddressChangeRequiredSelector(state$.value);
      if (addressOverride || !isChangeRequired) {
        if (currentStep > prevStep) {
          return of(setStep(AddSiteStep.HOMEOWNER_ADDRESS_VERIFICATION));
        }
        return of(setStep(AddSiteStep.ADD_DETAILS));
      }
      return EMPTY;
    }),
  );
};

/**
 * Condition: setStep action with HOMEOWNER_ADDRESS_VERIFICATION payload
 * Result: if homeowner address suggestion list have only one address or useSiteAddress is true or
 * addressOverride is true then skip HOMEOWNER_ADDRESS_VERIFICATION step and set ADD_SYSTEM_DETAILS or
 * SITE_ADDRESS_VERIFICATION step depending on wizard direction
 * Notes: direction of wizard is calculated by comparing the steps in the old and new state
 **/
const skipHomeAddressVerificationEpic: Epic = (
  action$: ActionsObservable<Action>,
  state$,
): Observable<Action> => {
  const statePairs$ = state$.pipe(pairwise());
  return action$.pipe(
    filter(isStepAction(AddSiteStep.HOMEOWNER_ADDRESS_VERIFICATION)),
    withLatestFrom(statePairs$),
    mergeMap(([, [oldState, newState]]): Observable<Action> => {
      const currentStep = currentStepSelector(newState);
      const prevStep = currentStepSelector(oldState);

      const { useSiteAddress, addressOverride } = addDetailsDataSelector(state$.value);
      const isChangeRequired = homeownerAddressChangeRequiredSelector(state$.value);
      if (addressOverride || useSiteAddress || !isChangeRequired) {
        if (currentStep > prevStep) {
          return of(setStep(AddSiteStep.ADD_SYSTEM_DETAILS));
        }
        return of(setStep(AddSiteStep.SITE_ADDRESS_VERIFICATION));
      }
      return EMPTY;
    }),
  );
};

/**
 * Condition: setAddress action
 * Result: set homeowner address or site address depending on current step
 **/
const setAddressEpic: Epic = (action$: ActionsObservable<Action>, state$): Observable<Action> =>
  action$.pipe(
    filter(isActionOf(setAddress)),
    mergeMap((action): Observable<Action> => {
      const currentStep = currentStepSelector(state$.value);
      if (currentStep === AddSiteStep.HOMEOWNER_ADDRESS_VERIFICATION) {
        return of(setHomeownerAddress(action.payload));
      }
      return of(setSiteAddress(action.payload));
    }),
  );

/**
 * Condition: setStep action with ADD_DETAILS payload
 * Result: clear address validation data and errors
 **/
const clearValidationDataEpic: Epic = (action$: ActionsObservable<Action>): Observable<Action> => {
  return action$.pipe(
    filter(isStepAction(AddSiteStep.ADD_DETAILS)),
    map(clearAddressValidationErrors),
  );
};

const loadUtilitiesEpic: Epic = (action$) =>
  action$.pipe(
    filter(isActionOf(loadUtilities.request)),
    mergeMap((action) => {
      return from(getUtilities(action.payload)).pipe(
        map((data) => loadUtilities.success(data as UtilitiesSuccessResponse)),
        catchError((e) => of(loadUtilities.failure(e))),
      );
    }),
  );

/**
 * Condition: systems change
 * Result: if there is only one pair inverter - load manager then the value of the pair is set
 * else pairing record is cleared
 **/
const setDefaultPairingRecordEpic: Epic = (
  action$: ActionsObservable<Action>,
  state$,
): Observable<Action> => {
  return action$.pipe(
    filter(isActionOf(updateSystemsInWizard)),
    map(() => {
      const onePairRecord = onePairRecordSelector(state$.value);
      return setPairingRecord(onePairRecord || {});
    }),
  );
};

/**
 * Condition: there is no load managers
 * Result: pairing step skipped
 **/
const skipPairingStepEpic: Epic = (
  action$: ActionsObservable<Action>,
  state$,
): Observable<Action> => {
  const statePairs$ = state$.pipe(pairwise());
  return action$.pipe(
    filter(isStepAction(AddSiteStep.PAIRING)),
    withLatestFrom(statePairs$),
    mergeMap(([, [oldState, newState]]): Observable<Action> => {
      const currentStep = currentStepSelector(newState);
      const prevStep = currentStepSelector(oldState);
      const noLoadManagers = !loadManagersSelector(newState).length;

      if (noLoadManagers) {
        if (currentStep > prevStep) {
          return of(setStep(AddSiteStep.REVIEW));
        }
        return of(setStep(AddSiteStep.ADD_SYSTEM_DETAILS));
      }
      return EMPTY;
    }),
  );
};

export default combineEpics(
  addSiteEpic,
  validationSiteAddressEpic,
  validationSiteAddressHomeownerEpic,
  skipAddressValidationEpic,
  addDetailsStepEpic,
  finishSiteAddressValidationEpic,
  skipSiteAddressVerificationEpic,
  finishHomeownerAddressValidationEpic,
  skipHomeAddressVerificationEpic,
  finishAllAddressValidationEpic,
  setAddressEpic,
  clearValidationDataEpic,
  loadUtilitiesEpic,
  setDefaultPairingRecordEpic,
  skipPairingStepEpic,
  startGetDetailsFlowEpic,
  getSystemsDetailsEpic,
  startPairingFlowEpic,
  systemsPairingEpic,
  pairingFlowEndEpic,
);
