import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { init as analyticsInit, trackEvent as analyticsClientTrackEvent } from './analyticsClient';
import { init as apiInit } from './api';
import { getAccessToken } from './api/token';
import { init as messagingInit } from './messagingClient';
import store, { persistor } from './store';
// import * as serviceWorker from "./serviceWorker";
import { refreshAuth, setAuthData } from './store/auth/actions';
import { AnalyticsEvent } from './types/Analytics';
import type { AuthRefreshTokenResponse } from './types/Auth';
import { getAuthDataFromAccessToken, getRefreshTokenFromStore } from './utils';

const root = document.getElementById('root');

const render = () => {
  const App = require('./App').default;
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>,
    root,
  );
};

const onTokenRefreshSuccess = (authRefreshTokenResponse: AuthRefreshTokenResponse) => {
  const authData = getAuthDataFromAccessToken(authRefreshTokenResponse);
  store.dispatch(setAuthData(authData));
  analyticsClientTrackEvent(AnalyticsEvent.AccessTokenRefresh);
};

const onTokenRefreshFailed = (e: Error) => {
  store.dispatch(refreshAuth.failure(e));
};

const start = () => {
  const interceptorCallbacks = {
    onTokenRefreshSuccess,
    onTokenRefreshFailed,
  };
  apiInit(interceptorCallbacks);

  const accessToken = getAccessToken();
  const refreshToken = getRefreshTokenFromStore();
  if (!accessToken && refreshToken) {
    store.dispatch(refreshAuth.request());
  }

  analyticsInit(); // mixpanel
  messagingInit(); // appcues

  render();
};

persistor.subscribe(() => {
  start();
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
