import type { User } from '../../../types/api/getAllUsers';
import type { UpdateHomeownerEmailRequest } from '../../../types/SiteDetails';
import { api } from '..';
import { getAllUsersQueryFn } from '../queryFunctions';

export const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    resetHomeownerPassword: builder.mutation<
      { temporaryPassword: string },
      { homeownerUserId: string }
    >({
      query: ({ homeownerUserId }) => ({
        method: 'POST',
        url: `/pwrfleet-ui/v1/users/${homeownerUserId}/password-reset`,
      }),
    }),
    updateHomeownerEmail: builder.mutation<void, UpdateHomeownerEmailRequest>({
      query: ({ oldEmail, newEmail }) => ({
        method: 'PATCH',
        url: `/user-ms/v2/users/update-homeowner-email`,
        body: { oldEmail, newEmail },
      }),
    }),
    getAllUsers: builder.query<User[], {}>({
      queryFn: getAllUsersQueryFn,
    }),
  }),
});

export const {
  useGetAllUsersQuery: useGetAllUsers,
  useUpdateHomeownerEmailMutation: useUpdateHomeownerEmail,
  useResetHomeownerPasswordMutation: useResetHomeownerPassword,
} = userApi;
