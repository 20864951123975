import { createAction, createAsyncAction } from 'typesafe-actions';

import type {
  AddSystemsToSiteFailure,
  AddSystemsToSiteSuccess,
  SystemsInterface,
} from '../../types/AddSystemsToSite';
import type {
  LoadManagerPairFailureResponse,
  LoadManagerPairRequest,
  LoadManagerPairSuccessResponse,
} from '../../types/LoadManager';
import type { SystemDetails } from '../../types/Site';
import type {
  GetSiteSystemsErrorResponse,
  GetSiteSystemsSuccessResponse,
} from '../../types/System';
import type {
  SystemDetailsFailureResponse,
  SystemDetailsSuccessResponse,
} from '../../types/SystemDetails';
import type { AddSystemStep } from './stepsData';

export interface AddSystemsToSiteRequestParams {
  siteId: string;
  systems: SystemsInterface[];
}

export const addSystems = createAsyncAction(
  'ADD_SYSTEMS_REQUEST',
  'ADD_SYSTEMS_SUCCESS',
  'ADD_SYSTEMS_FAILURE',
)<
  AddSystemsToSiteRequestParams,
  { siteId: string; data: AddSystemsToSiteSuccess },
  AddSystemsToSiteFailure
>();

export const clearAddSystemsState = createAction('CLEAR_ADD_SYSTEM_STATE')();

export const setAddSystemStep = createAction(
  'ADD_SYSTEM_SET_STEP',
  (step: AddSystemStep) => step,
)<AddSystemStep>();

export const setSystems = createAction(
  'ADD_SYSTEM_SET_SYSTEMS',
  (systems: SystemDetails[]) => systems,
)<SystemDetails[]>();

export const setPairingRecord = createAction('ADD_SYSTEM_SET_PAIRING_RECORD')<
  Record<string, string>
>();

export const clearLoadingErrorState = createAction('CLEAR_LOADING_ERROR_STATE')();

export const loadSystemsDetails = createAsyncAction(
  'ADD_SYSTEM_LOAD_SYSTEMS_DETAILS_REQUEST',
  'ADD_SYSTEM_LOAD_SYSTEMS_DETAILS_SUCCESS',
  'ADD_SYSTEM_LOAD_SYSTEMS_DETAILS_FAILURE',
)<
  { siteId: string; systemIds: string[] },
  { siteId: string; systems: SystemDetailsSuccessResponse[] },
  SystemDetailsFailureResponse
>();

export const pairLoadManagers = createAsyncAction(
  'ADD_SYSTEM_PAIR_LOAD_MANAGERS_REQUEST',
  'ADD_SYSTEM_PAIR_LOAD_MANAGERS_SUCCESS',
  'ADD_SYSTEM_PAIR_LOAD_MANAGERS_FAILURE',
)<
  LoadManagerPairRequest[],
  LoadManagerPairSuccessResponse[],
  LoadManagerPairFailureResponse | Error
>();

export const loadSystems = createAsyncAction(
  'ADD_SYSTEM_LOAD_SYSTEMS_REQUEST',
  'ADD_SYSTEM_LOAD_SYSTEMS_SUCCESS',
  'ADD_SYSTEM_LOAD_SYSTEMS_FAILURE',
)<{ siteId: string }, GetSiteSystemsSuccessResponse, GetSiteSystemsErrorResponse>();
