import { AnalyticsPageName } from '../types/Analytics';
import { DeviceType } from '../types/DeviceType';
import { DevicesTab } from '../types/Tabs';

export interface DevicesTabDefinition {
  name: string;
  supportedDeviceTypes: DeviceType[];
  analyticsPageName: AnalyticsPageName;
}

export const DevicesTabDefinitions: { [key in DevicesTab]: DevicesTabDefinition } = {
  [DevicesTab.OVERVIEW]: {
    name: 'Overview',
    supportedDeviceTypes: [
      DeviceType.BEACON,
      DeviceType.LOAD_MANAGER,
      DeviceType.INVERTER,
      DeviceType.RGM,
      DeviceType.BATTERY,
      DeviceType.PVLINK,
      DeviceType.GATEWAY_PENGUIN,
      DeviceType.DISCONNECT_SWITCH_MANTA,
      DeviceType.BATTERY_R2_BATTERY,
      DeviceType.INVERTER_LEANCORE,
    ],
    analyticsPageName: AnalyticsPageName.SiteDevices,
  },
  [DevicesTab.METRICS]: {
    name: 'Metrics',
    supportedDeviceTypes: [
      DeviceType.BEACON,
      DeviceType.INVERTER,
      DeviceType.BATTERY,
      DeviceType.PVLINK,
    ],
    analyticsPageName: AnalyticsPageName.SiteDeviceMetrics,
  },
  [DevicesTab.CONFIGURATION]: {
    name: 'Configuration',
    supportedDeviceTypes: [DeviceType.INVERTER, DeviceType.BATTERY],
    analyticsPageName: AnalyticsPageName.SiteConfiguration,
  },
};
