import type { AxiosError } from 'axios';

export enum SolarFilterOptions {
  UNKNOWN = 'UNKNOWN',
  YES = 'YES',
  NO = 'NO',
}

type Utility = {
  utilityId: number;
  name: string;
};

export type UtilitiesRequest = {
  zipcode: string;
};

export type UtilitiesSuccessResponse = Utility[];
export type UtilitiesFailedResponse = AxiosError;

export type UtilitiesResponse = UtilitiesSuccessResponse | UtilitiesFailedResponse;
