import { Box, Hidden } from '@mui/material';
// import { Link, Typography } from '@mui/material';
import clsx from 'clsx';
import type { MouseEvent } from 'react';
import { useEffect } from 'react';
import { generatePath, useHistory } from 'react-router-dom';

import { trackEvent } from '../../../analyticsClient';
import { LocalStorageItem } from '../../../constants/localStorage';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { useCompanyContext } from '../../../hooks/useCompanyContext';
import { useExactRouteMatch } from '../../../hooks/useExactRouteMatch';
import { useRoutes } from '../../../hooks/useRoutes';
import { useSiteContext } from '../../../hooks/useSiteContext';
import { isSupportModeSelector } from '../../../store/auth/selectors';
import { selectedCompanySelector } from '../../../store/company/selectors';
import { AlertsTab } from '../../../types/Alert';
import { AnalyticsEvent, AnalyticsProperty } from '../../../types/Analytics';
import { UIFeature } from '../../../types/UIFeature';
import { GatedFeature } from '../../gatedFeature';
import { Icon } from '../../icon';
import { NavigationMenuItem } from '../../navigationMenuItem';
// import { GENERAC_FEEDBACK } from '../../../constants/general';
import { PWRbutton } from '../../pwrButton';
import { LegalContent } from '../LegalContent';
import { UserHeader } from '../UserHeader';
import { HeaderContainer, StyledButtons, StyledNav } from './index.styles';
import { SiteContextMenu } from './siteContextMenu';

interface Props {
  className?: string;
  onClick?(
    e: MouseEvent<HTMLDivElement> | MouseEvent<HTMLLIElement> | MouseEvent<HTMLButtonElement>,
  ): void;
  toggleDrawer: (hide?: boolean) => void;
}

export const Navbar = ({ className, onClick, toggleDrawer }: Props) => {
  const Routes = useRoutes();
  const { siteId } = useSiteContext();
  const { companyId } = useCompanyContext();
  const isSupportLevelRoute = useExactRouteMatch([Routes.CompanyList, Routes.UsersList]);
  const history = useHistory();
  // in support mode, we hide the company info area here until we are in
  // a specific company context
  const isSupportMode = useAppSelector(isSupportModeSelector);
  const hideCompanyContext = !companyId || Boolean(isSupportMode && isSupportLevelRoute);
  const onAddSite = (e: MouseEvent<HTMLButtonElement>) => {
    history.push({ search: '?add-site-modal' });
    toggleDrawer && toggleDrawer(true);
    onClick?.(e);
    trackEvent(AnalyticsEvent.AddSiteButtonClick);
  };

  const selectedCompany = useAppSelector(selectedCompanySelector);

  const isSupportPortalEnabled = selectedCompany?.validatedInSalesforce;

  useEffect(() => {
    const eventLocalStorageValue = localStorage.getItem(
      LocalStorageItem.VALIDATED_COMPANY_APP_VIEW_EVENT_TRACKED,
    );
    const isValidatedCompanyAppViewEventTracked = eventLocalStorageValue
      ? JSON.parse(eventLocalStorageValue)
      : false;

    if (selectedCompany?.validatedInSalesforce && !isValidatedCompanyAppViewEventTracked) {
      trackEvent(AnalyticsEvent.SalesforceValidatedCompanyAppLoad, {
        [AnalyticsProperty.CompanyName]: selectedCompany.name,
      });
      localStorage.setItem(
        LocalStorageItem.VALIDATED_COMPANY_APP_VIEW_EVENT_TRACKED,
        JSON.stringify(true),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompany?.validatedInSalesforce]);

  const handleOnClick = (e: MouseEvent<HTMLDivElement> | MouseEvent<HTMLLIElement>) => {
    toggleDrawer && toggleDrawer(true);
    onClick?.(e);
  };

  return (
    <>
      <StyledNav className={className}>
        <HeaderContainer py={2} px={1} $disableBorder={hideCompanyContext}>
          <Hidden mdDown>
            <UserHeader toggleDrawer={toggleDrawer} hideCompanyContext={hideCompanyContext} />
          </Hidden>
          <Hidden mdUp>
            <div className="nav-back" onClick={handleOnClick}>
              <Icon icon="chevron down" rotate={90} size={30} className="nav-back__icon" />
            </div>
          </Hidden>
        </HeaderContainer>
        {!hideCompanyContext && (
          <Box px={2}>
            <ul className={clsx('nav-menu', !siteId ? 'nav-menu--closed' : '')}>
              <NavigationMenuItem
                title="All sites"
                icon="fleet"
                to={generatePath(Routes.CompanySitesList, { companyId })}
                exact
                routes={[Routes.CompanySitesMap]}
                onClick={handleOnClick}
              >
                {!!siteId && !!companyId && (
                  <SiteContextMenu companyId={companyId} siteId={siteId} />
                )}
              </NavigationMenuItem>
              <NavigationMenuItem
                title="All Alerts"
                icon="alerts"
                to={generatePath(Routes.CompanyAlerts, { companyId, tab: AlertsTab.ACTIVE })}
                exact
                routes={[Routes.CompanyAlerts]}
                onClick={handleOnClick}
              />
              {isSupportPortalEnabled && (
                <NavigationMenuItem
                  title="Support"
                  icon="support"
                  to={generatePath(Routes.CompanySupport, { companyId })}
                  exact
                  routes={[Routes.CompanySupport]}
                  onClick={handleOnClick}
                />
              )}
            </ul>
          </Box>
        )}
        <StyledButtons className="nav-add-site">
          {!hideCompanyContext && (
            <>
              {!siteId && (
                <GatedFeature
                  uiFeature={UIFeature.SITE_ADD}
                  onAllowed={() => (
                    <PWRbutton
                      id="add-site-btn"
                      color="primary"
                      variant="outlined"
                      text="Add Site"
                      onClick={onAddSite}
                    />
                  )}
                />
              )}
            </>
          )}
          {/* 
            // July 10, 2024, link broken due to Aha! license termination
            // commenting out until new feedback solution is found
            <Link
              rel="noreferrer"
              href={GENERAC_FEEDBACK}
              onClick={(event) => {
                event.preventDefault();
                trackEvent(AnalyticsEvent.ProvideFeedbackButtonClick);
                window.open(GENERAC_FEEDBACK, '_blank');
              }}
              sx={{
                marginTop: 'auto',
                display: 'flex',
                alignItems: 'center',
                alignSelf: 'flex-start',
                textDecoration: 'none',
              }}
            >
              <Icon icon="feedback" />
              <Box ml={1}>
                <Typography variant="body2">Provide Feedback</Typography>
              </Box>
            </Link> */}
        </StyledButtons>
        <LegalContent />
      </StyledNav>
    </>
  );
};
