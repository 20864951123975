import type { AddSystemsRequest, ResponseSystem } from '../../../types/api/addSystems';
import type {
  GetSystemValidationRequest,
  GetSystemValidationResponse,
} from '../../../types/api/getSystemValidation';
import type { UpdateSystemRequest } from '../../../types/api/updateSystemDevices';
import type {
  BatteriesSettingsResponse,
  GetBatteriesSettingsQueryArgs,
  UpdateBatterySettingQueryArgs,
} from '../../../types/BatterySettings';
import type {
  GetInverterSettingsQueryArgs,
  InverterSettingsResponse,
  UpdateInverterSettingQueryArgs,
} from '../../../types/InverterSettings';
import { ChartType } from '../../../types/SiteMetrics';
import type { SystemDetailsSuccessResponse } from '../../../types/SystemDetails';
import type {
  SystemModeMutationArgs,
  SystemModeMutationResponse,
  SystemModeQueryArgs,
  SystemModeQueryResponse,
} from '../../../types/SystemMode';
import type {
  SystemModeEventsRequestBody,
  SystemModeEventsResponse,
} from '../../../types/SystemModeEvents';
import { getFilenameFromContentDisposition } from '../../../utils';
import { triggerFileDownload } from '../../../utils/files';
import { api } from '..';

const systemApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSystemModeEvents: builder.query<
      SystemModeEventsResponse,
      { urlParams: { siteId: string; systemId: string }; body: SystemModeEventsRequestBody }
    >({
      query: ({ urlParams, body }) => ({
        method: 'POST',
        url: `/pwrfleet-ui/v1/sites/${urlParams.siteId}/systems/${urlParams.systemId}/history`,
        body,
      }),
    }),
    getSystemMode: builder.query<SystemModeQueryResponse, SystemModeQueryArgs>({
      query: ({ siteId, systemId }) =>
        `/pwrfleet-ui/v1/sites/${siteId}/systems/${systemId}/setting`,
      providesTags: (_result, _meta, args) => [{ type: 'SystemMode', id: args.systemId }],
    }),
    updateSystemMode: builder.mutation<SystemModeMutationResponse, SystemModeMutationArgs>({
      query: ({ urlParams, body }) => ({
        method: 'PATCH',
        url: `/pwrfleet-ui/v1/sites/${urlParams.siteId}/systems/${urlParams.systemId}/setting`,
        body,
      }),
      invalidatesTags: (_result, _meta, args) => [
        { type: 'SystemMode', id: args.urlParams.systemId },
        { type: 'SiteStatus', id: args.urlParams.siteId },
      ],
    }),
    getBatteriesSettings: builder.query<BatteriesSettingsResponse, GetBatteriesSettingsQueryArgs>({
      query: ({ siteId, systemId }) =>
        `/pwrfleet-ui/v1/sites/${siteId}/systems/${systemId}/batteries/settings`,
      providesTags: (_result, _meta, args) => [{ type: 'BatterySettings', id: args.systemId }],
    }),
    updateBatterySetting: builder.mutation<void, UpdateBatterySettingQueryArgs>({
      query: ({ siteId, systemId, deviceId, settingName, value }) => {
        return {
          url: `/pwrfleet-ui/v1/sites/${siteId}/systems/${systemId}/batteries/${deviceId}/settings`,
          method: 'PATCH',
          body: {
            settings: [
              {
                name: settingName,
                value,
              },
            ],
          },
        };
      },
      invalidatesTags: (_result, _meta, args) => [{ type: 'BatterySettings', id: args.systemId }],
    }),
    getInverterSettings: builder.query<InverterSettingsResponse, GetInverterSettingsQueryArgs>({
      query: ({ siteId, systemId }) =>
        `/pwrfleet-ui/v1/sites/${siteId}/systems/${systemId}/inverters/settings`,
      providesTags: (_result, _meta, args) => [{ type: 'InverterSettings', id: args.systemId }],
    }),
    updateInverterSetting: builder.mutation<void, UpdateInverterSettingQueryArgs>({
      query: ({ siteId, systemId, deviceId, settingName, value }) => {
        return {
          url: `/pwrfleet-ui/v1/sites/${siteId}/systems/${systemId}/inverters/${deviceId}/settings`,
          method: 'PATCH',
          body: {
            settings: [
              {
                name: settingName,
                value,
              },
            ],
          },
        };
      },
      invalidatesTags: (_result, _meta, args) => [{ type: 'InverterSettings', id: args.systemId }],
    }),
    getSystemValidation: builder.mutation<GetSystemValidationResponse, GetSystemValidationRequest>({
      query: (systemDetails) => ({
        url: `/sites/v2/validation/systems`,
        method: 'POST',
        body: systemDetails,
      }),
    }),
    downloadChartCsv: builder.mutation<
      any,
      { type: ChartType; url: string; params: { [key: string]: any }; body?: any }
    >({
      queryFn: async ({ type, url, params, body }, _queryApi, _extraOptions, baseQuery) => {
        if (type === ChartType.SINGLE_DEVICE) {
          const result: any = await baseQuery({
            url,
            method: 'POST',
            body,
            params,
            headers: {
              Accept: 'text/csv',
            },
            responseHandler: (response) => response.blob(),
          });

          const blob = new Blob([result.data], { type: 'text/csv' });
          const contentDisposition = result.meta.response.headers.get('content-disposition');
          const filename = getFilenameFromContentDisposition(contentDisposition || '');

          triggerFileDownload(window.URL.createObjectURL(blob), filename);
        } else {
          const result: any = await baseQuery({
            url,
            method: 'GET',
            params,
          });

          if (result.error) {
            return { error: result.error };
          }

          const { s3PreSignedUrl } = result.data;
          triggerFileDownload(s3PreSignedUrl);
        }

        return { data: null };
      },
    }),
    updateSystemDevices: builder.mutation<void, UpdateSystemRequest>({
      query: ({ siteId, systemId, body }) => ({
        url: `/pwrfleet-ui/v1/sites/${siteId}/systems/${systemId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (_result, _meta, args) => [
        { type: 'SiteDevices', id: args.siteId },
        { type: 'SiteStatus', id: args.siteId },
      ],
    }), // PowerCore swap
    deleteSystem: builder.mutation<void, { siteId: string; systemId: string }>({
      query: ({ siteId, systemId }) => ({
        method: 'DELETE',
        url: `/pwrfleet-ui/v1/sites/${siteId}/systems/${systemId}`,
      }),
    }),
    addSystems: builder.mutation<ResponseSystem[], AddSystemsRequest>({
      query: ({ companyId, siteId, systems }) => ({
        url: `pwrfleet-ui/v1/companies/${companyId}/sites/${siteId}/systems`,
        method: 'POST',
        body: systems,
      }),
    }),
    getSystemDetails: builder.query<SystemDetailsSuccessResponse, { systemId: string }>({
      query: ({ systemId }) => `/system/v1/systems/${systemId}`,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetSystemModeEventsQuery: useGetSystemModeEvents,
  useGetSystemModeQuery: useGetSystemMode,
  useUpdateSystemModeMutation: useUpdateSystemMode,
  useGetBatteriesSettingsQuery: useGetBatteriesSettings,
  useUpdateBatterySettingMutation: useUpdateBatterySetting,
  useGetInverterSettingsQuery: useGetInverterSettings,
  useUpdateInverterSettingMutation: useUpdateInverterSetting,
  useGetSystemValidationMutation: useGetSystemValidation,
  useDownloadChartCsvMutation: useDownloadChartCsv,
  useUpdateSystemDevicesMutation: useUpdateSystemDevices,
  useDeleteSystemMutation: useDeleteSystem,
  useAddSystemsMutation: useAddSystems,
  useLazyGetSystemDetailsQuery: useGetSystemDetails,
} = systemApi;
