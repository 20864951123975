import { createSelector } from '@reduxjs/toolkit';

import type { AddedSystem } from '../../types/AddSystemsToSite';
import { AddedSystemStatusName } from '../../types/AddSystemsToSite';
import {
  getAddSitePayloadAddress,
  getSuggestionHomeownerAddress,
  getSuggestionSiteAddress,
  getSystemBySerialNumber,
} from '../../utils';
import { isSystemInverter, isSystemLoadManager } from '../../utils/systemType';
import { userIdSelector } from '../auth/selectors';
import { companyIdSelector, fleetIdSelector } from '../company/selectors';
import type { RootState } from '../types';
import { AddSiteStep } from './stepsData';

/**
 * General selectors
 */
export const currentStepSelector = (state: RootState) => state.addSite.currentStep;

/**
 * ADD_DETAILS step selectors
 */
export const addDetailsDataSelector = (state: RootState) =>
  state.addSite[AddSiteStep.ADD_DETAILS].formData;

/**
 * SITE_ADDRESS_VERIFICATION and HOMEOWNER_ADDRESS_VERIFICATION steps selectors
 */
type AddressSteps =
  | AddSiteStep.SITE_ADDRESS_VERIFICATION
  | AddSiteStep.HOMEOWNER_ADDRESS_VERIFICATION;
export const selectedAddressSelector = (state: RootState, { step }: { step: AddressSteps }) =>
  state.addSite[step].selectedAddress;

/**
 * ADD_SYSTEM_DETAILS step selectors
 */
export const wizardSystemsSelector = (state: RootState) =>
  state.addSite[AddSiteStep.ADD_SYSTEM_DETAILS].systems;

/**
 * ADD_DETAILS step selectors
 */
export const validationSiteAddressSuccessSelector = (state: RootState) =>
  state.addSite[AddSiteStep.ADD_DETAILS].siteAddressValidationData;
export const validationHomeownerAddressSuccessSelector = (state: RootState) =>
  state.addSite[AddSiteStep.ADD_DETAILS].homeownerAddressValidationData;
export const validationHomeownerAddressLoadingSelector = (state: RootState) =>
  state.addSite[AddSiteStep.ADD_DETAILS].homeownerAddressValidating;
export const validationSiteAddressLoadingSelector = (state: RootState) =>
  state.addSite[AddSiteStep.ADD_DETAILS].siteAddressValidating;
export const addSiteLoadingSelector = (state: RootState) =>
  state.addSite[AddSiteStep.REVIEW].addSiteLoading;
export const validationSiteAddressFailureSelector = (state: RootState) =>
  state.addSite[AddSiteStep.ADD_DETAILS].siteAddressValidationError;
export const validationHomeownerAddressFailureSelector = (state: RootState) =>
  state.addSite[AddSiteStep.ADD_DETAILS].homeownerAddressValidationError;

/**
 * REVIEW step selectors
 */
export const addSiteFailureSelector = (state: RootState) =>
  state.addSite[AddSiteStep.REVIEW].addSiteFailure;
export const addSiteSuccessSelector = (state: RootState) =>
  state.addSite[AddSiteStep.REVIEW].addSiteSuccess;

/**
 * Additional selectors
 */
export const siteAddressSuggestionListSelector = createSelector(
  validationSiteAddressSuccessSelector,
  (data) => data?.suggestionList,
);

export const homeownerAddressSuggestionListSelector = createSelector(
  validationHomeownerAddressSuccessSelector,
  (data) => data?.suggestionList,
);

export const siteAddressChangeRequiredSelector = createSelector(
  validationSiteAddressSuccessSelector,
  (data) => data?.isChangeRequired,
);

export const homeownerAddressChangeRequiredSelector = createSelector(
  validationHomeownerAddressSuccessSelector,
  (data) => data?.isChangeRequired,
);

export const addressValidationSuccessSelector = createSelector(
  validationSiteAddressLoadingSelector,
  validationHomeownerAddressLoadingSelector,
  addDetailsDataSelector,
  validationSiteAddressSuccessSelector,
  validationHomeownerAddressSuccessSelector,
  (
    siteAddressValidating,
    homeownerAddressValidating,
    formData,
    siteAddressValidationData,
    homeownerAddressValidationData,
  ) => {
    const { useSiteAddress } = formData;
    if (useSiteAddress) {
      return !siteAddressValidating && siteAddressValidationData;
    }
    return (
      !siteAddressValidating &&
      !homeownerAddressValidating &&
      siteAddressValidationData &&
      homeownerAddressValidationData
    );
  },
);

export const originalSiteAddressSelector = createSelector(
  currentStepSelector,
  addDetailsDataSelector,
  (step, formData) => {
    if (step === AddSiteStep.HOMEOWNER_ADDRESS_VERIFICATION) {
      return getSuggestionHomeownerAddress(formData);
    }
    return getSuggestionSiteAddress(formData);
  },
);

export const addressSuggestionListSelector = createSelector(
  currentStepSelector,
  siteAddressSuggestionListSelector,
  homeownerAddressSuggestionListSelector,
  (step, siteAddressSuggestionList, homeownerAddressSuggestionList) => {
    if (step === AddSiteStep.HOMEOWNER_ADDRESS_VERIFICATION) {
      return homeownerAddressSuggestionList;
    }
    return siteAddressSuggestionList;
  },
);

export const previouslySelectedAddressIndexSelector = createSelector(
  currentStepSelector,
  (state: RootState) =>
    selectedAddressSelector(state, { step: AddSiteStep.SITE_ADDRESS_VERIFICATION }),
  (state: RootState) =>
    selectedAddressSelector(state, { step: AddSiteStep.HOMEOWNER_ADDRESS_VERIFICATION }),
  addressSuggestionListSelector,
  (step, selectedSiteAddress, selectedHomeOwnerAddress, suggestionList) => {
    const address =
      step === AddSiteStep.HOMEOWNER_ADDRESS_VERIFICATION
        ? selectedHomeOwnerAddress
        : selectedSiteAddress;
    const index = suggestionList?.findIndex((a) => a === address);
    if (index && index !== -1) {
      return index;
    }
    return 0;
  },
);

export const addressValidationErrorSelector = createSelector(
  validationSiteAddressFailureSelector,
  validationHomeownerAddressFailureSelector,
  (siteAddressError, homeownerAddressError) => !!(siteAddressError || homeownerAddressError),
);

const contactSelector = createSelector(
  addDetailsDataSelector,
  ({
    homeownerBusinessName,
    homeownerFirstName,
    homeownerLastName,
    homeownerPhone,
    homeownerEmail,
  }) => ({
    businessName: homeownerBusinessName,
    firstName: homeownerFirstName,
    lastName: homeownerLastName,
    phone: homeownerPhone,
    email: homeownerEmail,
  }),
);

const homeownerAddressSelector = createSelector(
  addDetailsDataSelector,
  (state: RootState) =>
    selectedAddressSelector(state, { step: AddSiteStep.SITE_ADDRESS_VERIFICATION })!,
  (state: RootState) =>
    selectedAddressSelector(state, {
      step: AddSiteStep.HOMEOWNER_ADDRESS_VERIFICATION,
    })!,
  ({ useSiteAddress }, selectedSiteAddress, selectedHomeownerAddress) =>
    useSiteAddress
      ? getAddSitePayloadAddress(selectedSiteAddress)
      : getAddSitePayloadAddress(selectedHomeownerAddress),
);

const siteAddressSelector = createSelector(
  (state: RootState) =>
    selectedAddressSelector(state, { step: AddSiteStep.SITE_ADDRESS_VERIFICATION })!,
  (selectedAddress) => getAddSitePayloadAddress(selectedAddress),
);

export const utilitiesSelector = (state: RootState) => state.addSite.utilities;
export const utilityIdSelector = (state: RootState) => state.addSite.utilities;
export const utilityNameSelector = createSelector(
  utilitiesSelector,
  addDetailsDataSelector,
  (utilities, { utilityId }) => utilities?.find((u) => u.utilityId === utilityId)?.name,
);

export const utilityOptionsSelector = createSelector(
  utilitiesSelector,
  (utilities) => utilities?.map((u) => ({ value: u.utilityId, label: u.name })) ?? [],
);
export const utilitiesLoadingSelector = (state: RootState) => state.addSite.utilitiesLoading;
export const utilitiesErrorSelector = (state: RootState) => state.addSite.utilitiesError;

export const addSitePayloadSelector = createSelector(
  userIdSelector,
  companyIdSelector,
  addDetailsDataSelector,
  contactSelector,
  homeownerAddressSelector,
  siteAddressSelector,
  wizardSystemsSelector,
  utilityNameSelector,
  fleetIdSelector,
  (
    userId,
    companyId,
    { siteName, utilityId },
    contact,
    homeOwnerAddress,
    siteAddress,
    systems,
    utilityName,
    fleetId = '',
  ) => ({
    siteName,
    fleetId,
    userId,
    companyId,
    contact,
    homeOwnerAddress,
    siteAddress,
    systems,
    utilityName,
    utilityId: utilityId ? (utilityId as number) : undefined,
  }),
);

export const homeownerEmailSelector = createSelector(
  addDetailsDataSelector,
  ({ homeownerEmail }) => homeownerEmail,
);

export const addSiteNeverConnectedSystemsCountSelector = createSelector(
  addSiteSuccessSelector,
  (data) => {
    return (
      data?.systems?.filter?.(
        (s) => s.systemConnectionStatus === AddedSystemStatusName.NEVER_CONNECTED,
      )?.length || 0
    );
  },
);

export const pairingRecordSelector = (state: RootState) =>
  state.addSite[AddSiteStep.PAIRING].pairingRecord;

export const loadManagersSelector = createSelector(wizardSystemsSelector, (systems) =>
  systems.filter(isSystemLoadManager),
);
export const invertersSelector = createSelector(wizardSystemsSelector, (systems) =>
  systems.filter(isSystemInverter),
);
export const onePairRecordSelector = createSelector(
  loadManagersSelector,
  invertersSelector,
  (loadManagers, inverters) => {
    if (loadManagers.length === 1 && inverters.length === 1) {
      const { serialNumber: loadManagerSerialNumber } = loadManagers[0];
      const { serialNumber: inverterSerialNumber } = inverters[0];
      return {
        [loadManagerSerialNumber]: inverterSerialNumber,
      };
    }
  },
);

type SystemPair = { loadManager: AddedSystem; inverter: AddedSystem };

export const registeredSystemsForPairingSelector = createSelector(
  pairingRecordSelector,
  addSiteSuccessSelector,
  (pairingRecord, addSiteSuccess) => {
    const systems = addSiteSuccess?.systems;
    if (systems) {
      return Object.entries(pairingRecord)
        .map(([loadManagerSerialNumber, inverterSerialNumber]) => {
          const loadManager = getSystemBySerialNumber(systems, loadManagerSerialNumber);
          const inverter = getSystemBySerialNumber(systems, inverterSerialNumber);
          if (loadManager && inverter) {
            return {
              loadManager,
              inverter,
            };
          }
          return null;
        })
        .filter((item): item is SystemPair => !!item);
    }
  },
);

export const isAddressOverriddenSelector = (state: RootState) =>
  state.addSite[AddSiteStep.ADD_DETAILS].formData.addressOverride;
export const isPairingSelector = (state: RootState) => state.addSite[AddSiteStep.PAIRING].isPairing;
export const pairingErrorSelector = (state: RootState) =>
  state.addSite[AddSiteStep.PAIRING].pairingError;
