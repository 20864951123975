import { createApi } from '@reduxjs/toolkit/query/react';

import { triggerFileDownload } from '../../utils/files';
import { baseQueryWithReauth } from './baseQuery';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'CompanySupport',
    'CompanySupportCase',
    'SiteStatus',
    'SiteDevices',
    'SiteAccess',
    'SiteAttachments',
    'SystemMode',
    'BatterySettings',
    'InverterSettings',
    'LoadManagerConfigs',
  ],
  endpoints: (builder) => ({
    downloadUrl: builder.mutation<any, { url: string; fileName: string }>({
      queryFn: async ({ url, fileName }, _queryApi, _extraOptions, baseQuery) => {
        const result: any = await baseQuery({
          url,
          method: 'GET',
          responseHandler: (response) => response.blob(),
        });

        const blob = new Blob([result.data]);
        triggerFileDownload(window.URL.createObjectURL(blob), fileName);

        return {
          data: null,
        };
      },
    }),
  }),
});

export const { useDownloadUrlMutation: useDownloadUrl } = api;
