import { Box, Checkbox, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { FieldProps } from 'formik';
import { Field, useFormikContext } from 'formik';

import type { AddDetailsStepData } from '../../store/addSite';
import { Input } from '../input';

const FieldsContainer = styled('div')`
  & > div {
    display: flex;
    margin-bottom: 20px;
    :last-of-type {
      margin-bottom: 10px;
    }
  }

  & .MuiFormHelperText-filled {
    font-weight: 700;
  }
`;

export const AddressCoordinatesInput = () => {
  const { values } = useFormikContext();
  const { addressOverride } = values as AddDetailsStepData;

  return (
    <Box bgcolor="background.default" p={2} borderRadius="10px">
      <Field name="addressOverride">
        {({ field }: FieldProps) => (
          <Box sx={{ display: 'flex' }}>
            <Typography fontWeight="bold">
              Provide site coordinates to override address verification.
            </Typography>
            <Checkbox size="small" color="default" checked={field.value} {...field} />
          </Box>
        )}
      </Field>

      {addressOverride ? (
        <Box mt={1}>
          <FieldsContainer>
            <Box mb={1}>
              <Typography variant="caption">
                Coordinates are required to plot this site on a map.
              </Typography>
            </Box>
            <Field name="latitude">
              {({ field, form, meta }: FieldProps) => (
                <Input
                  {...field}
                  type="number"
                  label="LATITUDE"
                  error={meta.touched && meta.error ? Boolean(form.errors.latitude) : false}
                  helperText={
                    meta.touched && meta.error && form.errors.latitude
                      ? String(form.errors.latitude)
                      : ''
                  }
                />
              )}
            </Field>
            <Field name="longitude">
              {({ field, form, meta }: FieldProps) => (
                <Input
                  {...field}
                  type="number"
                  label="LONGITUDE"
                  error={meta.touched && meta.error ? Boolean(form.errors.longitude) : false}
                  helperText={
                    meta.touched && meta.error && form.errors.longitude
                      ? String(form.errors.longitude)
                      : ''
                  }
                />
              )}
            </Field>
          </FieldsContainer>
        </Box>
      ) : null}
    </Box>
  );
};
