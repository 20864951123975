import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { FormikProps } from 'formik';
import { Form, Formik } from 'formik';
import type { FocusEvent, RefObject } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { boolean, number, object, ref, string } from 'yup';

import COUNTRIES from '../../constants/countries';
import { GENERAC_PRIVACY_POLICY, GENERAC_TERMS_PDF } from '../../constants/general';
import type { AddDetailsStepData as FormValue } from '../../store/addSite';
import { loadUtilities } from '../../store/addSite/actions';
import {
  utilitiesErrorSelector,
  utilitiesLoadingSelector,
  utilityOptionsSelector,
} from '../../store/addSite/selectors';
import { down } from '../../styles';
import { UIFeature } from '../../types/UIFeature';
import { isLatitude, isLongitude, REGEX_PHONE_NUMBER, REGEX_ZIP, REGEX_ZIP_US } from '../../utils';
import {
  cityValidationSchema,
  getMaxMessage,
  getMinMessage,
  getRequiredMessage,
  siteNameValidationSchema,
  validateState,
} from '../../utils/forms';
import { AddressCoordinatesInput } from '../addressCoordinatesInput';
import { Button } from '../button';
import { DependentFormField } from '../dependentFormField';
import { FormInput } from '../formInput';
import { FormSelect } from '../formSelect';
import { GatedFeature } from '../gatedFeature';
import { PWRcheckbox } from '../pwrCheckbox';

const StyledLink = styled('a')`
  color: inherit;
`;

type ContainerProps = {
  bottomPadding?: boolean;
};

const FieldsContainer = styled('div')<ContainerProps>`
  width: 60%;
  max-width: 400px;
  display: flex;
  flex-direction: column;

  padding-bottom: ${({ bottomPadding }) => (bottomPadding ? '20px' : 'unset')};

  & > .MuiFormControl-root {
    margin-bottom: 28px;

    :last-of-type {
      margin-bottom: 10px;
    }
  }

  ${down('xs')} {
    width: 80%;
  }
`;

const schema = object().shape({
  siteName: siteNameValidationSchema,
  siteAddress1: string()
    .required(getRequiredMessage('site address'))
    .min(3, ({ min }) => getMinMessage('Site address line 1', min))
    .max(50, ({ max }) => getMaxMessage('Site address line 1', max)),
  siteAddress2: string().max(50, ({ max }) => getMaxMessage('Site address line 2', max)),
  siteCity: cityValidationSchema,
  siteCountry: string()
    .required(getRequiredMessage('site country'))
    .min(2, ({ min }) => getMinMessage('Site country', min)),
  siteZip: string()
    .required(getRequiredMessage('site zip or postal code'))
    .min(3, ({ min }) => getMinMessage('Site zip or postal code', min))
    .max(10, ({ max }) => getMaxMessage('Site zip or postal code', max)) // To ensure compatibility with backend limits
    .when('siteCountry', {
      is: 'US',
      then: string().matches(REGEX_ZIP_US, 'Please enter a valid US ZIP or postal code'), // Specifically ZIP (#####) or ZIP+4 (#####-####)
      otherwise: string().matches(REGEX_ZIP, 'Please enter a valid postal code'), // For better user-facing validation
    }),
  utilityId: number(),
  homeownerBusinessName: string(),
  homeownerFirstName: string().required(getRequiredMessage('first name')),
  homeownerLastName: string().required(getRequiredMessage('last name')),
  homeownerPhone: string()
    .required(getRequiredMessage('area code and phone number'))
    .matches(REGEX_PHONE_NUMBER, 'Please use a valid 10+ digits phone number')
    .min(10, ({ min }) => getMinMessage('Area code and phone number', min)),
  homeownerEmail: string()
    .email(getRequiredMessage('valid email'))
    .required('Please enter an email for the Homeowner'),
  homeownerEmailVerify: string()
    .required("Please verify the Homeowner's email")
    .oneOf([ref('homeownerEmail'), null], 'Emails must match'),
  homeownerAddress1: string().when('useSiteAddress', {
    is: true,
    then: string(),
    otherwise: string()
      .required(getRequiredMessage('homeowner address'))
      .min(3, ({ min }) => getMinMessage('Homeowner address line 1', min))
      .max(50, ({ max }) => getMaxMessage('Homeowner address line 1', max)),
  }),
  homeownerAddress2: string().max(50, ({ max }) => getMaxMessage('Homeowner address line 2', max)),
  homeownerCity: string().when('useSiteAddress', {
    is: true,
    then: string(),
    otherwise: string()
      .required(getRequiredMessage('homeowner city'))
      .min(2, ({ min }) => getMinMessage('Homeowner city', min)),
  }),
  homeownerCountry: string().when('useSiteAddress', {
    is: true,
    then: string(),
    otherwise: string()
      .required(getRequiredMessage('homeowner country'))
      .min(2, ({ min }) => getMinMessage('Homeowner country', min)),
  }),
  homeownerZip: string().when('useSiteAddress', {
    is: true,
    then: string(),
    otherwise: string()
      .required(getRequiredMessage('homeowner zip or postal code'))
      .min(3, ({ min }) => getMinMessage('Homeowner zip or postal code', min))
      .max(10, ({ max }) => getMaxMessage('Homeowner zip or postal code', max)) // To ensure compatibility with backend limits
      .matches(REGEX_ZIP, 'Please enter a valid homeowner zip or postal code'), // For better user-facing validation
  }),

  useSiteAddress: boolean().required(),
  hasConsent: boolean().oneOf([true]),
  termsAgree: boolean().oneOf([true]),
  addressOverride: boolean(),
  latitude: number().when('addressOverride', {
    is: true,
    then: number()
      .required(getRequiredMessage('latitude'))
      .test('latitude', 'Must be a number between -90 and 90', isLatitude),
    otherwise: number(),
  }),
  longitude: number().when('addressOverride', {
    is: true,
    then: number()
      .required(getRequiredMessage('longitude'))
      .test('longitude', 'Must a number between -180 and 180', isLongitude),
    otherwise: number(),
  }),
});

const useUtility = () => {
  const utilityOptions = useSelector(utilityOptionsSelector);
  const utilitiesLoading = useSelector(utilitiesLoadingSelector);
  const utilitiesError = useSelector(utilitiesErrorSelector);
  return {
    utilityOptions,
    utilitiesLoading,
    utilitiesError,
  };
};

type SetSubmitting = (submitting: boolean) => void;

interface Props {
  initialValues: FormValue;
  onNext?: (values: FormValue, setSubmitting: SetSubmitting) => void;

  onBack?(values: FormValue): void;

  onValidationUpdate?(isValid: boolean): void;

  isLoading?: boolean;
  formRef?: RefObject<FormikProps<FormValue>> | null;
  useExternalButtons?: boolean;
}

export const SiteDetailsForm = ({
  initialValues,
  onNext,
  onBack,
  isLoading,
  useExternalButtons = false,
  formRef = null,
  onValidationUpdate,
}: Props) => {
  const dispatch = useDispatch();
  const { utilityOptions, utilitiesError, utilitiesLoading } = useUtility();

  useEffect(() => {
    if (formRef && formRef.current) {
      formRef.current.validateForm();
    }
  }, [formRef]);

  const onSubmit = (values: FormValue, { setSubmitting }: { setSubmitting: SetSubmitting }) => {
    onNext?.(values, setSubmitting);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={schema}
      validateOnMount
      innerRef={formRef}
    >
      {({ values, errors, isValid, setFieldValue, setFieldTouched, getFieldMeta }) => {
        const siteCountry = COUNTRIES.find((country) => country.value === values.siteCountry);
        const siteStates = siteCountry ? siteCountry.states : [];

        const homeownerCountry = COUNTRIES.find(
          (country) => country.value === values.homeownerCountry,
        );
        const homeownerStates = homeownerCountry ? homeownerCountry.states : [];
        onValidationUpdate?.(isValid);

        const trimOnBlur = (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          setFieldValue(e.target.name, e.target.value.trim());
        };

        return (
          <Form>
            <Box display="flex" flexDirection="column" alignItems="center">
              <FieldsContainer bottomPadding>
                <Box my={2}>
                  <Typography variant="subtitle1">Site information</Typography>
                </Box>
                <FormInput id="site-name" name="siteName" label="Site Name" onBlur={trimOnBlur} />
                <FormInput
                  id="site-address-1"
                  name="siteAddress1"
                  label="Site address line 1"
                  onBlur={trimOnBlur}
                />
                <FormInput
                  id="site-address-2"
                  name="siteAddress2"
                  label="Site address Line 2"
                  aria-describedby="outlined-weight-helper-text"
                  required={false}
                />
                <FormInput id="site-city" name="siteCity" label="City" />
                <FormSelect
                  id="site-country"
                  name="siteCountry"
                  label="Country"
                  options={COUNTRIES}
                  onChange={() => setFieldValue('siteState', '', true)}
                />
                <FormSelect
                  id="site-state"
                  name="siteState"
                  label="State or province"
                  options={siteStates}
                  validate={(value: string) =>
                    validateState(value, siteStates, 'site state or province')
                  }
                />
                <FormInput
                  id="site-zip"
                  name="siteZip"
                  label="ZIP or postal code"
                  onBlur={trimOnBlur}
                />
                {values.siteCountry === 'US' ? (
                  <DependentFormField<FormValue>
                    dependencyFields={['siteZip', 'siteCountry']}
                    defaultValue={utilitiesError ? 0 : ''}
                    effect={() => {
                      dispatch(loadUtilities.request({ zipcode: values.siteZip?.trim() }));
                    }}
                  >
                    <FormSelect
                      id="utility-id"
                      name="utilityId"
                      label={utilitiesLoading ? 'Loading Utilities…' : 'Utility'}
                      helperText={utilitiesError ? 'No Utilities Found' : ''}
                      options={utilityOptions}
                      required={false}
                      disabled={utilitiesLoading || !!utilitiesError}
                    />
                  </DependentFormField>
                ) : null}
                <GatedFeature
                  uiFeature={UIFeature.SUPPORT_VIEW}
                  onAllowed={() => <AddressCoordinatesInput />}
                />
              </FieldsContainer>
              <FieldsContainer bottomPadding>
                <Box my={2}>
                  <Typography variant="subtitle1">Homeowner information</Typography>
                </Box>
                <FormInput
                  id="homeowner-business-name"
                  name="homeownerBusinessName"
                  label="Business name"
                  required={false}
                  onBlur={trimOnBlur}
                />
                <FormInput
                  id="homeowner-first-name"
                  name="homeownerFirstName"
                  label="First Name"
                  onBlur={trimOnBlur}
                />
                <FormInput
                  id="homeowner-last-name"
                  name="homeownerLastName"
                  label="Last Name"
                  onBlur={trimOnBlur}
                />
                <FormInput
                  id="homeowner-phone"
                  name="homeownerPhone"
                  label="Area code and phone number"
                  autoComplete="phone"
                  onBlur={trimOnBlur}
                />
                <FormInput
                  id="homeowner-email"
                  name="homeownerEmail"
                  label="Email"
                  autoComplete="email"
                  onChange={(e) => {
                    setFieldValue(e.target.name, e.target.value.trim());
                  }}
                  onBlur={(e) => {
                    setFieldValue(e.target.name, e.target.value.trim().toLocaleLowerCase());
                  }}
                  helperText="Make sure the Homeowner can access this email."
                />
                <FormInput
                  id="homeowner-email-verify"
                  name="homeownerEmailVerify"
                  label="Verify Email"
                  autoComplete="email"
                  onChange={(e) => {
                    setFieldValue(e.target.name, e.target.value.trim());
                  }}
                  onBlur={(e) => {
                    setFieldValue(e.target.name, e.target.value.trim().toLocaleLowerCase());
                  }}
                  helperText="Verify the Homeowner's email address."
                />
              </FieldsContainer>
              <FieldsContainer bottomPadding>
                <Box my={2}>
                  <Typography variant="subtitle1">Homeowner address</Typography>
                </Box>
                <Box py={1}>
                  <PWRcheckbox
                    color="default"
                    size="small"
                    label="Use site address as homeowner address"
                    onChange={() => setFieldValue('useSiteAddress', !values.useSiteAddress)}
                    checked={values.useSiteAddress}
                  />
                </Box>
                {!values.useSiteAddress ? (
                  <>
                    <FormInput
                      id="homeowner-address-1"
                      name="homeownerAddress1"
                      label="Homeowner address line 1"
                      onBlur={trimOnBlur}
                    />
                    <FormInput
                      id="homeowner-address-2"
                      name="homeownerAddress2"
                      label="Homeowner address line 2"
                      required={false}
                      onBlur={trimOnBlur}
                    />
                    <FormInput id="homeowner-city" name="homeownerCity" label="City" />
                    <FormSelect
                      id="homeowner-country"
                      name="homeownerCountry"
                      label="Country"
                      options={COUNTRIES}
                      onChange={() => setFieldValue('homeownerState', '', true)}
                    />
                    <FormSelect
                      id="homeowner-state"
                      name="homeownerState"
                      label="State or province"
                      options={homeownerStates}
                      validate={(value: string) =>
                        validateState(value, homeownerStates, 'homeowner state or province')
                      }
                    />
                    <FormInput
                      id="homeowner-zip"
                      name="homeownerZip"
                      label="ZIP or postal code"
                      onBlur={trimOnBlur}
                    />
                  </>
                ) : null}
              </FieldsContainer>
              <FieldsContainer>
                <Box mb={1}>
                  <Typography variant="body1">By registering, you confirm that:</Typography>
                </Box>
                <Box py={1}>
                  <PWRcheckbox
                    checked={values.hasConsent}
                    label="You have the homeowner's consent to monitor their system."
                    onChange={() => setFieldValue('hasConsent', !values.hasConsent)}
                    onBlur={() => setFieldTouched('hasConsent')}
                    hasError={!!errors.hasConsent && getFieldMeta('hasConsent').touched}
                  />
                </Box>
                <Box py={1}>
                  <PWRcheckbox
                    checked={values.termsAgree}
                    label={
                      <span>
                        You agree to Generac's{' '}
                        <StyledLink
                          target="_blank"
                          rel="noopener noreferrer"
                          href={GENERAC_TERMS_PDF}
                          download
                        >
                          Terms
                        </StyledLink>{' '}
                        and{' '}
                        <StyledLink
                          target="_blank"
                          rel="noopener noreferrer"
                          href={GENERAC_PRIVACY_POLICY}
                        >
                          Privacy Policy
                        </StyledLink>
                        .
                      </span>
                    }
                    onChange={() => setFieldValue('termsAgree', !values.termsAgree)}
                    onBlur={() => setFieldTouched('termsAgree')}
                    hasError={!!errors.termsAgree && getFieldMeta('termsAgree').touched}
                  />
                </Box>
              </FieldsContainer>
              {!useExternalButtons && (
                <Box mt={4} display="flex" flexDirection="column">
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    width={280}
                    alignSelf="center"
                  >
                    <Button
                      data-testid="site-details-back-button"
                      color="secondary"
                      size="small"
                      onClick={() => onBack && onBack(values)}
                    >
                      Back
                    </Button>
                    <Button
                      disabled={!isValid || isLoading}
                      data-testid="site-details-next-button"
                      type="submit"
                      size="small"
                      isLoading={isLoading}
                    >
                      Next
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};
