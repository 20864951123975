import type { FetchArgs } from '@reduxjs/toolkit/dist/query';
import { isEmpty } from 'lodash';

import type { User } from '../../types/api/getAllUsers';
import type { SiteChartDataRequest } from '../../types/api/getSiteChartData';
import type { CompanySupportCasesArgs } from '../../types/CompanySupport';
import { ChartType } from '../../types/SiteMetrics';
import { convertCamelCaseToScreamingSnakeCase } from '../../utils';

export type SiteChartDataUrlArgs = Omit<
  SiteChartDataRequest,
  'granularity' | 'dateFrom' | 'dateTo'
>;

export const getCompanySupportCasesUrl = ({
  companyId,
  filterModel,
  sortModel,
  paginationModel,
}: CompanySupportCasesArgs): FetchArgs => {
  const params: { [key: string]: any } = {
    limit: paginationModel.pageSize,
    offset: paginationModel.page * paginationModel.pageSize,
  };

  filterModel.items.forEach((filter) => {
    if (filter.value?.trim().length < 1) {
      return;
    }

    if (filter.operator === 'contains' || filter.operator === 'is') {
      params[filter.field] = filter.value;
    } else if (filter.operator === 'onOrAfter') {
      params[`${filter.field}Start`] = filter.value;
    } else if (filter.operator === 'onOrBefore') {
      params[`${filter.field}End`] = filter.value;
    }
  });

  if (!isEmpty(sortModel[0])) {
    const fieldScreamingSnakeCase = convertCamelCaseToScreamingSnakeCase(sortModel[0].field);
    params.sortBy = fieldScreamingSnakeCase;

    if (sortModel[0].sort === 'asc') {
      params.descending = false;
    }
  }

  return { url: `/pwrfleet-ui/v1/companies/${companyId}/cases`, params };
};

export const getSiteChartDataUrl = (args: SiteChartDataUrlArgs) => {
  let url = `/hist/v2/sites/${args.siteId}/${args.metric}`;

  // enpoint is not consistent, for production and consumption it expects these paths, and for (all) devices it expects no path
  if (args.type === ChartType.PRODUCTION) {
    url += '/production/solar';
  } else if (args.type === ChartType.CONSUMPTION) {
    url += '/consumption';
  }

  return url;
};

export const sortUsers = (user: User, column: string) => {
  if (column === 'name') {
    return user.lastName;
  }

  return user[column as keyof User];
};
