import type { ReactNode } from 'react';

import { GENERAC_SUPPORT_PHONE_NUMBER } from '../../../constants/general';

const COMMON_ERROR_TEXT = `PWRfleet encountered an unexpected error during the registration process. Please review the information you entered and try again in a few moments. If this problem persists or you need assistance solving this problem, please contact Generac Support ${GENERAC_SUPPORT_PHONE_NUMBER}.`;
const SYSTEMS_ERROR_TEXT =
  'PWRfleet encountered issues during registration. Please review the error list below.';

const KNOWN_SYSTEM_ERRORS: {
  [key: number]: ReactNode;
} = {
  334: (
    <div>
      This equipment is already registered in PWRfleet. Please:
      <ol>
        <li>
          Use the search functionality in PWRfleet to check that this equipment is not already in
          your company’s site list
        </li>
        <li>
          Ensure that the equipment has not already been registered by a colleague under a different
          PWRfleet company account
        </li>
      </ol>
      If you still experience this problem, contact Generac Support with the equipment Serial Number
      and Activation Code to link this equipment to your company account.
    </div>
  ),
  335: (
    <span>
      There was a problem activating this equipment’s warranty. Please contact Generac Support at{' '}
      {GENERAC_SUPPORT_PHONE_NUMBER} with this Serial Number to rectify this issue.
    </span>
  ),
  336: (
    <span>
      There was an issue identifying the type of equipment being registered. Please contact Generac
      Support at {GENERAC_SUPPORT_PHONE_NUMBER} to rectify this issue.
    </span>
  ),
  337: (
    <span>
      The homeowner email address provided is already associated with a PWRfleet account, please
      retry using a different email address.
    </span>
  ),
  400: (
    <span>
      There was an issue retrieving equipment records for this Serial Number. Please contact Generac
      Support at {GENERAC_SUPPORT_PHONE_NUMBER} to rectify this issue.
    </span>
  ),
  404: (
    <span>
      The equipment associated with this Serial Number does not appear connected to the internet.
      Please verify connectivity is established, as described in the Installation Manual or the{' '}
      <a
        href="https://www.youtube.com/watch?v=2HRPm8_Xsd4&list=PLXvUTGq1zYzu_c96pwgwpP2_Qc0K_fNG9&index=4"
        target="_blank"
        rel="noopener noreferrer"
      >
        tutorial video
      </a>
      .
    </span>
  ),
  409: (
    <span>
      Unable to register the inverter or beacon because it is already registered to another site.
      Please contact Generac Support at {GENERAC_SUPPORT_PHONE_NUMBER} with this Serial Number to
      rectify this issue.
    </span>
  ),
  500: (
    <span>
      There was an issue reaching PWRfleet servers, please try again in a few minutes and contact
      Generac Support if you keep experiencing errors.
    </span>
  ),
};

const KNOWN_SITE_ERRORS: {
  [key: number]: ReactNode;
} = {
  337: (
    <span>
      The homeowner email address provided is already associated with a PWRfleet account, please
      retry using a different email address.
    </span>
  ),
};

export { COMMON_ERROR_TEXT, KNOWN_SITE_ERRORS, KNOWN_SYSTEM_ERRORS, SYSTEMS_ERROR_TEXT };
