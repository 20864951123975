import type { AxiosError } from 'axios';

export enum SystemType {
  ESS = 'ESS', // first generation PWRcell (R1)
  ES2 = 'ES2', // next generation PWRcell (R2) also type of all future systems that communicate via Gateway
  LOAD_MANAGER = 'LOAD_CONTROLLER',
  MICROINVERTER = 'MICROINVERTER', // project on hold, microinverter systems will probably be type ES2 in future, leaving as is for now
  UNKNOWN = 'UNKNOWN',
}

export type SiteSystem = {
  siteId: string;
  systemId: string;
  systemType: SystemType;
  serialNumber: string;
  installerId: string;
};
export type GetSiteSystemsSuccessResponse = SiteSystem[];
export type GetSiteSystemsErrorResponse = AxiosError;
export type GetSiteSystemsResponse = GetSiteSystemsSuccessResponse | GetSiteSystemsErrorResponse;
