import { Box, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import React from 'react';

import handleErrorMessage from './helpers/handleErrorMessage';
import {
  COMMON_ERROR_TEXT,
  KNOWN_SITE_ERRORS,
  KNOWN_SYSTEM_ERRORS,
  SYSTEMS_ERROR_TEXT,
} from './helpers/knownErrors';
import type { SystemOrSiteError } from './types/systemOrSiteError';

interface Props {
  systemErrorList?: SystemOrSiteError[];
}

export const AddSystemErrors = ({ systemErrorList }: Props) => {
  if (systemErrorList?.length) {
    const { siteErrors, systemErrors } =
      handleErrorMessage.splitSystemAndSiteErrors(systemErrorList);

    return (
      <>
        <Box color="background.paper">
          <Typography variant="body2" color="inherit">
            {SYSTEMS_ERROR_TEXT}
          </Typography>
        </Box>
        <Box pt={1}>
          <Box mt={1}>
            {!isEmpty(systemErrors) && <Typography variant="h3">System Errors</Typography>}

            <ul>
              {systemErrors
                .map((err, i) => {
                  const { errorCode, serialNumber } = err;
                  const serialNumberText = serialNumber ? ` (${serialNumber})` : '';

                  return {
                    text: `System ${i + 1}${serialNumberText} ${handleErrorMessage.getErrorMessage(
                      errorCode,
                    )}:`,
                    description: KNOWN_SYSTEM_ERRORS[errorCode] || null,
                  };
                })
                .map(({ text, description }, i) => (
                  <li key={i}>
                    <Typography variant="subtitle2">{text}</Typography>
                    {description && (
                      <ul>
                        <li>{description}</li>
                      </ul>
                    )}
                  </li>
                ))}
            </ul>

            {!isEmpty(siteErrors) && <Typography variant="h3">Site Errors</Typography>}

            <ul>
              {siteErrors
                .map((err) => {
                  const { errorCode } = err;

                  return {
                    text: handleErrorMessage.getErrorMessage(errorCode),
                    description: KNOWN_SITE_ERRORS[errorCode] || null,
                  };
                })
                .map(({ text, description }, i) => (
                  <li key={i}>
                    <Typography variant="subtitle2">{text}:</Typography>
                    {description && (
                      <ul>
                        <li>{description}</li>
                      </ul>
                    )}
                  </li>
                ))}
            </ul>
          </Box>
        </Box>
      </>
    );
  }
  return (
    <Typography variant="body2" color="inherit">
      {COMMON_ERROR_TEXT}
    </Typography>
  );
};
