import { combineReducers } from '@reduxjs/toolkit';

import account from './account';
import addSite from './addSite';
import addSystemsToSite from './addSystemsToSite';
import alertDetails from './alertDetails';
import { api } from './api';
import auth from './auth';
import companies from './companies';
import company from './company';
import companyAlerts from './companyAlerts';
import companyAssociations from './companyAssociations';
import companyUsers from './companyUsers';
import featureToggles from './featureToggles';
import mapSites from './mapSites';
import signup from './signup';
import siteAlerts from './siteAlerts';
import siteLayout from './siteLayout';
import sites from './sites';
import ui from './ui';

const rootReducer = combineReducers({
  auth,
  companyAlerts,
  siteAlerts,
  company,
  companies,
  sites,
  mapSites,
  siteLayout,
  addSite,
  account,
  signup,
  companyUsers,
  addSystemsToSite,
  companyAssociations,
  alertDetails,
  featureToggles,
  ui,
  [api.reducerPath]: api.reducer,
});

export default rootReducer;
