import type { SxProps, TextFieldProps } from '@mui/material';

import { SettingInputWrapper } from '../settingInputWrapper';
import type { SettingTextInputProps } from '../settingTextInput';
import PwrSettingsTextInput from '../settingTextInput';

interface SettingInputProps extends SettingTextInputProps {
  label: string;
  description: string;
  rootSx?: SxProps;
}

export const SettingNumberInput = ({
  label,
  description,
  rootSx,
  name = '',
  ...inputProps
}: SettingInputProps & TextFieldProps) => {
  return (
    <SettingInputWrapper
      label={label}
      name={name}
      description={description}
      rootSx={{
        mb: 3,
      }}
      settingInputSlotElement={<PwrSettingsTextInput {...inputProps} name={name} />}
    />
  );
};
