export enum AddSiteStep {
  PREPARATION,
  ADD_DETAILS,
  SITE_ADDRESS_VERIFICATION,
  HOMEOWNER_ADDRESS_VERIFICATION,
  ADD_SYSTEM_DETAILS,
  PAIRING,
  REVIEW,
  SUCCESS,
}
