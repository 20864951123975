import { Box, Typography } from '@mui/material';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';

import { settingsUnitMap } from '../../constants/settings';
import type { SettingsMetaDataType } from '../../types/DeviceSettings';
import { SettingNumberInput } from '../settingNumberInput';

export interface SettingInputMetadata {
  name: string;
  label: string;
  description: string;
  currentValue: number;
  unit: string;
  type: SettingsMetaDataType;
  constraints: {
    minValue?: number;
    maxValue?: number;
  };
  loading?: boolean;
  editable?: boolean;
  error?: string;
}

interface SettingsPanelProps {
  name?: string;
  inputsMetadata: SettingInputMetadata[];
  handleUpdateSetting: (updatedInputMetadata: SettingInputMetadata) => void;
}

const validateInputs = (inputsMetadata: SettingInputMetadata[]) => {
  const errors: { [key: string]: string } = {};

  inputsMetadata.forEach((input) => {
    const value = input.currentValue;
    if (input.constraints.minValue && value < input.constraints.minValue) {
      errors[input.name] = `Value must be greater than ${input.constraints.minValue}`;
    }
    if (input.constraints.maxValue && value > input.constraints.maxValue) {
      errors[input.name] = `Value must be less than ${input.constraints.maxValue}`;
    }
  });

  return errors;
};

export const SettingsPanel = ({
  name,
  inputsMetadata,
  handleUpdateSetting,
}: SettingsPanelProps) => {
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [updatedInputsMetadata, setUpdatedInputsMetadata] = useState<SettingInputMetadata[]>([]);

  useEffect(() => {
    if (inputsMetadata) {
      setUpdatedInputsMetadata(inputsMetadata);
    }
  }, [inputsMetadata]);

  useEffect(() => {
    setErrors(validateInputs(updatedInputsMetadata));
  }, [updatedInputsMetadata]);

  const handleInputChange = (inputParams: SettingInputMetadata, newValue: number) => {
    setUpdatedInputsMetadata((prev) => {
      return prev.map((input) => {
        if (input.name === inputParams.name) {
          return {
            ...input,
            currentValue: newValue,
          };
        }
        return input;
      });
    });
  };

  const handleSubmit = (inputName: string) => {
    const input = updatedInputsMetadata.find((input) => input.name === inputName);
    if (input && !errors[inputName]) {
      handleUpdateSetting(input);
    }
  };
  return (
    <Box data-test-class="settings-inputs-panel">
      {name && (
        <Typography
          variant="h3"
          sx={{
            fontSize: {
              xs: 18,
              md: 22,
            },
            mb: 3,
          }}
          data-test-class="settings-inputs-panel-title"
        >
          {name}
        </Typography>
      )}
      {updatedInputsMetadata.map((input, index) => {
        const isSubmitButtonDisabled = input.currentValue === inputsMetadata[index].currentValue;
        const error = errors[input.name] || input.error;
        const helperText =
          !isNil(input.constraints.minValue) && !isNil(input.constraints.maxValue)
            ? `${input.constraints.minValue} - ${input.constraints.maxValue}`
            : '';

        return (
          <SettingNumberInput
            name={input.name}
            label={input.label}
            description={input.description}
            valueUnit={settingsUnitMap[input.unit] ?? input.unit}
            value={input.currentValue}
            onChange={(e) => handleInputChange(input, Number(e.target.value))}
            onSubmit={() => handleSubmit(input.name)}
            helperText={helperText}
            rootSx={{
              mb: {
                xs: 3,
                md: 0,
              },
            }}
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*',
            }}
            type="number"
            disableSubmitButton={isSubmitButtonDisabled}
            errorText={error}
            isLoading={input.loading}
            readOnly={!input.editable}
          />
        );
      })}
    </Box>
  );
};
