import { createSelector } from '@reduxjs/toolkit';

import { AddedSystemStatusName } from '../../types/AddSystemsToSite';
import { SystemType } from '../../types/System';
import { getSystemBySerialNumber } from '../../utils';
import { isSystemInverter, isSystemLoadManager } from '../../utils/systemType';
import type { RootState } from '../types';

export const isLoadingSelector = (state: RootState) => state.addSystemsToSite.loading;
export const errorsSelector = (state: RootState) => state.addSystemsToSite.errors;
export const addedSystemsSelector = (state: RootState) => state.addSystemsToSite.data;

export const neverConnectedSystemsCountSelector = createSelector(
  addedSystemsSelector,
  (systems) =>
    systems?.filter?.((s) => s.systemConnectionStatus === AddedSystemStatusName.NEVER_CONNECTED)
      ?.length || 0,
);

export const systemsAddedSelector = (state: RootState) => state.addSystemsToSite.systemsAdded;
export const addSystemCurrentStepSelector = (state: RootState) => state.addSystemsToSite.step;
export const systemsSelector = (state: RootState) => state.addSystemsToSite.systems;

export const pairingRecordSelector = (state: RootState) => state.addSystemsToSite.pairingRecord;

export const siteSystemsSelector = (state: RootState) => state.addSystemsToSite.siteSystems;

export const newLoadManagersSelector = createSelector(systemsSelector, (systems) =>
  systems.filter(isSystemLoadManager),
);
export const newInvertersSelector = createSelector(systemsSelector, (systems) =>
  systems.filter(isSystemInverter),
);

export const existingInvertersSelector = createSelector(siteSystemsSelector, (systems) =>
  systems.filter(({ systemType }) => systemType === SystemType.ESS),
);
export const existingLoadManagersSelector = createSelector(siteSystemsSelector, (systems) =>
  systems.filter(({ systemType }) => systemType === SystemType.LOAD_MANAGER),
);
export const invertersToPairWithSelector = createSelector(
  existingInvertersSelector,
  newInvertersSelector,
  (existingInverters, newInverters) => [...existingInverters, ...newInverters],
);
export const onePairRecordSelector = createSelector(
  newLoadManagersSelector,
  newInvertersSelector,
  (loadManagers, inverters) => {
    if (loadManagers.length === 1 && inverters.length === 1) {
      const { serialNumber: loadManagerSerialNumber } = loadManagers[0];
      const { serialNumber: inverterSerialNumber } = inverters[0];
      return {
        [loadManagerSerialNumber]: inverterSerialNumber,
      };
    }
  },
);

export const isAllPairedSelector = createSelector(
  newLoadManagersSelector,
  pairingRecordSelector,
  (loadManagers, pairingRecord) =>
    loadManagers.every(({ serialNumber }) => pairingRecord[serialNumber]),
);

type System = { serialNumber: string; systemId: string };
type SystemPair = { loadManager: System; inverter: System };

export const systemsForPairingSelector = createSelector(
  pairingRecordSelector,
  addedSystemsSelector,
  existingInvertersSelector,
  (pairingRecord, systems, existingInverters) => {
    if (systems) {
      return Object.entries(pairingRecord)
        .map(([loadManagerSerialNumber, inverterSerialNumber]) => {
          const loadManager = getSystemBySerialNumber(systems, loadManagerSerialNumber);
          const existingInverter = getSystemBySerialNumber(existingInverters, inverterSerialNumber);
          const addedInverter = getSystemBySerialNumber(systems, inverterSerialNumber);
          const inverter = addedInverter || existingInverter;
          if (loadManager && inverter) {
            return {
              loadManager,
              inverter,
            };
          }
          return null;
        })
        .filter((item): item is SystemPair => !!item);
    }
  },
);

export const siteSystemsLoadingSelector = (state: RootState) =>
  state.addSystemsToSite.siteSystemsLoading;
export const siteSystemsErrorSelector = (state: RootState) =>
  state.addSystemsToSite.siteSystemsError;

export const isPairingSelector = (state: RootState) => state.addSystemsToSite.isPairing;
export const pairingErrorSelector = (state: RootState) => state.addSystemsToSite.pairingError;
