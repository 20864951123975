import { createAction, createAsyncAction } from 'typesafe-actions';

import type {
  LoadManagerPairFailureResponse,
  LoadManagerPairRequest,
  LoadManagerPairSuccessResponse,
} from '../../types/LoadManager';
import type { SystemDetails } from '../../types/Site';
import type {
  AddSiteFailureResponseData,
  AddSiteSuccessResponse,
  SuggestionListInterface,
  ValidationAddressFailureInterface,
  ValidationSiteAddressInterface,
  ValidationSiteAddressResponseInterface,
} from '../../types/SiteDetails';
import type {
  SystemDetailsFailureResponse,
  SystemDetailsSuccessResponse,
} from '../../types/SystemDetails';
import type {
  UtilitiesFailedResponse,
  UtilitiesRequest,
  UtilitiesSuccessResponse,
} from '../../types/Utility';
import type { AddDetailsStepData } from '.';
import type { AddSiteStep } from './stepsData';

export const setStep = createAction(
  'ADD_SITE_SET_STEP',
  (step: AddSiteStep) => step,
)<AddSiteStep>();

type AddDetailsStepPayload = {
  data: AddDetailsStepData;
  validate: boolean;
};

export const setAddDetailsStepData = createAction(
  'ADD_SITE_DETAILS_DATA',
  (data: AddDetailsStepData, validate = true) => ({ data, validate }),
)<AddDetailsStepPayload>();

export const getAddSite = createAsyncAction(
  'GET_ADD_SITE_REQUEST',
  'GET_ADD_SITE_SUCCESS',
  'GET_ADD_SITE_FAILURE',
)<undefined, AddSiteSuccessResponse, AddSiteFailureResponseData>();

export const getValidationSiteAddress = createAsyncAction(
  'GET_VALIDATION_SITE_ADDRESS_REQUEST',
  'GET_VALIDATION_SITE_ADDRESS_SUCCESS',
  'GET_VALIDATION_SITE_ADDRESS_FAILURE',
)<
  ValidationSiteAddressInterface,
  ValidationSiteAddressResponseInterface,
  ValidationAddressFailureInterface
>();

export const getValidationSiteAddressHomeowner = createAsyncAction(
  'GET_VALIDATION_SITE_ADDRESS_HO_REQUEST',
  'GET_VALIDATION_SITE_ADDRESS_HO_SUCCESS',
  'GET_VALIDATION_SITE_ADDRESS_HO_FAILURE',
)<
  ValidationSiteAddressInterface,
  ValidationSiteAddressResponseInterface,
  ValidationAddressFailureInterface
>();

export const clearAddressValidationErrors = createAction('CLEAR_ADDRESS_VALIDATION_ERRORS')();

export const clearAddSiteError = createAction('CLEAR_ADD_SITE_ERROR')();

export const removeSystemFromWizard = createAction(
  'REMOVE_SYSTEM_FROM_WIZARD',
  (index: number) => index,
)<number>();

export const updateSystemsInWizard = createAction(
  'UPDATE_SYSTEMS_IN_WIZARD',
  (systems: SystemDetails[]) => systems,
)<SystemDetails[]>();

export const resetAddSiteState = createAction('ADD_SITE_RESET_STATE', undefined)<undefined>();

export const setSiteAddress = createAction('SET_SITE_ADDRESS')<SuggestionListInterface>();

export const setHomeownerAddress = createAction('SET_HOMEOWNER_ADDRESS')<SuggestionListInterface>();

export const setAddress = createAction('SET_ADDRESS')<SuggestionListInterface>();

export const skipAddressValidation = createAction('SKIP_ADDRESS_VALIDATION')();

export const loadUtilities = createAsyncAction(
  'LOAD_UTILITIES_REQUEST',
  'LOAD_UTILITIES_SUCCESS',
  'LOAD_UTILITIES_FAILURE',
)<UtilitiesRequest, UtilitiesSuccessResponse, UtilitiesFailedResponse>();

export const setPairingRecord = createAction('SET_PAIRING_RECORD')<Record<string, string>>();

export const loadSystemsDetails = createAsyncAction(
  'LOAD_SYSTEMS_DETAILS_REQUEST',
  'LOAD_SYSTEMS_DETAILS_SUCCESS',
  'LOAD_SYSTEMS_DETAILS_FAILURE',
)<{ systemIds: string[] }, SystemDetailsSuccessResponse[], SystemDetailsFailureResponse>();

export const pairLoadManagers = createAsyncAction(
  'PAIR_LOAD_MANAGERS_REQUEST',
  'PAIR_LOAD_MANAGERS_SUCCESS',
  'PAIR_LOAD_MANAGERS_FAILURE',
)<
  LoadManagerPairRequest[],
  LoadManagerPairSuccessResponse[],
  LoadManagerPairFailureResponse | Error
>();
