import type { AxiosError } from 'axios';

import type { Capability } from './Capability';
import type { LoadManagerStatus } from './SiteDetails';
import type { SiteFilterId } from './SiteFilter';
import type { SystemType } from './System';

export interface Site {
  siteId: string;
  siteName: string;
  capability: Capability[];
  soc?: number;
  soh: number;
  installedPV?: number;
  installedStorage: number;
  updateDate: string;
  installedDate: string;
  highestSeverityAlert: HighestSeverityAlert;
  generation: Partial<Generation>;
  consumption: Partial<Consumption>;
  siteAddress: Address;
  timezone: string;
  status: SiteConnectionStatus;
  inverterSerialNumber?: string[];
  contact: Partial<{ email: string; name: string }>;
  systemTypes: SiteSystemType[];
}

export interface MapSite {
  siteId: string;
  siteName: string;
  longitude: number;
  latitude: number;
  installedPV?: number;
  installedDate: string;
  loadControllerStatus?: LoadManagerStatus;
}

export type SiteQueryParams = Partial<Record<SiteFilterId, any>>;

interface Address {
  country: string;
  state: string;
  city: string;
  zip: string;
  streetAddress: string;
  longitude: number;
  latitude: number;
}

interface Consumption {
  consumptionToday: number;
  consumptionYesterday: number;
  consumptionThisMonth: number;
  consumptionLastMonth: number;
}

interface Generation {
  performanceToday: number;
  performanceYesterday: number;
  avgPerformanceLastMonth: number;
  avgPerformanceThisMonth: number;
  avgPerformanceThisYear: number;
  yieldToday: number;
  yieldYesterday: number;
}

export enum SystemProperty {
  SerialNumber = 'serialNumber',
  ActivationCode = 'activationCode',
  SystemType = 'systemType',
}

export interface SystemDetails {
  [SystemProperty.SerialNumber]: string;
  [SystemProperty.ActivationCode]: string;
}

export interface SystemDetailsRemoteError {
  serialNumber: string;
  isValid?: string;
  errorCode?: number;
}

interface HighestSeverityAlert {
  id?: string;
  name?: string;
  severity?: string;
  state?: string;
  updateDate: number;
  triggeredDate: number;
}

export interface SiteListExportParams {
  countryCode?: string;
  state?: string;
  city?: string;
  zip?: string;
  siteName?: string;
  siteId?: string;
  sort?: string;
  sensorInstallDate?: string[];
}

export interface SiteListExportRequest {
  companyId: string;
  params?: SiteListExportParams;
}

export interface SiteListExportSuccessResponse {
  emailSentTo: string;
  message: string;
}

export interface SiteListExportError {
  status: number;
  timestamp: string;
  error: string;
  message: string;
  path: string;
  previousRequestEmail?: string; // only for 423 Error
}

export type SiteListExportErrorResponse = AxiosError<SiteListExportError>;

export type SiteListExportResponse = SiteListExportSuccessResponse | SiteListExportErrorResponse;

export interface SitesResponse {
  data: Site[];
  page: number;
  perPage: number;
  total: number;
}

export interface SitesRequest {
  fleetId: string;
  page: number;
  perPage?: number;
  companyId: string;
}

export enum SiteConnectionStatus {
  NEVER_CONNECTED = 'NEVER_CONNECTED',
  OFFLINE = 'OFFLINE',
  ONLINE = 'ONLINE',
  UNKNOWN = 'UNKNOWN',
}

export interface SiteSystemType {
  systemType: SystemType;
  quantity: number;
}

export enum SiteOwnershipType {
  COMPANY = 'COMPANY',
  HOMEOWNER = 'HOMEOWNER',
}
