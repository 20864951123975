export enum Routes {
  Login = '/login',
  Logout = '/logout',
  Signup = '/signup',
  AuthCallback = '/auth-callback',
  Profile = '/profile',
}

export const CompanyDashboard = '/companies/:companyId'; // used for url parsing and redirects

export enum PublicRoutes {
  CompanyAlerts = '/alerts/:tab/:alertId?',
  CompanySupport = '/support',
  CompanySupportCase = '/support/:caseId',
  CompanyAssociations = '/associations',
  CompanyUsers = '/users',
  CompanyDetails = '/details',
  CompanySitesList = '/sites',
  CompanySitesMap = '/sites-map',
  SingleDeviceChart = '/sites/:siteId/devices/:rcpn',
  SiteDashboard = '/sites/:siteId',
  SiteAccess = '/sites/:siteId/access',
  SiteAlerts = '/sites/:siteId/alerts/:tab/:alertId?',
  SiteDevices = '/sites/:siteId/devices/:systemId?/:deviceType?/:deviceId?',
  SiteDevicesMetrics = '/sites/:siteId/devicesMetrics/:systemId?/:deviceType?/:deviceId?',
  SiteDevicesConfig = '/sites/:siteId/devicesConfig/:systemId?/:deviceType?/:deviceId?',
  SiteDetails = '/sites/:siteId/details',
  SiteConfiguration = '/sites/:siteId/configuration',
  SiteFirmware = '/sites/:siteId/firmware/:systemId?', // feature removed
  SiteLayout = '/sites/:siteId/layout', // feature removed
  SiteLayoutEdit = '/sites/:siteId/layout/edit', // feature removed
}

export enum SupportRoutes {
  UsersList = '/users',
  CompanyList = '/companies',
  CompanyAlerts = '/companies/:companyId/alerts/:tab/:alertId?',
  CompanySupport = '/companies/:companyId/support',
  CompanySupportCase = '/companies/:companyId/support/:caseId',
  CompanyAssociations = '/companies/:companyId/associations',
  CompanyUsers = '/companies/:companyId/users',
  CompanyDetails = '/companies/:companyId/details',
  CompanySitesList = '/companies/:companyId/sites',
  CompanySitesMap = '/companies/:companyId/sites-map',
  SingleDeviceChart = '/companies/:companyId/sites/:siteId/devices/:rcpn',
  SiteDashboard = '/companies/:companyId/sites/:siteId',
  SiteAccess = '/companies/:companyId/sites/:siteId/access',
  SiteAlerts = '/companies/:companyId/sites/:siteId/alerts/:tab/:alertId?',
  SiteDevices = '/companies/:companyId/sites/:siteId/devices/:systemId?/:deviceType?/:deviceId?',
  SiteDevicesMetrics = '/companies/:companyId/sites/:siteId/devicesMetrics/:systemId?/:deviceType?/:deviceId?',
  SiteDevicesConfig = '/companies/:companyId/sites/:siteId/devicesConfig/:systemId?/:deviceType?/:deviceId?',
  SiteDetails = '/companies/:companyId/sites/:siteId/details',
  SiteConfiguration = '/companies/:companyId/sites/:siteId/configuration',
  SiteFirmware = '/companies/:companyId/sites/:siteId/firmware/:systemId?', // feature removed
  SiteLayout = '/companies/:companyId/sites/:siteId/layout', // feature removed
  SiteLayoutEdit = '/companies/:companyId/sites/:siteId/layout/edit', // feature removed
}
