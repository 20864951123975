import 'react-toastify/dist/ReactToastify.css';

import { makeStyles } from '@mui/styles';
import { LicenseInfo as MUILicenseInfo } from '@mui/x-data-grid-pro';
import type { ComponentType } from 'react';
import { lazy, Suspense, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Router, Switch, useLocation } from 'react-router-dom';

import browserHistory from './browserHistory';
import {
  AlertsContainer,
  GatedFeature,
  GatedRoute,
  Logout,
  PageTracker,
  ScrollToTop,
  SearchPopup,
  SuspenseLoader,
  UiLayout,
} from './components';
import { LocalStorageItem } from './constants/localStorage';
import { CompanyDashboard } from './constants/routes';
import {
  init as featureToggleClientInit,
  start as featureToggleClientStart,
} from './featureToggleClient';
import { useAlert } from './hooks/useAlert';
import { useAppSelector } from './hooks/useAppSelector';
import { useCompanyContext } from './hooks/useCompanyContext';
import { useExactRouteMatch } from './hooks/useExactRouteMatch';
import { useFeatureToggle } from './hooks/useFeatureToggle';
import { useRoutes } from './hooks/useRoutes';
import { useSiteContext } from './hooks/useSiteContext';
import { AuthCallback } from './screens/authCallback';
import { Login } from './screens/login';
import { Signup } from './screens/login/signup';
import {
  isAuthenticatingSelector,
  isLoggedInSelector,
  userCompanyIdSelector,
  userIdSelector,
  userRoleSelector,
} from './store/auth/selectors';
import { setCompanyId } from './store/company/actions';
import { selectedCompanySelector } from './store/company/selectors';
import { setFeatureTogglesFinishState, updateFeatureToggles } from './store/featureToggles';
import { defaultTheme, PWRfleetThemeProvider } from './styles';
import type { FeatureToggleList } from './types/FeatureToggle';
import { FeatureToggle } from './types/FeatureToggle';
import { Alert, AlertContainer } from './types/ScreenAlerts';
import { UIFeature } from './types/UIFeature';

const Root = lazy(() => import('./screens/root'));
const CompanyList = lazy(() => import('./screens/companyList'));
const CompanyAlerts = lazy(() => import('./screens/companyAlerts'));
const CompanySupport = lazy(() => import('./screens/companySupport'));
const CompanySupportCase = lazy(() => import('./screens/companySupport/viewCompanySupportCase'));
const CompanyAssociations = lazy(() => import('./screens/companyAssociations'));
const CompanyDetails = lazy(() => import('./screens/companyDetails'));
const CompanyUsers = lazy(() => import('./screens/companyUsers'));
const CompanySitesContainer = lazy(() => import('./screens/companySitesContainer'));
const Profile = lazy(() => import('./screens/profile'));
const SiteContainer = lazy(() => import('./screens/siteContainer'));
const UserList = lazy(() => import('./screens/userList'));
const Search = lazy(() => import('./screens/search'));

const MergeCompaniesWizard = lazy(() => import('./screens/mergeCompaniesWizard'));
const NewAddSiteWizard = lazy(() => import('./screens/newAddSiteWizard'));
const AddSiteWizard = lazy(() => import('./screens/addSiteWizard'));
const DeleteSiteDialog = lazy(() => import('./screens/deleteSiteDialog'));
const AddEquipmentDialog = lazy(() => import('./screens/addEquipmentDialog'));
const AddSystemWizard = lazy(() => import('./screens/addSystemWizard'));
const PowerCoreSwapWizard = lazy(() => import('./screens/powerCoreSwapWizard'));
const SystemModeEventsDialog = lazy(() => import('./screens/systemModeEventsDialog'));
const ViewLoadManagerDetailsModal = lazy(() => import('./screens/viewLoadManagerDetailsModal'));
const DeviceSwapWizard = lazy(() => import('./screens/deviceSwapWizard'));
const LoadManagerEditPairingWizard = lazy(() => import('./screens/loadManagerEditPairingWizard'));

const useStyles = makeStyles(() => ({}));

const UrlParamRoute = ({
  param,
  component: Component,
}: {
  param: string;
  component: ComponentType;
}) => {
  return (
    <Route
      render={({ location }) => {
        const sp = new URLSearchParams(location.search);
        if (sp.has(param)) {
          return <Component />;
        }
        return null;
      }}
    />
  );
};

const LoggedInRoutes = () => {
  const Routes = useRoutes();
  const dispatch = useDispatch();
  const { siteId } = useSiteContext();

  const isExactMatchSiteMap = useExactRouteMatch(Routes.CompanySitesMap);
  const createSiteV2Enabled = useFeatureToggle(FeatureToggle.CREATE_SITE_ENABLE_V2);

  const selectedCompany = useAppSelector(selectedCompanySelector);

  const isSupportPortalEnabled = selectedCompany?.validatedInSalesforce;

  const { companyId = '' } = useCompanyContext();

  // set companyId value that is parsed from URL in redux store to avoid missing companyId bug
  // for SUPPORT users when visiting page directly from URL
  useEffect(() => {
    if (companyId) {
      dispatch(setCompanyId(companyId));
    }
  }, [dispatch, companyId]);

  return (
    <UiLayout>
      <Suspense fallback={<SuspenseLoader />}>
        <GatedFeature
          uiFeature={UIFeature.SITE_ADD}
          onAllowed={() => (
            <UrlParamRoute
              param="add-site-modal"
              component={createSiteV2Enabled ? NewAddSiteWizard : AddSiteWizard}
            />
          )}
        />
        {siteId && !isExactMatchSiteMap && <SearchPopup />}
        <UrlParamRoute param="search" component={Search} />
        <GatedFeature
          uiFeature={UIFeature.SYSTEM_ADD}
          onAllowed={() => (
            <UrlParamRoute
              param="add-system-modal"
              component={createSiteV2Enabled ? AddEquipmentDialog : AddSystemWizard}
            />
          )}
        />
        <GatedFeature
          uiFeature={UIFeature.COMPANY_BULK_MERGE}
          onAllowed={() => (
            <UrlParamRoute param="merge-companies-modal" component={MergeCompaniesWizard} />
          )}
        />
        <GatedFeature
          uiFeature={UIFeature.SITE_DELETE}
          onAllowed={() => <UrlParamRoute param="delete-site" component={DeleteSiteDialog} />}
        />
        <GatedFeature
          uiFeature={UIFeature.MCU_SWAP}
          onAllowed={() => <UrlParamRoute param="mcu-swap" component={DeviceSwapWizard} />}
        />
        <GatedFeature
          uiFeature={UIFeature.POWERCORE_SWAP}
          onAllowed={() => <UrlParamRoute param="powercore-swap" component={PowerCoreSwapWizard} />}
        />
        <UrlParamRoute param="event-calendar" component={SystemModeEventsDialog} />
        <GatedFeature
          uiFeature={UIFeature.LOAD_MANAGER_PAIRING_EDIT}
          onAllowed={() => (
            <UrlParamRoute param="edit-pairing" component={LoadManagerEditPairingWizard} />
          )}
        />
        <GatedFeature
          uiFeature={UIFeature.LOAD_MANAGER_DETAILS_VIEW}
          onAllowed={() => (
            <UrlParamRoute param="view-details" component={ViewLoadManagerDetailsModal} />
          )}
        />
        <Switch>
          <Route exact path={[Routes.CompanyAlerts]} component={CompanyAlerts} />
          <Route
            path={[Routes.CompanySupportCase]}
            component={isSupportPortalEnabled ? CompanySupportCase : undefined}
          />
          <Route
            path={[Routes.CompanySupport]}
            component={isSupportPortalEnabled ? CompanySupport : undefined}
          />
          <Route exact path={Routes.Profile} component={Profile} />
          <GatedRoute
            uiFeature={UIFeature.SUPPORT_ALL_USERS_VIEW}
            exact
            path={Routes.UsersList}
            component={UserList}
            redirectPath="/"
          />
          <GatedRoute
            uiFeature={UIFeature.SUPPORT_ALL_COMPANIES_VIEW}
            exact
            path={Routes.CompanyList}
            component={CompanyList}
          />
          <Route
            exact
            path={[Routes.CompanySitesMap, Routes.CompanySitesList]}
            component={CompanySitesContainer}
          />
          <Route path={[Routes.SiteDashboard]} component={SiteContainer} />
          <GatedRoute
            uiFeature={UIFeature.COMPANY_ASSOCIATIONS_VIEW}
            exact
            path={Routes.CompanyAssociations}
            component={CompanyAssociations}
          />
          <GatedRoute
            uiFeature={UIFeature.USER_VIEW}
            exact
            path={Routes.CompanyUsers}
            component={CompanyUsers}
          />
          <GatedRoute
            uiFeature={UIFeature.COMPANY_VIEW}
            exact
            path={Routes.CompanyDetails}
            component={CompanyDetails}
          />
          {/* PWRcrumbs creates a dead link to /companies/:companyId, redirect it to the desired page */}
          <Redirect from={CompanyDashboard} to={Routes.CompanySitesList} />
          <Route path="*" component={Root} />
        </Switch>
      </Suspense>
    </UiLayout>
  );
};

const LoginRedirect = () => {
  const Routes = useRoutes();
  const { pathname = '', search = '' } = useLocation();
  const url = `${pathname}${search}`;

  useEffect(() => {
    if (url) {
      localStorage.setItem(LocalStorageItem.REDIRECT_URL, url);
    }
  }, [url]);
  return <Redirect to={Routes.Login} />;
};

const App = () => {
  useStyles();
  const Routes = useRoutes();
  const dispatch = useDispatch();
  const [connectionStatus, setConnectionStatus] = useState<'online' | 'offline'>('online');

  const isLoggedIn = useAppSelector(isLoggedInSelector);
  const isAuthenticating = useAppSelector(isAuthenticatingSelector);
  const userId = useAppSelector(userIdSelector);
  const userRole = useAppSelector(userRoleSelector);
  const userCompanyId = useAppSelector(userCompanyIdSelector);

  useAlert(
    { alert: Alert.ConnectionLostError, condition: connectionStatus === 'offline' },
    {
      title: 'Internet connection lost.',
      buttons: [
        {
          children: 'Refresh',
          onClick: () => window.location.reload(),
        },
      ],
    },
  );

  const handleOnline = useCallback(
    (e: Event) => {
      if (e.type === 'offline') {
        setConnectionStatus('offline');
      } else {
        setConnectionStatus('online');
      }
    },
    [setConnectionStatus],
  );

  useEffect((): (() => void) => {
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOnline);
    return (): void[] => [
      window.removeEventListener('online', handleOnline),
      window.removeEventListener('offline', handleOnline),
    ];
  }, [handleOnline]);

  // @todo this may need to be cleaned up / reorganized
  // also, checks for client being re-initialized in case of
  // a dependency prop update
  useEffect(() => {
    const initializeFeatureToggles = async () => {
      try {
        /**
         * userCompanyId will be empty for Support users here
         * therefore, Unleash will not be able to use a Support user's companyId
         * for toggle constraints.
         * This is fine, as we'll only care about their SUPPORT role
         */
        const client = await featureToggleClientInit({
          userId,
          userRole,
          companyId: userCompanyId,
        });
        client.on('update', () => {
          const featureToggles: FeatureToggleList = [];
          Object.values(FeatureToggle).forEach((value) => {
            if (client.isEnabled(value)) {
              featureToggles.push(value);
            }
          });
          dispatch(updateFeatureToggles(featureToggles));
        });

        featureToggleClientStart();
      } catch (e) {
        // We want to show this in case Unleashed is down
        // eslint-disable-next-line no-console
        console.error('Could not initialize feature toggles');
        dispatch(setFeatureTogglesFinishState());
      }
    };

    if (userId && userRole) {
      initializeFeatureToggles();
    }
  }, [userId, userRole, userCompanyId, dispatch]);

  // Set MUI license info
  MUILicenseInfo.setLicenseKey(
    '3d94c91a1b4832fbf89872d197ccf407Tz04NTU2MSxFPTE3NDA5NDE0MDcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
  ); // Hardcoding it is OK and intentional: https://mui.com/x/introduction/licensing/#security

  return (
    <PWRfleetThemeProvider theme={defaultTheme}>
      <>
        <AlertsContainer containerId={AlertContainer.Root} />
        <Router history={browserHistory}>
          <ScrollToTop />
          <Switch>
            <Route exact path={Routes.AuthCallback} component={AuthCallback} />
            <Route exact path={Routes.Login} component={Login} />
            <Route exact path={Routes.Logout} component={Logout} />
            <Route exact path={Routes.Signup} component={Signup} />
            {isLoggedIn && <LoggedInRoutes />}
            {!isAuthenticating && <LoginRedirect />}
          </Switch>
          <PageTracker />
        </Router>
      </>
    </PWRfleetThemeProvider>
  );
};

export default App;
