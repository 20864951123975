import { Capability } from '../types/Capability';

const capabilityOrder: Capability[] = [
  Capability.RGM,
  Capability.LOAD_MANAGER,
  Capability.PVL,
  Capability.MICROINVERTER,
  Capability.GENERATOR,
  Capability.BATTERY,
];

/**
 * Forces the icons in the Site List Capabilities column into a certain order
 * @param capabilities : site capabilities array returned by the endpoint
 * @returns : a list of capabilities in the order:  RGM, Load Manager (Controller), PVL, Generator, Battery
 */
export const reorderCapabilities = (capabilities: Capability[]): Capability[] => {
  const orderedCapabilitiesList: Capability[] = [];
  capabilityOrder.forEach((capability) => {
    if (capabilities.includes(capability)) {
      orderedCapabilitiesList.push(capability);
    }
  });
  return orderedCapabilitiesList;
};
