import type { PaginationProps, SelectChangeEvent } from '@mui/material';
import { Box, TableCell, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import { get } from 'lodash';
import { DateTime } from 'luxon';
import type { ComponentProps, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DATE_TABLE_FORMAT } from '../../constants/dates';
import { usePagination } from '../../hooks/usePagination';
import { setSitesSort } from '../../store/sites/actions';
import {
  newTableColumnsSelector,
  selectedSortOrderSelector,
  selectedSortPropertySelector,
} from '../../store/sites/selectors';
import type { RootState } from '../../store/types';
import { useViewMode } from '../../styles/stylingUtils';
import { Capability } from '../../types/Capability';
import type { RowComponentProps, TableColumn } from '../../types/NewTable';
import type { Site, SiteSystemType } from '../../types/Site';
import { SiteConnectionStatus } from '../../types/Site';
import { SystemType } from '../../types/System';
import { reorderCapabilities } from '../../utils/reorderCapabilities';
import { SortOrder } from '../../utils/sort';
import { Icon } from '../icon';
import { NewTable } from '../newTable';
import { PWRtooltip } from '../pwrTooltip';

const SITE_NAME_COLUMN_MIN_WIDTH = 200;
const ROWS_PER_PAGE_OPTIONS = [10, 25, 50];

export const StyledIcon = styled(Icon)`
  padding-left: 2px;
  padding-right: 2px;
`;

const connectionStatusPresenter = (connectionStatus: SiteConnectionStatus) => {
  switch (connectionStatus) {
    case SiteConnectionStatus.NEVER_CONNECTED:
      return (
        <Box display="flex" height="100%" alignItems="center" justifyContent="center">
          <PWRtooltip message="One or more systems have been registered but not yet internet connected">
            <Icon icon="never connected" />
          </PWRtooltip>
        </Box>
      );
    case SiteConnectionStatus.OFFLINE:
      return (
        <Box display="flex" height="100%" alignItems="center" justifyContent="center">
          <PWRtooltip message="One or more systems in this site have temporarily lost internet connectivity">
            <Icon icon="no connectivity" />
          </PWRtooltip>
        </Box>
      );
    case SiteConnectionStatus.ONLINE:
    case SiteConnectionStatus.UNKNOWN:
      return '';
    default:
      return '';
  }
};

const capabilityPresenter = (capabilities: Capability[]) => {
  if (capabilities && capabilities.length) {
    const orderedCapabilities: Capability[] = reorderCapabilities(capabilities);

    return (
      <Box display="inline-flex" height="100%" alignItems="center">
        {orderedCapabilities.map((capabilityType, i) => {
          switch (capabilityType) {
            case Capability.PVL:
              return (
                <PWRtooltip message="String Inverter installed" placement="right" key={i}>
                  <StyledIcon icon="solar" />
                </PWRtooltip>
              );
            case Capability.BATTERY:
              return (
                <PWRtooltip message="Storage installed" placement="right" key={i}>
                  <StyledIcon icon="battery" />
                </PWRtooltip>
              );
            case Capability.GENERATOR:
              return (
                <PWRtooltip message="DC Generator installed" placement="right" key={i}>
                  <StyledIcon icon="generator" size={22} />
                </PWRtooltip>
              );
            case Capability.RGM:
              return (
                <PWRtooltip message="Revenue Grade Meter installed" placement="right" key={i}>
                  <StyledIcon icon="rgm" size={25} />
                </PWRtooltip>
              );
            case Capability.LOAD_MANAGER:
              return (
                <PWRtooltip message="Load Manager installed" placement="right" key={i}>
                  <StyledIcon icon="load manager" size={24} />
                </PWRtooltip>
              );
            case Capability.MICROINVERTER:
              return (
                <PWRtooltip message="Microinverter installed" placement="right" key={i}>
                  <StyledIcon icon="solar" />
                </PWRtooltip>
              );
            default:
              // capability not supported/implemented here
              return null;
          }
        })}
      </Box>
    );
  }
  return null;
};

const systemTypesPresenter = (systemTypes: SiteSystemType[]) => {
  if (systemTypes && systemTypes.length) {
    return (
      <Box display="inline-flex" height="100%" alignItems="center">
        {systemTypes.map((systemType, i) => {
          switch (systemType.systemType) {
            case SystemType.ESS:
              return (
                <PWRtooltip message="String Inverter System" placement="right" key={i}>
                  <StyledIcon icon="inverter" size={25} />
                </PWRtooltip>
              );
            case SystemType.MICROINVERTER:
              return (
                <PWRtooltip message="Microinverter System" placement="right" key={i}>
                  <StyledIcon icon="microinverter" size={25} />
                </PWRtooltip>
              );
            default:
              return null;
          }
        })}
      </Box>
    );
  }
  return null;
};

const siteNamePresenter = (siteName: string, site: Site, isMobile: boolean) => {
  if (isMobile) {
    return (
      <div>
        <div>{siteName}</div>
        <div>{site.siteAddress.city}</div>
      </div>
    );
  }
  return <Box minWidth={SITE_NAME_COLUMN_MIN_WIDTH}>{siteName}</Box>;
};

const installedDatePresenter = (installedDate: string) => {
  return DateTime.fromISO(installedDate).toFormat(DATE_TABLE_FORMAT);
};

const StyledTableRow = styled(TableRow)`
  height: 50px;
`;

const CellContent = ({
  column,
  row,
  isMobile,
}: {
  column: TableColumn<Site>;
  row: Site;
  isMobile: boolean;
}) => {
  const { id, property, unit } = column;
  switch (id) {
    case 'status':
      return connectionStatusPresenter(row.status);
    case 'capability':
      return capabilityPresenter(row.capability);
    case 'systemTypes':
      return systemTypesPresenter(row.systemTypes);
    case 'siteName':
      return siteNamePresenter(row.siteName, row, isMobile);
    case 'installedDate':
      return installedDatePresenter(row.installedDate);
    case 'soc':
      if (unit && row.soc != null) {
        return `${unit.formatter(row.soc)}${unit.symbol}`;
      }
  }
  const value: any = get(row, property!);
  if (value == null) {
    return '--';
  } else if (unit) {
    return unit.formatter(value);
  }
  return value;
};

const RowComponent = ({ index, row, columns, TableRowProps }: RowComponentProps<Site>) => {
  const viewMode = useViewMode();
  const isMobile = viewMode === 'mobile';
  return (
    <StyledTableRow data-test-id={`sites-table-row-${index}`} {...TableRowProps}>
      {columns?.map((column) => (
        <TableCell key={`cell-${column.id}-${row.siteId}`}>
          <CellContent column={column} row={row} isMobile={isMobile} />
        </TableCell>
      ))}
    </StyledTableRow>
  );
};

export type Props = Pick<ComponentProps<typeof NewTable>, 'containerMaxHeight'> & {
  rows: Site[];
  onPageChange: PaginationProps['onChange'];
  onRowsPerPageChange: (event: SelectChangeEvent<unknown>, perPage: number) => void;
  onRowClick?: (row: Site, index: number) => void;
  hidePagination: boolean;
};

export const SitesTable = ({
  onPageChange,
  onRowsPerPageChange,
  rows,
  hidePagination,
  ...props
}: Props) => {
  const dispatch = useDispatch();
  const mode = useViewMode();
  const orderBy = useSelector(selectedSortPropertySelector);
  const order: SortOrder = useSelector(selectedSortOrderSelector);
  const { page, perPage, total } = usePagination({ selector: (state) => state.sites });
  const columns = useSelector((state: RootState) => newTableColumnsSelector(state, { mode }));

  const handleRequestSort = (event: MouseEvent<HTMLElement>, property: string) => {
    let isAsc = true;
    if (order) {
      isAsc = !(orderBy === property && order === SortOrder.ASC);
    }
    const newOrder: SortOrder = isAsc ? SortOrder.ASC : SortOrder.DESC;
    dispatch(setSitesSort({ property, order: newOrder }));
  };

  return (
    <NewTable
      columns={columns}
      rows={rows}
      RowComponent={RowComponent}
      idExtractor="siteId"
      stickyHeader
      HeadProps={{
        order,
        orderBy,
        onRequestSort: handleRequestSort,
      }}
      PaginationProps={
        !hidePagination
          ? {
              onChange: onPageChange,
              page,
              count: total,
              rowsPerPage: perPage,
              rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
              onRowsPerPageChange,
            }
          : undefined
      }
      {...props}
    />
  );
};
