import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Link as MUILink, Typography } from '@mui/material';
import type { ReactElement } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

import { Icon } from '../icon';
import type { IconType } from '../icon/icons';

export interface PWRHeaderProps {
  /** (Required) Page title used as the H1 */
  title: string;

  /** (Optional) Determine display of back arrow */
  hasArrowBack?: boolean;

  /** (Optional) Smaller text below the page title */
  subTitle?: string | ReactElement;

  /** (Optional) Icon to show before the title */
  icon?: IconType;
}

/** Simple reusable page header that accepts title, subtitle, and icon as props. Will generate breadcrumbs automatically based on the displayed screen's react-router route */
export const PWRheader = (props: PWRHeaderProps) => {
  const { title, hasArrowBack, subTitle, icon } = props;

  return (
    <Box data-test-id="pwrheader" sx={{ display: 'flex' }}>
      {hasArrowBack && (
        <Box pr={1}>
          <MUILink
            color="textPrimary"
            component={ReactRouterLink}
            to="."
            data-test-id="pwrheader-back-arrow"
          >
            <ArrowBackIcon />
          </MUILink>
        </Box>
      )}
      {icon && (
        <Box pr={2}>
          <Icon icon={icon} size={32} data-test-id="pwrheader-icon" />
        </Box>
      )}
      <Box pr={1}>
        <Typography variant="h3" component="h1" gutterBottom data-test-id="pwrheader-h1">
          {title}
        </Typography>
        <Box data-test-id="pwrheader-subtitle">{subTitle}</Box>
      </Box>
    </Box>
  );
};
