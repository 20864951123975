import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { INITIAL_KPIS } from '../../constants/kpi';
import { INITIAL_SORT_COLUMN } from '../../constants/userColumns';
import type { KpiId } from '../../types/Kpi';
import type { TableSort } from '../../types/Table';
import { SortOrder } from '../../utils/sort';

/*
 * Redux slice for ui state that needs to be persisted as user navigates app,
 * most if not all other reducers (which fetch data) should be migrated to
 * the rtk query api and/or apiSlices if it is refactored
 */

export interface State {
  features: {
    search: { value: string };
    kpisCard: {
      selectedKpis: KpiId[];
    };
    usersTable: {
      sort: TableSort;
    };
  };
}

const initialState: State = {
  features: {
    search: { value: '' },
    kpisCard: {
      selectedKpis: INITIAL_KPIS,
    },
    usersTable: {
      sort: {
        sortColumn: INITIAL_SORT_COLUMN,
        sortOrder: SortOrder.ASC,
      },
    },
  },
};

const slice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    updateSearchValue(state, action: PayloadAction<string>) {
      const searchValue = action.payload;
      state.features.search.value = searchValue;
    },
    updateSelectedKpis(state, action: PayloadAction<KpiId[]>) {
      const selectedKpis = action.payload;
      state.features.kpisCard.selectedKpis = selectedKpis;
    },
    updateUsersTableSort(state, action: PayloadAction<TableSort>) {
      const sort = action.payload;
      state.features.usersTable.sort = sort;
    },
  },
});

export const { updateSearchValue, updateSelectedKpis, updateUsersTableSort } = slice.actions;
export default slice.reducer;
