import type { TextFieldProps } from '@mui/material';
import { InputAdornment, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useAppSelector } from '../../hooks/useAppSelector';
import { searchValueSelector } from '../../store/ui/selectors';
import { Icon } from '../icon';

/**
 * A minimally-styled input field that accepts a string input and updates the URL path with a search param.
 * From there, <UrlParamRoute> in /App.tsx will detect it and pass it to /screens/search/index.tsx. All the actual search logic lives in that screen, not this component.
 * @param props Override the default prop values, if you like, such as by using different InputProps for adornments or overriding the built-in event handlers
 * @constructor
 */

export const SearchInput = (props?: TextFieldProps) => {
  const [searchInput, setSearchInput] = useState<string>('');
  const [shouldSearchBoxAutofocus, setShouldSearchBoxAutoFocus] = useState<boolean>(true);
  const inputRef = useRef<HTMLInputElement>(null);
  const history = useHistory();
  const searchQueryFromRedux = useAppSelector(searchValueSelector);

  const updateURLWithQuery = () => {
    const trimmedQuery = searchInput.trim();
    if (!trimmedQuery) {
      return;
    }

    history.push('?search&query=' + encodeURIComponent(trimmedQuery));
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && searchInput) {
      updateURLWithQuery();
    }

    if (event.key === 'Escape' && inputRef?.current) {
      inputRef.current.blur();
    }
  };

  useEffect(() => {
    if (searchQueryFromRedux) {
      setShouldSearchBoxAutoFocus(false); // If we're loading a search query, focus the RESULTS instead of the search input
      setSearchInput(searchQueryFromRedux);
    }
  }, [searchQueryFromRedux]);

  return (
    <TextField
      variant="outlined"
      placeholder="Search for sites, devices, or homeowners"
      inputRef={inputRef}
      value={searchInput}
      onChange={(event) => setSearchInput(event.target.value)}
      onKeyDown={handleKeyDown}
      autoFocus={shouldSearchBoxAutofocus}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Icon icon="search" />
          </InputAdornment>
        ),
        endAdornment: searchInput ? ( // TODO add ability to click on icon to search
          <InputAdornment position="end" onClick={updateURLWithQuery} style={{ cursor: 'pointer' }}>
            <Icon icon="enter" />
          </InputAdornment>
        ) : null,
      }}
      inputProps={{ 'data-test-id': 'site-search-input' }}
      sx={{ width: '400px' }}
      {...props}
    />
  );
};
