import { makeStyles } from '@mui/styles';

import { COLORS } from '../../styles';

export const useStyles = makeStyles({
  root: {
    display: 'inline-flex',
  },
  wrapper: {
    position: 'relative',
    display: 'flex',
  },
  buttonPrimary: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    width: 44,
    height: 44,
    margin: 0,
    padding: 0,
    '&:hover': {
      backgroundColor: COLORS.LIGHT_GREY,
      boxShadow: 'none',
    },
  },
  buttonError: {
    backgroundColor: COLORS.ERROR,
    boxShadow: 'none',
    margin: 0,
    padding: 0,
    width: 44,
    height: 44,
    '&:hover': {
      backgroundColor: COLORS.ERROR,
      boxShadow: 'none',
    },
  },
  buttonSuccess: {
    backgroundColor: COLORS.GREEN,
    boxShadow: 'none',
    margin: 0,
    padding: 0,
    width: 44,
    height: 44,
    '&:hover': {
      backgroundColor: COLORS.GREEN,
      boxShadow: 'none',
    },
  },
  buttonDisabled: {
    opacity: 0.3,
    backgroundColor: 'unset !important',
  },
  fabProgress: {
    width: 44,
    height: 44,
    borderRadius: 44,
    backgroundColor: COLORS.LIGHT_GREY,
    color: COLORS.GREEN,
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
  },
  iconProgress: {
    position: 'absolute',
    zIndex: 2,
    top: 12,
    left: 12,
  },
});
