import { createSelector } from '@reduxjs/toolkit';

import COLUMNS, {
  INITIAL_TABLET_COLUMNS,
  MOBILE_COLUMNS,
  TABLET_COLUMNS,
} from '../../constants/companyColumns';
import type { ViewMode } from '../../styles/stylingUtils';
import { ResponsiveViewMode } from '../../styles/stylingUtils';
import type { TableColumn } from '../../types/Table';
import { allUsersSelector } from '../api/selectors';
import type { RootState } from '../types';

export const companiesSelector = (state: RootState) => state.companies.items;
export const companySelector = (state: RootState, { companyId }: { companyId: string }) =>
  state.companies.items.find((c) => c.companyId === companyId);
export const companiesLoadingSelector = (state: RootState) => state.companies.loading;
export const companiesLoadingFinishedSelector = (state: RootState) =>
  state.companies.loadingFinished;
export const companiesErrorSelector = (state: RootState) => state.companies.error;

// @todo not yet implemented
export const selectedSortPropertySelector = (state: RootState) =>
  state.companies.selectedSortProperty;
export const selectedSortOrderSelector = (state: RootState) => state.companies.selectedSortOrder;

export const columnsSelector = createSelector(
  (state: RootState, { mode }: { mode: ViewMode }) => {
    if (mode === ResponsiveViewMode.TABLET) {
      return TABLET_COLUMNS;
    } else if (mode === ResponsiveViewMode.MOBILE) {
      return MOBILE_COLUMNS;
    }
    return INITIAL_TABLET_COLUMNS;
  },
  (selectedColumns) => {
    const sections = Object.keys(COLUMNS);
    let resultColumns: TableColumn[] = [];
    sections.forEach((s) => {
      const { items } = COLUMNS[s];
      const columns = items.filter((i) => selectedColumns.includes(i.name));
      resultColumns = [...resultColumns, ...columns];
    });
    return resultColumns;
  },
);

export const selectedCompaniesSelector = (state: RootState) => state.companies.selectedCompanyIds;

export const selectedCompaniesListSelector = createSelector(selectedCompaniesSelector, (obj) =>
  Object.entries(obj)
    .filter(([, value]) => value)
    .map(([key]) => key),
);

export const selectedCompaniesListLengthSelector = createSelector(
  selectedCompaniesListSelector,
  (arr) => arr.length,
);

export const selectedCompaniesFull = createSelector(
  companiesSelector,
  selectedCompaniesListSelector,
  (companies, ids) => {
    return companies.filter((c) => ids.includes(c.companyId));
  },
);

const targetCompanyId = (state: RootState, { targetCompanyId }: { targetCompanyId: string }) =>
  targetCompanyId;

export const companiesMergeStatsSelector = createSelector(
  targetCompanyId,
  selectedCompaniesFull,
  allUsersSelector,
  (targetCompanyId, selectedCompanies, users) => {
    return {
      companiesCount: selectedCompanies.length,
      usersCount: users.filter((u) => selectedCompanies.find((c) => c.companyId === u.companyId))
        .length,
      companyName: selectedCompanies.find((c) => c.companyId === targetCompanyId)?.name || '',
    };
  },
);

export const companiesMergeInProgressSelector = (state: RootState) =>
  state.companies.companiesMergeInProgress;
export const companiesMergeFinishedSelector = (state: RootState) =>
  state.companies.companiesMergeFinished;
