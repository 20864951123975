import type { SystemType } from './System';

export interface SystemsInterface {
  serialNumber: string;
  activationCode: string;
  systemType?: SystemType;
}

export interface AddSystemsToSiteRequest {
  siteId: string;
  userId: string;
  companyId: string;
  systems: SystemsInterface[];
}

type SystemError = {
  serialNumber: string;
  errorMsg: string;
  errorCode: number;
};

export interface AddSystemsToSiteFailure {
  systemErrorList: SystemError[];
}

export enum AddedSystemStatusName {
  CONNECTED = 'CONNECTED',
  NEVER_CONNECTED = 'NEVER_CONNECTED',
}

export type AddedSystem = {
  systemId: string;
  serialNumber: string;
  systemConnectionStatus: AddedSystemStatusName;
  systemType: SystemType;
};

export type AddSystemsToSiteSuccess = AddedSystem[];

export type AddSystemsToSiteResponse = AddSystemsToSiteSuccess | AddSystemsToSiteFailure;
