import type { AxiosError } from 'axios';
import { produce } from 'immer';
import type { ActionType } from 'typesafe-actions';
import { createReducer, getType } from 'typesafe-actions';

import type { SystemDetails } from '../../types/Site';
import type {
  AddSiteFailureResponseData,
  AddSiteSuccessResponse,
  SuggestionListInterface,
  ValidationAddressFailureInterface,
  ValidationSiteAddressResponseInterface,
} from '../../types/SiteDetails';
import type { UtilitiesFailedResponse, UtilitiesSuccessResponse } from '../../types/Utility';
import * as actions from './actions';
import { AddSiteStep } from './stepsData';

export interface AddDetailsStepData {
  siteName: string;
  siteAddress1: string;
  siteAddress2: string;
  siteCity: string;
  siteCountry: string;
  siteState: string;
  siteZip: string;
  homeownerBusinessName: string;
  homeownerFirstName: string;
  homeownerLastName: string;
  homeownerPhone: string;
  homeownerEmail: string;
  homeownerEmailVerify: string;
  homeownerAddress1: string;
  homeownerAddress2: string;
  homeownerCity: string;
  homeownerCountry: string;
  homeownerState: string;
  homeownerZip: string;
  useSiteAddress: boolean;
  hasConsent: boolean;
  termsAgree: boolean;
  utilityId: number | string;

  // CSM fields
  addressOverride: boolean;
  latitude: number | undefined;
  longitude: number | undefined;
}

export interface AddSiteState {
  utilities: UtilitiesSuccessResponse | null;
  utilitiesLoading: boolean;
  utilitiesError: UtilitiesFailedResponse | null;
  currentStep: AddSiteStep;
  [AddSiteStep.ADD_DETAILS]: {
    formData: AddDetailsStepData;
    siteAddressValidating: boolean;
    siteAddressValidationData: null | ValidationSiteAddressResponseInterface;
    siteAddressValidationError: null | ValidationAddressFailureInterface;
    homeownerAddressValidating: boolean;
    homeownerAddressValidationData: null | ValidationSiteAddressResponseInterface;
    homeownerAddressValidationError: null | ValidationAddressFailureInterface;
  };
  [AddSiteStep.SITE_ADDRESS_VERIFICATION]: {
    selectedAddress: null | SuggestionListInterface;
  };
  [AddSiteStep.HOMEOWNER_ADDRESS_VERIFICATION]: {
    selectedAddress: null | SuggestionListInterface;
  };
  [AddSiteStep.ADD_SYSTEM_DETAILS]: {
    systems: SystemDetails[];
  };
  [AddSiteStep.PAIRING]: {
    pairingRecord: Record<string, string>; // { [loadManagerSerialNumber]: inverterSerialNumber }
    pairingError: AxiosError | Error | null;
    isPairing: boolean;
  };
  [AddSiteStep.REVIEW]: {
    addSiteSuccess: null | AddSiteSuccessResponse;
    addSiteFailure: null | AddSiteFailureResponseData;
    addSiteLoading: boolean;
  };
}

const INITIAL_STATE: AddSiteState = {
  utilities: null,
  utilitiesLoading: false,
  utilitiesError: null,
  currentStep: AddSiteStep.PREPARATION,
  [AddSiteStep.ADD_DETAILS]: {
    formData: {
      siteName: '',
      siteAddress1: '',
      siteAddress2: '',
      siteCity: '',
      siteCountry: '',
      siteState: '',
      siteZip: '',
      utilityId: '',
      homeownerBusinessName: '',
      homeownerFirstName: '',
      homeownerLastName: '',
      homeownerPhone: '',
      homeownerEmail: '',
      homeownerEmailVerify: '',
      homeownerAddress1: '',
      homeownerAddress2: '',
      homeownerCity: '',
      homeownerCountry: '',
      homeownerState: '',
      homeownerZip: '',
      useSiteAddress: false,
      hasConsent: false,
      termsAgree: false,

      addressOverride: false,
      latitude: undefined,
      longitude: undefined,
    },
    siteAddressValidating: false,
    siteAddressValidationData: null,
    siteAddressValidationError: null,
    homeownerAddressValidating: false,
    homeownerAddressValidationData: null,
    homeownerAddressValidationError: null,
  },
  [AddSiteStep.SITE_ADDRESS_VERIFICATION]: {
    selectedAddress: null,
  },
  [AddSiteStep.HOMEOWNER_ADDRESS_VERIFICATION]: {
    selectedAddress: null,
  },
  [AddSiteStep.ADD_SYSTEM_DETAILS]: {
    systems: [],
  },
  [AddSiteStep.PAIRING]: {
    pairingRecord: {},
    pairingError: null,
    isPairing: false,
  },
  [AddSiteStep.REVIEW]: {
    addSiteSuccess: null,
    addSiteFailure: null,
    addSiteLoading: false,
  },
};

export type AddSiteActions = ActionType<typeof actions>;

export default createReducer<AddSiteState, AddSiteActions>(INITIAL_STATE, {
  [getType(actions.setStep)]: produce(
    (state: AddSiteState, action: ReturnType<typeof actions.setStep>) => {
      state.currentStep = action.payload;
    },
  ),
  [getType(actions.setAddDetailsStepData)]: produce(
    (state: AddSiteState, action: ReturnType<typeof actions.setAddDetailsStepData>) => {
      state[AddSiteStep.ADD_DETAILS].formData = action.payload.data;
    },
  ),
  [getType(actions.getAddSite.request)]: produce((state) => {
    state[AddSiteStep.REVIEW].addSiteLoading = true;
    state[AddSiteStep.REVIEW].addSiteFailure = null;
  }),
  [getType(actions.getAddSite.success)]: produce(
    (state, action: ReturnType<typeof actions.getAddSite.success>) => {
      state[AddSiteStep.REVIEW].addSiteSuccess = action.payload;
      state[AddSiteStep.REVIEW].addSiteLoading = false;
    },
  ),
  [getType(actions.getAddSite.failure)]: produce(
    (state, action: ReturnType<typeof actions.getAddSite.failure>) => {
      const systems = state[AddSiteStep.ADD_SYSTEM_DETAILS].systems;
      state[AddSiteStep.REVIEW].addSiteFailure = action.payload;
      state[AddSiteStep.REVIEW].addSiteLoading = false;
      if (action.payload?.systemErrorList?.length && systems?.length) {
        // sorting system errors in the order that the systems are sent to BE
        state[AddSiteStep.REVIEW].addSiteFailure.systemErrorList =
          action.payload.systemErrorList.sort((a, b) => {
            return systems.findIndex((c: SystemDetails) => c.serialNumber === a.serialNumber) >
              systems.findIndex((c: SystemDetails) => c.serialNumber === b.serialNumber)
              ? 1
              : -1;
          });
      }
    },
  ),
  [getType(actions.getValidationSiteAddress.request)]: produce((state) => {
    state[AddSiteStep.ADD_DETAILS].siteAddressValidationError = null;
    state[AddSiteStep.ADD_DETAILS].siteAddressValidating = true;
  }),
  [getType(actions.getValidationSiteAddress.success)]: produce(
    (state, action: ReturnType<typeof actions.getValidationSiteAddress.success>) => {
      state[AddSiteStep.ADD_DETAILS].siteAddressValidationData = action.payload;
      state[AddSiteStep.ADD_DETAILS].siteAddressValidating = false;
    },
  ),
  [getType(actions.getValidationSiteAddress.failure)]: produce(
    (state, action: ReturnType<typeof actions.getValidationSiteAddress.failure>) => {
      state[AddSiteStep.ADD_DETAILS].siteAddressValidationError = action.payload;
      state[AddSiteStep.ADD_DETAILS].siteAddressValidating = false;
    },
  ),
  [getType(actions.getValidationSiteAddressHomeowner.request)]: produce((state) => {
    state[AddSiteStep.ADD_DETAILS].homeownerAddressValidationError = null;
    state[AddSiteStep.ADD_DETAILS].homeownerAddressValidating = true;
  }),
  [getType(actions.getValidationSiteAddressHomeowner.success)]: produce(
    (state, action: ReturnType<typeof actions.getValidationSiteAddressHomeowner.success>) => {
      state[AddSiteStep.ADD_DETAILS].homeownerAddressValidationData = action.payload;
      state[AddSiteStep.ADD_DETAILS].homeownerAddressValidating = false;
    },
  ),
  [getType(actions.getValidationSiteAddressHomeowner.failure)]: produce(
    (state, action: ReturnType<typeof actions.getValidationSiteAddressHomeowner.failure>) => {
      state[AddSiteStep.ADD_DETAILS].homeownerAddressValidationError = action.payload;
      state[AddSiteStep.ADD_DETAILS].homeownerAddressValidating = false;
    },
  ),
  [getType(actions.updateSystemsInWizard)]: produce(
    (state: AddSiteState, action: ReturnType<typeof actions.updateSystemsInWizard>) => {
      state[AddSiteStep.ADD_SYSTEM_DETAILS].systems = action.payload;
    },
  ),
  [getType(actions.removeSystemFromWizard)]: produce(
    (state: AddSiteState, action: ReturnType<typeof actions.removeSystemFromWizard>) => {
      const index = action.payload;
      const items = state[AddSiteStep.ADD_SYSTEM_DETAILS].systems;
      state[AddSiteStep.ADD_SYSTEM_DETAILS].systems = items.filter((e, i) => i !== index);
    },
  ),
  [getType(actions.setSiteAddress)]: produce(
    (state: AddSiteState, action: ReturnType<typeof actions.setSiteAddress>) => {
      state[AddSiteStep.SITE_ADDRESS_VERIFICATION].selectedAddress = action.payload;
    },
  ),
  [getType(actions.setHomeownerAddress)]: produce(
    (state: AddSiteState, action: ReturnType<typeof actions.setHomeownerAddress>) => {
      state[AddSiteStep.HOMEOWNER_ADDRESS_VERIFICATION].selectedAddress = action.payload;
    },
  ),
  [getType(actions.clearAddressValidationErrors)]: produce((state: AddSiteState) => {
    state[AddSiteStep.ADD_DETAILS].homeownerAddressValidationError = null;
    state[AddSiteStep.ADD_DETAILS].siteAddressValidationError = null;
    state[AddSiteStep.ADD_DETAILS].homeownerAddressValidationData = null;
    state[AddSiteStep.ADD_DETAILS].siteAddressValidationData = null;
  }),
  [getType(actions.clearAddSiteError)]: produce((state: AddSiteState) => {
    state[AddSiteStep.REVIEW].addSiteFailure = null;
  }),
  [getType(actions.loadUtilities.request)]: produce((state) => {
    state.utilitiesLoading = true;
    state.utilities = null;
    state.utilitiesError = null;
  }),
  [getType(actions.loadUtilities.success)]: produce(
    (state, { payload }: ReturnType<typeof actions.loadUtilities.success>) => {
      state.utilitiesLoading = false;
      state.utilities = payload;
    },
  ),
  [getType(actions.loadUtilities.failure)]: produce(
    (state, { payload }: ReturnType<typeof actions.loadUtilities.failure>) => {
      state.utilitiesLoading = false;
      state.utilities = null;
      state.utilitiesError = payload;
    },
  ),
  [getType(actions.setPairingRecord)]: produce(
    (state, { payload }: ReturnType<typeof actions.setPairingRecord>) => {
      state[AddSiteStep.PAIRING].pairingRecord = payload;
    },
  ),
  [getType(actions.loadSystemsDetails.request)]: produce((state) => {
    state[AddSiteStep.PAIRING].pairingError = null;
    state[AddSiteStep.PAIRING].isPairing = true;
  }),
  [getType(actions.loadSystemsDetails.failure)]: produce(
    (state, { payload }: ReturnType<typeof actions.loadSystemsDetails.failure>) => {
      state[AddSiteStep.PAIRING].pairingError = payload;
      state[AddSiteStep.PAIRING].isPairing = false;
    },
  ),
  [getType(actions.pairLoadManagers.success)]: produce((state) => {
    state[AddSiteStep.PAIRING].isPairing = false;
  }),
  [getType(actions.pairLoadManagers.failure)]: produce(
    (state, { payload }: ReturnType<typeof actions.pairLoadManagers.failure>) => {
      state[AddSiteStep.PAIRING].pairingError = payload;
      state[AddSiteStep.PAIRING].isPairing = false;
    },
  ),
  // eslint-disable-next-line unused-imports/no-unused-vars
  [getType(actions.resetAddSiteState)]: produce((state: AddSiteState) => INITIAL_STATE),
});
