export const icons = {
  'access site': require('./assets/access site.svg').default,
  access: require('./assets/access.svg').default,
  account: require('./assets/account.svg').default,
  'account black': require('./assets/account_black.svg').default,
  'alert error': require('./assets/alert_error.svg').default,
  'alert success': require('./assets/alert_success.svg').default,
  alerts: require('./assets/alerts.svg').default,
  'alerts black': require('./assets/alerts_black.svg').default,
  'alerts filled crossed': require('./assets/alerts_filled_crossed.svg').default,
  'alerts filled orange': require('./assets/alerts_filled_orange.svg').default,
  'alerts filled red': require('./assets/alerts_filled_red.svg').default,
  'alerts filled yellow': require('./assets/alerts_filled_yellow.svg').default,
  'arrow back': require('./assets/arrow_back.svg').default,
  'arrow bullet': require('./assets/arrow_bullet.svg').default,
  'arrow down selected': require('./assets/arrow_down_selected.svg').default,
  'arrow down unselected': require('./assets/arrow_down_unselected.svg').default,
  'arrow drop down': require('./assets/arrow_drop_down.svg').default,
  'arrow external link': require('./assets/arrow_external_link.svg').default,
  'arrow forward': require('./assets/arrow_forward.svg').default,
  'arrow up selected': require('./assets/arrow_up_selected.svg').default,
  battery: require('./assets/battery.svg').default,
  'battery card': require('./assets/battery_card.svg').default,
  'battery disconnected': require('./assets/battery_disconnected.svg').default,
  'battery error': require('./assets/battery_error.svg').default,
  'battery settings': require('./assets/battery_settings.svg').default,
  beacon: require('./assets/beacon.svg').default,
  'beacon card': require('./assets/beacon_card.svg').default,
  'brand g': require('./assets/brand_g.svg').default,
  'button check': require('./assets/button_check.svg').default,
  calendar: require('./assets/calendar.svg').default,
  'chart bar': require('./assets/chart_bar.svg').default,
  'chart gantt': require('./assets/chart_gantt.svg').default,
  check: require('./assets/check.svg').default,
  chevron: require('./assets/chevron.svg').default,
  'chevron down': require('./assets/chevron_down.svg').default,
  'chevron left': require('./assets/chevron_left.svg').default,
  close: require('./assets/close.svg').default,
  'close black': require('./assets/close_black.svg').default,
  'close gray': require('./assets/close_gray.svg').default,
  'close red': require('./assets/close_red.svg').default,
  'close white': require('./assets/close_white.svg').default,
  cog: require('./assets/cog.svg').default,
  'coming soon': require('./assets/coming_soon.svg').default,
  company: require('./assets/company.svg').default,
  'company black': require('./assets/company_black.svg').default,
  complete: require('./assets/complete.svg').default,
  delete: require('./assets/delete.svg').default,
  'delete new': require('./assets/delete_new.svg').default,
  details: require('./assets/details.svg').default,
  'device home': require('./assets/device_home.svg').default,
  disabled: require('./assets/disabled.svg').default,
  download: require('./assets/download.svg').default,
  edit: require('./assets/edit.svg').default,
  'edit pen': require('./assets/edit_pen.svg').default,
  'edit pencil': require('./assets/edit_pencil.svg').default,
  email: require('./assets/email.svg').default,
  enter: require('./assets/enter.svg').default,
  error: require('./assets/error.svg').default,
  'error alert orange': require('./assets/error_alert_orange.svg').default,
  'error new': require('./assets/error_new.svg').default,
  'exclamation circle red': require('./assets/exclamation circle red.svg').default,
  'expand image': require('./assets/expand_image.svg').default,
  'expected production': require('./assets/expected_production.svg').default,
  export: require('./assets/export.svg').default,
  'export csv': require('./assets/export_csv.svg').default,
  'external link': require('./assets/external_link.svg').default,
  'external link white': require('./assets/external_link_white.svg').default,
  failed: require('./assets/failed.svg').default,
  feedback: require('./assets/feedback.svg').default,
  fleet: require('./assets/fleet.svg').default,
  'fleet selected': require('./assets/fleet_selected.svg').default,
  gateway: require('./assets/gateway.svg').default,
  generator: require('./assets/generator.svg').default,
  'generator disconnected': require('./assets/generator_disconnected.svg').default,
  'generator error': require('./assets/generator_error.svg').default,
  help: require('./assets/help.svg').default,
  home: require('./assets/home.svg').default,
  homeowner: require('./assets/homeowner.svg').default,
  'icon check': require('./assets/icon_check.svg').default,
  info: require('./assets/info.svg').default,
  'info new': require('./assets/info_new.svg').default,
  inverter: require('./assets/inverter.svg').default,
  'inverter card': require('./assets/inverter_card.svg').default,
  'inverter error': require('./assets/inverter_error.svg').default,
  'inverter new': require('./assets/inverter_new.svg').default,
  'invite link': require('./assets/invite_link.svg').default,
  islanding: require('./assets/islanding.svg').default,
  'kpis black': require('./assets/kpis_black.svg').default,
  'kpis purple': require('./assets/kpis_purple.svg').default,
  list: require('./assets/list.svg').default,
  'load manager': require('./assets/load_manager.svg').default,
  'load manager card': require('./assets/load_manager_card.svg').default,
  'load manager disconnected': require('./assets/load_manager_disconnected.svg').default,
  'load manager error': require('./assets/load_manager_error.svg').default,
  location: require('./assets/location.svg').default,
  manta: require('./assets/manta.svg').default,
  menu: require('./assets/menu.svg').default,
  meter: require('./assets/meter.svg').default,
  microinverter: require('./assets/microinverter.svg').default,
  'mobile tab cart': require('./assets/mobile_tab_cart.svg').default,
  'more menu': require('./assets/more_menu.svg').default,
  'more menu black': require('./assets/more_menu_black.svg').default,
  'more menu white': require('./assets/more_menu_white.svg').default,
  'never connected': require('./assets/never_connected.svg').default,
  'no connectivity': require('./assets/no_connectivity.svg').default,
  'notes attachments': require('./assets/notes_attachments.svg').default,
  octopus: require('./assets/octopus.svg').default,
  'overview tab': require('./assets/overview_tab.svg').default,
  phone: require('./assets/phone.svg').default,
  plus: require('./assets/plus.svg').default,
  'pvlink card': require('./assets/pvlink_card.svg').default,
  'radio button': require('./assets/radio_button.svg').default,
  'radio button checked': require('./assets/radio_button_checked.svg').default,
  rgm: require('./assets/rgm.svg').default,
  'rgm card': require('./assets/rgm_card.svg').default,
  'rgm disconnected': require('./assets/rgm_disconnected.svg').default,
  'rgm error': require('./assets/rgm_error.svg').default,
  search: require('./assets/search.svg').default,
  'search close': require('./assets/search_close.svg').default,
  share: require('./assets/share.svg').default,
  site: require('./assets/site.svg').default,
  'site capacity': require('./assets/site_capacity.svg').default,
  'solar production': require('./assets/solar production.svg').default,
  solar: require('./assets/solar.svg').default,
  'solar average': require('./assets/solar_average.svg').default,
  'solar battery': require('./assets/solar_battery.svg').default,
  'solar disconnected': require('./assets/solar_disconnected.svg').default,
  'solar energy': require('./assets/solar_energy.svg').default,
  'solar error': require('./assets/solar_error.svg').default,
  'solar house': require('./assets/solar_house.svg').default,
  'solar power': require('./assets/solar_power.svg').default,
  'solar temperature': require('./assets/solar_temperature.svg').default,
  support: require('./assets/support.svg').default,
  system: require('./assets/system.svg').default,
  'system card': require('./assets/system_card.svg').default,
  'system owner': require('./assets/system_owner.svg').default,
  'total consumption': require('./assets/total consumption.svg').default,
  'transfer black': require('./assets/transfer black.svg').default,
  'transfer white': require('./assets/transfer white.svg').default,
  transient: require('./assets/transient.svg').default,
  trashcan: require('./assets/trashcan.svg').default,
  'trashcan new': require('./assets/trashcan_new.svg').default,
  update: require('./assets/update.svg').default,
  warning: require('./assets/warning.svg').default,
  wrench: require('./assets/wrench.svg').default,
  network: require('./assets/meter.svg'),
};
export type IconType = keyof typeof icons;
export type IconsType = Record<IconType, string>;
