import type { SxProps } from '@mui/material';
import { Box, Collapse } from '@mui/material';
import { isEmpty } from 'lodash';
import type { ReactElement } from 'react';
import { useState } from 'react';

import { COLORS } from '../../styles';
import type { PWRbuttonProps } from '../pwrButton';
import { PWRbutton } from '../pwrButton';
import { PWRloader } from '../pwrLoader';
import type { CardHeaderProps } from './CardHeader';
import CardHeader from './CardHeader';

const rootBoxSx: SxProps = {
  display: 'flex',
  p: {
    md: 3,
    xs: 2,
  },
  flexDirection: 'column',
  bgcolor: COLORS.WHITE,
  borderRadius: 3,
  height: '100%',
};

const textButtonSx: SxProps = {
  ml: {
    md: 2,
    xs: 0,
  },
  mb: {
    md: 0,
    xs: 2,
  },
  height: 40,
  fontSize: 16,
  ':last-child': {
    mb: 0,
  },
};

const actionButtonsSx: SxProps = {
  display: 'flex',
  flexDirection: {
    md: 'row-reverse',
    xs: 'column',
  },
  flexWrap: 'wrap',
  ':last-child': {
    mb: '0 !important',
  },
  pt: {
    md: 6,
    xs: 3,
  },
};

export interface CardProps
  extends Omit<CardHeaderProps, 'bodyPanelCollapsed' | 'handleCollapseButtonClick'> {
  bodySlotElement?: ReactElement;
  bodyButtonsListProps?: PWRbuttonProps[];
  isLoading?: boolean;
}

export const Card = (props: CardProps) => {
  const { bodySlotElement, bodyButtonsListProps, isLoading, name, ...headerProps } = props;

  const [isBodyCollapsed, setIsBodyCollapsed] = useState<boolean>(false);

  return (
    <Box sx={rootBoxSx} data-test-id={`device-card-${name}`}>
      <PWRloader isLoading={isLoading ?? false}>
        <CardHeader
          {...headerProps}
          bodyPanelCollapsed={isBodyCollapsed}
          handleCollapseButtonClick={() => setIsBodyCollapsed((prev) => !prev)}
          name={name}
        />
        {bodySlotElement && (
          <Collapse in={!isBodyCollapsed}>
            <Box
              sx={{
                pt: 3,
              }}
            >
              {bodySlotElement}
            </Box>
          </Collapse>
        )}
        {!isEmpty(bodyButtonsListProps) && (
          <Box sx={actionButtonsSx}>
            {bodyButtonsListProps?.map(({ text, sx, ...rest }) => {
              return (
                <PWRbutton
                  key={text}
                  text={text ?? ''}
                  data-test-class={`device-action-button-${text}`}
                  color="secondary"
                  variant="outlined"
                  sx={{ ...textButtonSx, ...sx }}
                  {...rest}
                />
              );
            })}
          </Box>
        )}
      </PWRloader>
    </Box>
  );
};
